import React from "react";
import { Modal, ModalBody } from "reactstrap";
import image from "../../assets/images/failed-icon.jpg";
import { withRouter } from "react-router-dom";

class CancelFailure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.newModal
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
    const { history } = this.props;
    if (history) history.push("/myorders");
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newModal !== this.props.newModal) {
      this.toggle();
    }
  }

  handleClick = () => {
    this.toggle();
  };

  render() {
    return (
      <div>
        <Modal
          centered
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={`${this.props.className} reset-password`}
        >
          <div>
            <ModalBody id="reset-password-image-body">
              <img id="reset-password-image" src={image} alt="" />
            </ModalBody>
            <ModalBody>
              <p className="popup-body">
                Sorry the cancel order wasn't successful. Please try again.
              </p>
            </ModalBody>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(CancelFailure);
