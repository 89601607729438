import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";

import cardService from "../../services/creditCardService";

class AddCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      card_id: ""
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  deleteCard = async () => {
    await cardService.delete_card(this.props.card_id);
    this.props.getCards();
    this.toggle();
  };

  render() {
    return (
      <div>
        <i
          className="fas fa-trash-alt"
          onClick={this.toggle}
        ></i>
        <Modal
          centered
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={`${this.props.className} reset-password`}
        >
          <div>
            <ModalBody>
              <p className="popup-body">
                Are you sure you want to delete this card?
              </p>
              <div className="txtCenter">
                <Button
                  color="success"
                  id="btnCancelYes"
                  onClick={this.deleteCard}
                >
                  YES
                </Button>
                <Button color="danger" id="btnCancelNo" onClick={this.toggle}>
                  NO
                </Button>
              </div>
            </ModalBody>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AddCardForm;
