import React, { useEffect, useState } from 'react';
import companyService from '../../services/companyService';
import { getZlToken } from '../shared/common';

const AddressesDropList = ({ addressId, getMessage, getCompany, getMeals, className = '' }) => {

  const [userAddresses, setUserAddresses] = useState([]);
  const [selectedAddresses, setSelectedAddresses] = useState(0);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const newData = getZlToken(localStorage.getItem("ZlToken"));
    setSelectedAddresses(addressId);
    setUserData(newData)
    getUserAddresses(newData.id);
  }, [addressId]);

  const getUserAddresses = async (userId) => {
    const userAddresses = await companyService.getUserAddresses(userId);
    setUserAddresses(userAddresses);
  };

  const handelChange = async (e) => {
    const value = Number(e.target.value);
    setSelectedAddresses(value);
    let newData = { ...userData };
    newData.company_id = value;
    setUserData(newData);
    await companyService.changeUserAddress(newData, value);
    getMeals();
    getCompany();
    getMessage();
  }

  return (
    <div className='form-group mr-3'>
      <select
        name='addresses'
        className={`form-control bg-light rounded ${className}`}
        value={selectedAddresses ? selectedAddresses : false}
        onChange={handelChange}
      >

        {userAddresses.map(({ id, address_id, name }) => (
          <option key={id} value={address_id}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AddressesDropList;
