import React from "react";
import { FormGroup, Input, Button } from "reactstrap";
import RequestReceived from "./request_received";
import validationService from "../../services/validationService";
import { Link } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import Map from "./map";

import companyService from "../../services/companyService";
import {getZlToken} from "../shared/common"
class RequestCompany extends React.Component {
  constructor(props) {
    let userData = getZlToken(localStorage.getItem("ZlToken"));
    super(props);
    this.state = {
      newModal: false,
      airtableError: false,
      id: userData.id,
      name:
        userData.first_name +
        " " +
        userData.last_name,
      phone: userData.phone,
      email: userData.email,
      companyName: "",
      companyLocation: "",
      location: "",
      lat: 43.64887,
      lng: -79.4,
      unit: "",
      floor: "",
      companyNameError: "",
      companyLocationError: "",
      companyFloorError: "",
      companyNameValid: false,
      companyLocationValid: false,
      companyFloorValid: false,
    };
  }

  checkCompanyNameValid = () => {
    let companyNameChecked = validationService.validatName(
      this.state.companyName
    );
    this.setState({
      companyNameError: companyNameChecked.erroeMessage,
      companyNameValid: companyNameChecked.nameValid,
    });
  };

  checkCompanyFloorValid = () => {
    let companyFloorChecked = validationService.validateCompany(
      this.state.floor
    );
    this.setState({
      companyFloorError: companyFloorChecked.erroeMessage,
      companyFloorValid: companyFloorChecked.nameValid,
    });
  };

  checkCompanyLocationValid = () => {
    let companyLocationChecked = validationService.validatLocation(
      this.state.companyLocation
    );
    this.setState({
      companyLocationError: companyLocationChecked.erroeMessage,
      companyLocationValid: companyLocationChecked.nameValid,
    });
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  checkAllFieldsValidations = () => {
    this.checkCompanyNameValid();
    this.checkCompanyLocationValid();
    this.checkCompanyFloorValid();
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.preventDefault();
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !(
        this.state.companyLocationValid &&
        this.state.companyNameValid &&
        this.state.companyFloorValid
      )
    ) {
      this.checkAllFieldsValidations();
      return;
    }

    let then = this;

    const requestCompany = await companyService.add_company(
      this.state.companyName,
      this.state.companyLocation,
      this.state.unit,
      this.state.floor
    );
    if (requestCompany.status !== 200) {
      then.setState({ airtableError: true });
    } else {
      then.setState({ newModal: true });
    }
  };

  handelAddressChange = async (value) => {
    const companyLocation = value.address;
    const lat = value.lat;
    const lng = value.lng;

    let errors = { ...this.state.companyLocationError };

    let locationError = this.checkCompanyLocationValid();

    if (locationError) errors = locationError;
    else errors = "";
    this.setState({ companyLocation, lat, lng, companyLocationError: errors });
  };

  render() {
    if (isMobile) {
      return (
        <div>
          <div className="marTop d-none d-md-block"></div>
          <Link to="/selectcompany">
            <i className="fa fa-arrow-left" id="backArrow" />
          </Link>
          <div id="signup-mobile-form">
            <h3 id="welcome-ver">Add your condo or company to ZipLunch</h3>
            <form onSubmit={this.handleSubmit}>
              <FormGroup>
                <i className="fas fa-shopping-bag gps-companys"></i>
                <Input
                  className={`form-group company-inputs ${this.errorClass(
                    this.state.companyNameError
                  )}`}
                  id="companyName"
                  type="text"
                  placeholder="Condo/Company name"
                  value={this.state.companyName}
                  onChange={this.handleChange}
                  onBlur={(e) => {
                    this.checkCompanyNameValid();
                  }}
                />{" "}
                {this.state.companyNameError && (
                  <label className="errorMessage">
                    {`${this.state.companyNameError}Business Name.`}
                  </label>
                )}
                {this.state.companyExist && (
                  <label className="errorMessage">
                    This name alredy exists
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Map
                  lat={this.state.lat}
                  lng={this.state.lng}
                  address={this.state.companyLocation}
                  onChange={this.handelAddressChange}
                  errors={this.state.companyLocationError}
                ></Map>
              </FormGroup>
              <FormGroup>
                <i className="fas fa-building gps-companys"></i>
                <Input
                  className="form-group company-inputs"
                  id="unit"
                  type="text"
                  placeholder="Unit"
                  value={this.state.buildingNumber}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <i className="fas fa-building gps-companys"></i>
                <Input
                  className={`form-group company-inputs ${this.errorClass(
                    this.state.companyFloorError
                  )}`}
                  id="floor"
                  type="text"
                  placeholder="Floor *"
                  onChange={this.handleChange}
                  onBlur={this.checkCompanyFloorValid}
                />
                {this.state.companyFloorError && (
                  <label className="errorMessage">
                    {`${this.state.companyFloorError}Floor number.`}
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Button color="danger" id="btn" type="submit">
                  Request
                </Button>
                {this.state.airtableError && (
                  <label className="errorMessage">
                    something went wrong. please try again.
                  </label>
                )}
              </FormGroup>
              <RequestReceived newModal={this.state.newModal} />
            </form>
          </div>
        </div>
      );
    }
    if (isBrowser) {
      return (
        <div>
          <div id="signup-mobile-form">
            <h3 id="welcome-ver">Add your condo or company to ZipLunch </h3>
            <form onSubmit={this.handleSubmit}>
              <FormGroup>
                <i className="fas fa-shopping-bag gps-companys"></i>
                <Input
                  className={`form-group company-inputs ${this.errorClass(
                    this.state.companyNameError
                  )}`}
                  id="companyName"
                  type="text"
                  placeholder="Condo/Company name"
                  value={this.state.companyName}
                  onChange={this.handleChange}
                  onBlur={(e) => {
                    this.checkCompanyNameValid();
                  }}
                />{" "}
                {this.state.companyNameError && (
                  <label className="errorMessage">
                    {`${this.state.companyNameError}Business Name.`}
                  </label>
                )}
                {this.state.companyExist && (
                  <label className="errorMessage">
                    This name alredy exists
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Map
                  lat={this.state.lat}
                  lng={this.state.lng}
                  address={this.state.companyLocation}
                  onChange={this.handelAddressChange}
                  errors={this.state.companyLocationError}
                ></Map>
              </FormGroup>
              <FormGroup>
                <i className="fas fa-building gps-companys"></i>
                <Input
                  className="form-group company-inputs"
                  id="unit"
                  type="text"
                  placeholder="Unit"
                  value={this.state.buildingNumber}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <i className="fas fa-building gps-companys"></i>
                <Input
                  className={`form-group company-inputs ${this.errorClass(
                    this.state.companyFloorError
                  )}`}
                  id="floor"
                  type="text"
                  placeholder="Floor *"
                  onChange={this.handleChange}
                  onBlur={this.checkCompanyFloorValid}
                />
                {this.state.companyFloorError && (
                  <label className="errorMessage">
                    {`${this.state.companyFloorError}Floor number.`}
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Button color="danger" id="btn" type="submit">
                  Request
                </Button>
              </FormGroup>
              <RequestReceived newModal={this.state.newModal} />
            </form>
          </div>
        </div>
      );
    }
  }
}

export default RequestCompany;
