import React from "react";
import { Modal, ModalBody } from "reactstrap";
import image from "../../assets/images/green_check.png";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";

class CancelSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.newModal
    };
  }

  toggle = () => {
    let flag = !this.state.modal;
    this.setState({
      modal: flag
    });
    this.props.handelHideCancel();
    setTimeout(() => {
      this.props.toggle();
    }, 1000000);

    if (this.state.toggle) {
      const { history } = this.props;
      if (history) history.push("/myorders");
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newModal !== this.props.newModal) {
      this.toggle();
    }
  }

  handleClick = () => {
    this.toggle();
  };

  render() {
    return (
      <div>
        <Modal
          centered
          isOpen={this.state.modal}
          onClick={this.handleClick}
          className={`reset-password`}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <ModalBody id="reset-password-image-body">
              <img id="reset-password-image" src={image} alt="" />
            </ModalBody>
            <ModalBody>
              <p className="popup-body">Your order has been cancelled</p>
            </ModalBody>
            <Button
              color="danger"
              onClick={this.handleClick}
              style={{
                width: "50%"
              }}
            >
              Done
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(CancelSuccess);
