import MenuItem from "@material-ui/core/MenuItem";
import MuiPaper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Geocode from "react-geocode";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

const apiKey = "AIzaSyCde16IQkTCT_LzQmAHneEoEzUm4PRBeZg";

export const defaults = {
  lat: 45.24887,
  lng: -75.804025
};

Geocode.setApiKey(apiKey);
// Geocode.enableDebug();

const Paper = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    right: 0
  }
})(MuiPaper);

const renderSuggestion = (suggestion, props) => {
  return (
    <MenuItem
      {...props}
      key={suggestion.id}
      selected={suggestion.active}
      component="div"
    >
      {suggestion.description}
    </MenuItem>
  );
};

const AutocompleteComponent = ({
  label,
  address,
  errors,
  onChange,
  onSelect
}) => (
  <PlacesAutocomplete
    highlightFirstSuggestion
    value={address}
    onChange={onChange}
    onSelect={onSelect}
  >
    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <div>
        <input
          {...getInputProps({
            placeholder: "Address ...",
            className: "form-control form-control-lg"
          })}
        />
        {errors && (
          <small className="form-text text-danger px-1">{errors}</small>
        )}

        <div className="position-relative">
          {loading && <div>Loading...</div>}

          {suggestions && suggestions.length > 0 && (
            <Paper square>
              {suggestions.map(suggestion => {
                return renderSuggestion(
                  suggestion,
                  getSuggestionItemProps(suggestion)
                );
              })}
            </Paper>
          )}
        </div>
      </div>
    )}
  </PlacesAutocomplete>
);

class AppMap extends Component {
  state = {
    lat: this.props.lat,
    lng: this.props.lng,
    address: this.props.address
  };

  async componentDidMount() {
    await this.updateLatLang();
  }

  async componentDidUpdate(prevProps, prevState) {
    await this.updateLatLang();
  }

  updateLatLang = async () => {
    const { lat, lng, address } = this.props;
    const { lat: sLat, lng: sLng, address: sAddress } = this.state;

    if (lat !== sLat || lng !== sLng || address !== sAddress) {
      if (address && (!lat || !lng)) {
        const result = await this.getLatLngFromAddress(address);
        const data = {
          address: address,
          lat: result.lat || defaults.lat,
          lng: result.lng || defaults.lng
        };
        this.setState({ ...data });
        if (this.props.onChange) this.props.onChange(data);
      } else {
        this.setState({ lat, lng, address });
      }
    } else {
    }
  };

  getLatLngFromAddress = async address => {
    try {
      if (!address) return { lat: defaults.lat, lng: defaults.lng };
      const results = await geocodeByAddress(address);
      const latlng = await getLatLng(results[0]);
      return latlng;
    } catch (error) {
      return { lat: defaults.lat, lng: defaults.lng };
    }
  };

  handleMarkerDrag = async (props, marker, event) => {
    const oldAddress = this.state.address;
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    try {
      const address = await this.getAddress(newLat, newLng);
      const data = {
        address: address,
        lat: newLat,
        lng: newLng
      };
      this.setState({ ...data });
      if (this.props.onChange) this.props.onChange(data);
    } catch (error) {
      const data = {
        address: oldAddress,
        lat: newLat,
        lng: newLng
      };
      this.setState({ ...data });
      if (this.props.onChange) this.props.onChange(data);
    }
  };

  getAddress = async (lat, lng) => {
    const { results } = await Geocode.fromLatLng(lat, lng);

    if (results || (results.length > 0 && results[0].formatted_address))
      return results[0].formatted_address;

    return "";
  };

  handleAutocompleteChange = async address => {
    this.setState({ address: address });
    if (this.props.onChange) this.props.onChange({ ...this.state, address });
  };

  handleAutocompleteSelect = async address => {
    const oldLat = this.state.lat;
    const oldLng = this.state.lng;
    try {
      const results = await geocodeByAddress(address);
      const latlng = await getLatLng(results[0]);

      const data = { address: address, lat: latlng.lat, lng: latlng.lng };
      this.setState({ ...data });
      if (this.props.onChange) this.props.onChange(data);
    } catch (error) {
      const data = { address: address, lat: oldLat, lng: oldLng };
      this.setState({ ...data });
      if (this.props.onChange) this.props.onChange(data);
    }
  };

  render() {
    const { lat, lng, address } = this.state;

    return (
      <div>
        <div className="form-group">
          <AutocompleteComponent
            address={address}
            label={this.props.label}
            errors={this.props.errors}
            onChange={this.handleAutocompleteChange}
            onSelect={this.handleAutocompleteSelect}
          />
        </div>
        <div
          className="mb-3"
          style={{ height: this.props.height, position: "relative" }}
        >
          {lat && lng && (
            <Map
              google={this.props.google}
              zoom={this.props.zoom}
              style={{
                height: "100%",
                width: "100%"
              }}
              initialCenter={{ lat, lng }}
              onDblclick={this.handelDblclick}
            >
              <Marker
                draggable
                position={{ lat, lng }}
                name={"Current location"}
                onDragend={this.handleMarkerDrag}
              ></Marker>

              <InfoWindow
                visible={address ? true : false}
                position={{ lat: lat + 0.0018, lng }}
              >
                <div>
                  <span style={{ padding: 0, margin: 0 }}>{address}</span>
                </div>
              </InfoWindow>
            </Map>
          )}
        </div>
      </div>
    );
  }
}

AppMap.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  address: PropTypes.string,
  zoom: PropTypes.number,
  height: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.string
};

AppMap.defaultProps = {
  lat: defaults.lat,
  lng: defaults.lng,
  address: "",
  zoom: 8,
  height: 300,
  label: "Address"
};

export default GoogleApiWrapper({
  apiKey: apiKey
})(AppMap);
