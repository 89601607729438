function validateName(cardHolder) {
  const cardHolderValid = new RegExp(/^[-a-zA-Z' ]{2,26}$/).test(cardHolder);
  let erroeMessage = cardHolderValid
    ? ""
    : cardHolder.length === 0
    ? "Please enter your Card Name"
    : "Please enter a valid Card Name";
  return { erroeMessage: erroeMessage, cardHolderValid: cardHolderValid };
}

function validateNumber(number) {
  const numberValid = new RegExp(/^[0-9]{13,19}$/).test(number);
  let erroeMessage = numberValid
    ? ""
    : number.length === 0
    ? "Please enter your Card Number"
    : "Please enter a valid Card Number";
  return { erroeMessage: erroeMessage, cardNumberValid: numberValid };
}

function validateMMYY(my) {
  const MYValid = new RegExp(/^(0[1-9]|1[0-2])?([0-9]{2})$/).test(my);
  let erroeMessage = MYValid
    ? ""
    : my.length === 0
    ? "Please enter your MMYY"
    : "Please enter a valid MMYY";
  return { erroeMessage: erroeMessage, cardExpMMYYValid: MYValid };
}

function validateCCV(ccv) {
    const CCValid = new RegExp(/^[0-9]{3,4}$/).test(ccv);
    let erroeMessage = CCValid
      ? ""
      : ccv.length === 0
      ? "Please enter your ccv"
      : "Please enter a valid ccv";
    return { erroeMessage: erroeMessage, cardCCVValid: CCValid };
  }

export default {
  validateName,
  validateNumber,
  validateMMYY,
  validateCCV
};
