import React, { Component } from 'react'
import { Button, Input, Modal, ModalBody } from 'reactstrap'
import toastService from '../../services/toast-service';
import userService from '../../services/userService';

export class ConfirmAccountDeletion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: ''
    }
  }

  handleChangeReason = (e) => {
    this.setState({ reason: e.target.value })
  }

  handleSubmit = async () => {
    this.props.toggle();
    const response = await userService.deactivateAccount(this.state.reason);
    if (response === 'success') {
      this.props.toggleSuccess();
    } else {
      toastService.error('An error occurred, please try again later')
    }
  }

  render() {
    return (
      <Modal
        centered
        isOpen={this.props.confirmModal}
        toggle={this.props.toggle}
      >
        <div>
          <ModalBody>
            <label className="popup-body">
              Please let us know why you would like to delete your account
            </label>
            <Input
              className='my-4'
              type="textarea"
              placeholder="Your Reason"
              noValidate
              onChange={this.handleChangeReason}
              value={this.state.reason}
            />
            <div className="d-flex justify-content-center row txtCenter">
              <Button
                color="success"
                className='col-5'
                id="btnCancelYes"
                onClick={this.handleSubmit}
              >
                Send deletion request
              </Button>
              <Button color="danger" id="btnCancelNo" onClick={this.props.toggle}>
                Close
              </Button>
            </div>
          </ModalBody>
        </div>
      </Modal>

    )
  }
}

export default ConfirmAccountDeletion