import React from "react";
import { FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import userService from "../../services/userService";
import FinishRegistration from "./finish_registration";
import verificationCode from "../../services/verificationCode";
import { isBrowser, isMobile } from "react-device-detect";
import {getZlToken,setZlToken} from "../shared/common"
class VerificationCode extends React.Component {
  constructor(props) {
    let userData = getZlToken(localStorage.getItem("ZlToken"));
    super(props);
    this.state = {
      id: userData.id,
      phoneNumber: userData.phone !== null ?userData.phone:"",
      code: "",
      disabled: "",
      timer: null,
      codeError: false,
      errorMessage: "",
      modal: false,
    };
  }

  handleChange = (e) => {
    if (e.target.value.length === 4) {
      this.setState({
        [e.target.id]: e.target.value,
      });
      this.sendCode(e.target.value);
    } else if (e.target.value.length > 4) {
      return;
    } else {
      this.setState({
        [e.target.id]: e.target.value,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  sendCode = async (c) => {
    let code = "";
    if (c) {
      code = c;
    } else {
      code = this.state.code;
    }

    const verifiedPhone = await userService
      .verify_phone(this.state.id, code)
      .then((data) => data)
      .catch((error) => error);

    if (verifiedPhone.result === "authentication required") {
      this.props.history.push("/login");
      localStorage.clear();
    } else if (verifiedPhone && verifiedPhone.result === "Success") {
      clearTimeout(this.state.timer);
      this.setState({ modal: true });
      let newData = getZlToken(localStorage.getItem("ZlToken"));
      newData.phone_verified = true;
      await setZlToken(newData);
    } else {
      this.setState({ errorMessage: true, code: "" });
      document.getElementById("code").focus();
    }
  };

  resendNewCode = async () => {
    this.setState({ code: "" });
    const resendCode = await userService
      .resend_code(this.state.id, this.state.phoneNumber)
      .then((data) => data)
      .catch((error) => error);

    if (resendCode && resendCode.status === 200) {
      this.setState({ codeError: false });
    }
  };

  disableButton = () => {
    this.setState({ disabled: "isDisabled" });
    this.setState({
      timer: setTimeout(
        function () {
          this.setState({ disabled: "" });
          this.explode();
        }.bind(this),
        60000
      ),
    });
  };

  onClickResendCode = () => {
    this.resendNewCode();
    this.disableButton();
    this.activate();
  };

  explode = () => {
    document.getElementById("countdown").hidden = true;
    document.getElementById("countdown-label").hidden = true;
  };

  activate = () => {
    verificationCode.activate();
  };

  render() {
    if (isMobile) {
      return (
        <div className="marTop">
          <Link to="/verifyphone">
            <i className="fa fa-arrow-left" id="backArrow"></i>
          </Link>
          <div id="code-mob-form">
            <h3 id="welcome-verification">Phone Verification</h3>
            <p id="code-account">
              We have sent you an SMS with a code to number{" "}
              {this.state.phoneNumber}
            </p>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <FormGroup>
                <Input
                  sm={2}
                  className="code-input-new"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  name="code"
                  id="code"
                  placeholder=" "
                  value={this.state.code}
                  onChange={this.handleChange.bind(this)}
                />
              </FormGroup>
              <FinishRegistration modal={this.state.modal} />
              <FormGroup>
                {this.state.errorMessage && (
                  <p id="errorCode">Wrong Code please try again</p>
                )}
              </FormGroup>
              <div className="text-center">
                <p className="mb-2 received-code">
                  Haven't received a code yet?
                </p>
                <div>
                  <label
                    className={`mx-2 ${this.state.disabled}`}
                    id="resendCode"
                    onClick={this.onClickResendCode}
                  >
                    Resend a new code{" "}
                    <label id="countdown-label" hidden>
                      in :
                    </label>
                  </label>
                  <div id="countdown" hidden>
                    <div id="countdown-number"></div>
                    <svg>
                      <circle r="18" cx="20" cy="20"></circle>
                    </svg>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }
    if (isBrowser) {
      return (
        <div id="code-mob-form">
          <h3 id="welcome-verification">Phone Verification</h3>
          <p id="code-account">
            We have sent you an SMS with a code to number{" "}
            {this.state.phoneNumber}
          </p>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <FormGroup>
              <Input
                sm={2}
                className="code-input-new"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                name="code"
                id="code"
                placeholder=" "
                value={this.state.code}
                onChange={this.handleChange.bind(this)}
              />
            </FormGroup>
            <FinishRegistration modal={this.state.modal} />
            <FormGroup>
              {this.state.errorMessage && (
                <p id="errorCode">Wrong Code please try again</p>
              )}
            </FormGroup>
            <div className="text-center">
              <p className="mb-2 received-code">Haven't received a code yet?</p>
              <div>
                <label
                  className={`mx-2 ${this.state.disabled}`}
                  id="resendCode"
                  onClick={this.onClickResendCode}
                >
                  Resend a new code{" "}
                  <label id="countdown-label" hidden>
                    in :
                  </label>
                </label>
                <div id="countdown" hidden>
                  <div id="countdown-number"></div>
                  <svg>
                    <circle r="18" cx="20" cy="20"></circle>
                  </svg>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    }
  }
}

export default VerificationCode;

