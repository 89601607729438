import React from "react";
import MealHeader from "../meals/mealHeader";
import ConfirmCancel from "./confirm_cancel";
import Moment from "react-moment";
import MobileHeader from "../shared/mobile_header";
import { isMobile } from "react-device-detect";
import httpService from "../../services/httpService";
import { getZlToken } from "../shared/common";
import companyService from "../../services/companyService";

class OrderInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.location.state ? this.props.location.state.data : null,
      id: this.props.location.state ? this.props.location.state.data.id : null,
      newModal: false,
      hideCancel: false,
      hubMealData: [],
    };
    this.toggel = this.toggel.bind(this);
  }

  componentDidMount = async () => {
    this.handleCorporateName();
    const url = process.env.REACT_APP_APIURL;
    const order = { ...this.state.data }
    const hubMealData = await httpService.get(`${url}/hub_meals/search-criteria-hubs-meals?hub_id=${order.hub_id}&meal_id=${order.meal_id}&date=${order.created}`);
    this.setState({ hubMealData: hubMealData.data.result[0] })
  }

  handleCorporateName = async () => {
    const userData = getZlToken(localStorage.getItem("ZlToken"));
    if (this.state.data.paid_by_user || this.state.data.paid_by_company) {
      const corporateName = await companyService.get_company_details(userData.company_id);
      this.setState({ corporateName: corporateName.result[0].name })
    }
  }

  toggel() {
    this.setState({ newModal: !this.state.newModal });
  }

  back() {
    this.props.history.push("/myorders");
  }
  handelHideCancel = () => {
    this.setState({
      hideCancel: true,
    });
  };
  RedirectToMyOrder = () => {
    this.props.history.push("/dailymenu");
  };

  render() {
    return (
      <div>
        {isMobile ? (
          <MobileHeader title="Order Information" link="/myorders" />
        ) : (
          <MealHeader title={"Order Information"} className="BrowserHeader" />
        )}

        {!isMobile ? (
          <p onClick={() => this.back()}>
            <i className="fa fa-arrow-left profile-back-arrow"></i>
          </p>
        ) : null}

        {this.state.data ? (
          <div className="orderInfoCont">
            <div className="orderHeader text-center text-dark px-2">
              <h5>Order #{this.state.data.id}</h5>
              <p style={{ fontWeight: "600", textTransform: "capitalize" }}>
                {this.state.data.restaurants.name}
              </p>
              <Moment format="ddd, D MMM YYYY" utc="0">
                {this.state.data.created}
              </Moment>
              <p>
                {this.state.data.status_id === 0 && !this.state.hideCancel ? (
                  'Open'
                ) : null}
              </p>
              <p>
                {this.state.data.status_id === 1 && !this.state.hideCancel ? (
                  'Being Made'
                ) : null}
              </p>
              <p>
                {this.state.data.status_id === 2 && !this.state.hideCancel ? (
                  'Delivered'
                ) : null}
              </p>
              <p>
                {this.state.data.status_id === 3 ||
                  this.state.data.status_id === 4 ||
                  this.state.hideCancel ? (
                  'Cancelled'
                ) : null}
              </p>
            </div>
            <div className="orderContent">
              <div className="contentSection">
                <p>Delivery Time:</p>
                <p>{this.state.hubMealData.delivery_time}</p>
              </div>
              <div className="contentSection">
                <p>Pick up:</p>
                <p>{this.state.data.hub.pickup}</p>
              </div>

              <div className="orderPrice">
                <div className="mealNameXamount">
                  <p>
                    {this.state.data.meals.name} x {this.state.data.amount}
                  </p>
                  <p>
                    $
                    {this.state.data.status_id === 3 ||
                      this.state.data.status_id === 4 ||
                      this.state.hideCancel
                      ? 0
                      : this.state.data.total}
                  </p>
                </div>
                <div className="mealNameXamount">
                  <p>Delivery </p>
                  <p>Free</p>
                </div>

                <div className="mealNameXamount">
                  {this.state.data.discount !== 0 ? <p>Discount </p> : null}
                  {this.state.data.discount !== 0 ? (
                    <p>
                      {" "}
                      $
                      {this.state.data.status_id === 3 ||
                        this.state.data.status_id === 4 ||
                        this.state.hideCancel
                        ? 0
                        : this.state.data.discount}
                    </p>
                  ) : null}
                </div>

                <div className="mealNameXamount">
                  <p>HST </p>
                  <p>
                    $
                    {this.state.data.status_id === 3 ||
                      this.state.data.status_id === 4 ||
                      this.state.hideCancel
                      ? 0
                      : (
                        Number(this.state.data.tax) *
                        (Number(this.state.data.total) -
                          Number(this.state.data.discount))
                      ).toFixed(2)}
                  </p>
                </div>
                <hr />
                <div className="mealNameXamount">
                  <p>Total </p>
                  <p>
                    $
                    {this.state.data.status_id === 3 ||
                      this.state.data.status_id === 4 ||
                      this.state.hideCancel
                      ? 0
                      : this.state.data.total_after_discount}
                  </p>
                </div>
                {(this.state.data.paid_by_company || this.state.data.paid_by_user) && (
                  <>
                    <div className="mealNameXamount">
                      <p>Paid By {this.state.corporateName}  </p>
                      <p>
                        $
                        {this.state.data.status_id === 3 ||
                          this.state.data.status_id === 4 ||
                          this.state.hideCancel
                          ? 0
                          : this.state.data.paid_by_company}
                      </p>
                    </div>
                    <div className="mealNameXamount">
                      <p>Paid By You</p>
                      <p>
                        $
                        {this.state.data.status_id === 3 ||
                          this.state.data.status_id === 4 ||
                          this.state.hideCancel
                          ? 0
                          : this.state.data.paid_by_user}
                      </p>
                    </div>
                  </>)
                }
              </div>
              {this.state.data.status_id === 0 ? (
                <div
                  className="cancelButton"
                  onClick={this.toggel.bind(this)}
                  style={{ display: this.state.hideCancel ? "none" : "block" }}
                >
                  Cancel
                  {this.state.newModal && (
                    <ConfirmCancel
                      id={this.state.data.id}
                      newModal={this.state.newModal}
                      toggel={this.toggel}
                      handelHideCancel={this.handelHideCancel}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          this.RedirectToMyOrder()
        )}
      </div>
    );
  }
}

export default OrderInfo;
