import axios from "axios";
import tokenUtils from "./token-utils";
import { UnauthorizedError } from "./errors";
import { trackPromise } from "react-promise-tracker";
import toastService from "./toast-service";
import { isBrowser } from "react-device-detect";

axios.interceptors.request.use(
  (config) => {
    const url = config.url.split("/");
    if (url[url.length - 1] === "login") return config;
    const userData = tokenUtils.getLoggedUser();

    if (userData)
      config.headers.Authorization = `Bearer ${userData.accessToken}`;

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(null, (error) => {
  const { response } = error;
  if (response && response.status >= 400 && response.status < 500)
    return handelExpectedError(error);
  return handelUnexpectedError(error);
});

function handelUnexpectedError(error) {
  if (isBrowser) {
    toastService.error("An unexpected error occurred");
  }
  return Promise.reject(error);
}

function handelExpectedError(error) {
  const { response } = error;

  if (response.status === 401) {
    if (isBrowser) {
      if (response.data.result) {
        toastService.error(response.data.result);
      }
      if (response.data.message) {
        toastService.error(response.data.message);
      }
    }
    return Promise.reject(new UnauthorizedError(response.data, error));
  } else {
    if (response.data.from === "preauthorizeOrder") {
      localStorage.setItem("error",response.data.message)
    }
    else if (isBrowser) {
      if (response.data) {
        if (response.data.result) {
          toastService.error(response.data.result);
        }
        if (response.data.message) {
          toastService.error(response.data.message);
        }
      } else {
        toastService.error("An unexpected error occurred");
      }
    }
    return Promise.reject(error);
  }
}

export default {
  get: (...args) =>
    window.location.href.indexOf("/dailymenu") > -1
      ? axios.get(...args)
      : trackPromise(axios.get(...args)),
  post: (...args) => trackPromise(axios.post(...args)),
  put: (...args) => trackPromise(axios.put(...args)),
  patch: (...args) => trackPromise(axios.patch(...args)),
  delete: (...args) => trackPromise(axios.delete(...args)),
};
