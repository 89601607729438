import jwtDecode from "jwt-decode";

const userKey = "user";

function setLoggedUser(userData) {
  localStorage.setItem(userKey, JSON.stringify(userData));
}

function getLoggedUser() {
  try {
    const userData =JSON.parse( localStorage.getItem(userKey));
    
    if (!userData) return null;

    const tokenDecoded = jwtDecode(userData.accessToken);

    if (isTokensExpired(tokenDecoded)) {
      removeLoggedUser();
      return null;
    }

    return userData
  } catch {
    return null;
  }
}

function removeLoggedUser() {
  localStorage.clear();
}

function isTokensExpired(token) {
  return Date.now() >= token.exp * 1000;
}

export default {
  setLoggedUser,
  getLoggedUser,
  removeLoggedUser
};
