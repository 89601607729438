import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { isIOS } from "react-device-detect";

class MobileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Row
        className={
          isIOS && this.props.style
            ? "ios-safe-padding m-0 ios-header-backgroung fixedPosition"
            : isIOS
            ? "ios-safe-padding m-0 ios-header-backgroung"
            : !isIOS && this.props.style
            ? "profile-header-dev m-0 fixedPosition"
            : "profile-header-dev m-0"
        }
      >
        <Col xs={2}>
          <Link to={this.props.link}>
            <i className="fa fa-times profile-back-arrow"></i>
          </Link>
        </Col>
        <Col xs={8}>
          <p className="profile-header">{this.props.title}</p>
        </Col>
        <Col xs={2}></Col>
        {this.props.date && (
          <div className="MyOrderDate">{this.props.date}</div>
        )}
      </Row>
    );
  }
}

export default MobileHeader;
