import httpService from "./httpService";
import { isMobile } from "react-device-detect";

const url = process.env.REACT_APP_APIURL;

async function get_cards() {
  try {
    const data = await httpService.get(`${url}/getCards`);
    return data.data;
  } catch (error) {
    throw error;
  }
}

async function set_default_card(card_id) {
  try {
    const data = await httpService.post(`${url}/setDefaultCard`, {
      card_id: card_id,
    });
    return data.data;
  } catch (error) {
    throw error;
  }
}

async function add_card(name, card_number, month, year, ccv) {
  try {
    const data = await httpService.post(`${url}/addCard`, {
      card: {
        cardHolder: name,
        cardNumber: card_number,
        cardExpMonth: month,
        cardExpYear: year,
        ccv: ccv,
      },
    });
    return data.data;
  } catch (error) {
    if (isMobile) {
      let element = document.getElementById("ErrorMobile");
      element.classList.remove("hidden");
      element.classList.add("active");
      setTimeout(() => {
        element.classList.add("hidden");
        element.classList.remove("active");
      }, 5000);
    }

    throw error;
  }
}

async function delete_card(card_id) {
  try {
    const data = await httpService.post(`${url}/deleteCard`, {
      card_id: card_id,
    });
    return data.data;
  } catch (error) {
    throw error;
  }
}

export default {
  get_cards,
  set_default_card,
  add_card,
  delete_card,
};
