import React, { Component } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import image from "../../assets/images/green_check.png";
import { Link } from "react-router-dom";
import {getZlToken} from "../shared/common"
class FinishRegistration extends Component {
  constructor (props) {
    super(props);
    let userData = getZlToken(localStorage.getItem("ZlToken"));
    this.state = {
      router: "",
      companyId: userData.company_id,
      modal: false
    };
  }
  routeTo = () => {
    if (this.state.companyId !== null) {
      this.setState({ router: "/dailymenu" });
    } else {
      this.setState({ router: "/selectcompany" });
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  componentDidMount = () => {
    this.routeTo();
  }

  componentDidUpdate = () => {
    if (this.state.modal !== this.props.modal) {
      this.setState({ modal: true });
    }
  }

  render() {
    return (
      <div>
        <Modal
          centered
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={`${this.props.className} reset-password`}
        >
          <div>
            <ModalBody id="reset-password-image-body">
              <img id="reset-password-image" src={image} alt="" />
            </ModalBody>
            <ModalBody>
              <p id="reset-password-popup-body">
                <span role="img" aria-label="finish">
                  🎉
                </span>{" "}
                Your account is verified! Finally, let’s setup your delivery
                preferences
              </p>
              <Link to={this.state.router}>
                <Button color="danger" id="btn">
                  Continue
                </Button>
              </Link>
            </ModalBody>
          </div>
        </Modal>
      </div>
    );
  }
}

export default FinishRegistration;
