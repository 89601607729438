import React from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalBody,
  Button,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import cardValidationService from "../../services/cardValidationService";
import creditCardService from "../../services/creditCardService";

class AddCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      cardHolder: "",
      cardNumber: "",
      cardExpMMYY: "",
      cardCCV: "",
      cardHolderError: "",
      cardNumberError: "",
      cardExpMMYYError: "",
      cardCCVError: "",
      cardHolderValid: false,
      cardNumberValid: false,
      cardExpMMYYValid: false,
      cardCCVValid: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  checkCardHolderValid = () => {
    let cardHolderChecked = cardValidationService.validateName(
      this.state.cardHolder
    );
    this.setState({
      cardHolderError: cardHolderChecked.erroeMessage,
      cardHolderValid: cardHolderChecked.cardHolderValid,
    });
  };

  checkCardNumberValid = () => {
    let cardNumberChecked = cardValidationService.validateNumber(
      this.state.cardNumber
    );

    this.setState({
      cardNumberError: cardNumberChecked.erroeMessage,
      cardNumberValid: cardNumberChecked.cardNumberValid,
    });
  };

  checkCardExpMMYYValid = () => {
    let cardExpMMYYChecked = cardValidationService.validateMMYY(
      this.state.cardExpMMYY
    );
    this.setState({
      cardExpMMYYError: cardExpMMYYChecked.erroeMessage,
      cardExpMMYYValid: cardExpMMYYChecked.cardExpMMYYValid,
    });
  };

  checkCardCCVValid = () => {
    let cardCCVChecked = cardValidationService.validateCCV(this.state.cardCCV);
    this.setState({
      cardCCVError: cardCCVChecked.erroeMessage,
      cardCCVValid: cardCCVChecked.cardCCVValid,
    });
  };

  checkAllFieldsValidations = () => {
    this.checkCardHolderValid();
    this.checkCardNumberValid();
    this.checkCardExpMMYYValid();
    this.checkCardCCVValid();
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.preventDefault();
  };
  handleCardNumberChange = (e) => {
    if (e.keyCode === 8) {
    }
    if (e.target.value.length > 16) return;
    else {
      this.setState({
        [e.target.id]: e.target.value,
      });
      e.preventDefault();
    }
  };
  handleCardCCVChange = (e) => {
    if (onkeydown === 8) {
    }

    if (e.target.value.length > 4) return;
    else {
      this.setState({
        [e.target.id]: e.target.value,
      });
      e.preventDefault();
    }
  };

  handleMonthYearChange = (e) => {
    if (onkeydown === 8) {
    }
    if (e.target.value.length > 4) return;
    else {
      this.setState({
        [e.target.id]: e.target.value,
      });
      e.preventDefault();
    }
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !(
        this.state.cardHolder &&
        this.state.cardNumber &&
        this.state.cardExpMMYY &&
        this.state.cardCCV
      )
    ) {
      this.checkAllFieldsValidations();
      return;
    }

    const month = this.state.cardExpMMYY[0] + this.state.cardExpMMYY[1];
    const year = this.state.cardExpMMYY[2] + this.state.cardExpMMYY[3];
    await creditCardService.add_card(
      this.state.cardHolder,
      this.state.cardNumber,
      month,
      year,
      this.state.cardCCV
    );

    this.setState({
      cardHolder: "",
      cardNumber: "",
      cardExpMMYY: "",
      cardCCV: "",
    });
    this.toggle();
    this.props.getCards();
  };

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newModal !== this.props.newModal) {
      this.setState({ newModal: nextProps.newModal });
      this.toggle();
    }
  }

  handleAddNewCard = () => {
    localStorage.removeItem("mealId")
  }

  render() {
    return (
      <div>
        <Link to="./add_new_card">
          <p onClick={this.handleAddNewCard} className="pl-4 add-visaCard">Add New Card</p>
        </Link>
        <Modal
          centered
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={`${this.props.className} reset-password`}
        >
          <div>
            <ModalBody>
              <p className="popup-body">Add a credit card</p>
              <form onSubmit={this.handleSubmit}>
                <FormGroup className="sign-up-form">
                  <Input
                    className={`form-group form-input ${this.errorClass(
                      this.state.cardHolderError
                    )}`}
                    type="text"
                    name="cardHolder"
                    id="cardHolder"
                    placeholder="Name on Card"
                    noValidate
                    onChange={this.handleChange}
                    value={this.state.value}
                    onBlur={this.checkCardHolderValid}
                  />
                </FormGroup>
                <FormGroup className="sign-up-form">
                  <Input
                    className={`form-group form-input ${this.errorClass(
                      this.state.cardNumberError
                    )}`}
                    type="number"
                    name="cardNumber"
                    id="cardNumber"
                    placeholder="Card Number"
                    noValidate
                    onChange={this.handleCardNumberChange}
                    value={this.state.cardNumber}
                    onBlur={this.checkCardNumberValid}
                  />
                </FormGroup>
                <Row form>
                  <Col xs={6}>
                    <FormGroup>
                      <Input
                        className={`form-group form-input ${this.errorClass(
                          this.state.cardExpMMYYError
                        )}`}
                        type="number"
                        name="cardExpMMYY"
                        id="cardExpMMYY"
                        placeholder="MMYY"
                        noValidate
                        onChange={this.handleMonthYearChange}
                        value={this.state.cardExpMMYY}
                        onBlur={this.checkCardExpMMYYValid}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Input
                        className={`form-group form-input ${this.errorClass(
                          this.state.cardCCVError
                        )}`}
                        pattern="[0-9]*"
                        type="number"
                        inputMode="password"
                        name="cardCCV"
                        id="cardCCV"
                        placeholder="CVV"
                        noValidate
                        onChange={this.handleCardCCVChange}
                        value={this.state.cardCCV}
                        onBlur={this.checkCardCCVValid}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className="mb-0">
                  <Button color="danger" id="btn" type="submit">
                    Add Card
                  </Button>
                </FormGroup>
                <FormGroup className="cancel-add-card">
                  <span onClick={this.toggle}>Cancel</span>
                </FormGroup>
              </form>
            </ModalBody>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AddCardForm;
