import React, { Component } from "react";
import CarouselHome from "./carousel.jsx";
import {getZlToken} from "../components/shared/common"
class Home extends Component {
  state = {
    logedIn: localStorage.getItem("user")
  };

  UNSAFE_componentWillMount = () => {
    let userData = getZlToken(localStorage.getItem("ZlToken"));
    if (this.state.logedIn) {
      let phone_verified = userData.phone_verified;
      if (this.state.logedIn && phone_verified) {
        this.props.history.push("/dailymenu");
      }
    }
  };

  render() {
    return (
      <div>
        <CarouselHome />
      </div>
    );
  }
}

export default Home;
