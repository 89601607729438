import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import image from "../../assets/images/green_check.png";
import { Link } from "react-router-dom";

class RequestReceived extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newModal !== this.props.newModal) {
      this.setState({ newModal: nextProps.newModal });
      this.toggle();
    }
  }

  render() {
    return (
      <div>
        <Modal
          centered
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={`${this.props.className} reset-password`}
        >
          <div>
            <ModalBody id="reset-password-image-body">
              <img id="reset-password-image" src={image} alt="" />
            </ModalBody>
            <ModalBody>
              <p className="popup-body">Request Received</p>
              <p className="verify-phone-text">
                Thanks a lot for your interest in ZipLunch. Your request has
                been filed. Our admins will notify you once your location is
                available.
              </p>
              <Link to="/selectcompany">
                <Button color="danger" id="btn" onClick={this.toggle}>
                  Continue
                </Button>
              </Link>
            </ModalBody>
          </div>
        </Modal>
      </div>
    );
  }
}

export default RequestReceived;
