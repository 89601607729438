import React, { Component } from "react";
import { Button, Col, Row, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import image from "../../assets/images/food.png";
import userService from "../../services/userService";
import validationService from "../../services/validationService";
import image2 from "../../assets/images/loginweb.png";
import { isBrowser } from "react-device-detect";
import browserType from "../../services/browserType";
import urlService from "../../services/urlService";
import {getZlToken,setZlToken} from "../shared/common";

const browser = browserType();

if (browser === "ie" || browser === "edge") {
  window.location.hash = "#/unsupported";
}
class LogIn extends Component {
  state = {
    logedIn: localStorage.getItem("user"),
    email: "",
    password: "",
    APIError: false,
    emailError: "",
    passwordError: "",
    emailValid: false,
    passwordValid: false,
    formValid: false,
  };

  UNSAFE_componentWillMount = () => {
    let userData = getZlToken(localStorage.getItem("ZlToken"));
    if (this.state.logedIn) {
      let phone_verified = userData.phone_verified;
      if (this.state.logedIn && phone_verified) {
        this.props.history.push("/dailymenu");
        window.scrollTo(0, 0);
      }
    }
  };

  checkEmailValid = () => {
    let eamilChecked = validationService.validatEmail(this.state.email);
    this.setState({
      emailError: eamilChecked.erroeMessage,
      emailValid: eamilChecked.emailValid,
      formValid: eamilChecked.emailValid,
    });
  };

  checkPasswordValid = () => {
    let passwordChecked = validationService.validatPassword(
      this.state.password
    );
    this.setState({
      passwordError: passwordChecked.erroeMessage,
      passwordValid: passwordChecked.passwordValid,
      formValid: passwordChecked.passwordValid,
    });
  };

  checkAllFieldsValidations = () => {
    this.checkPasswordValid();
    this.checkEmailValid();
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.preventDefault(e);
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.state.formValid) {
      this.checkAllFieldsValidations();
      return;
    }

    const loggedIn = await userService
      .login(this.state.email, this.state.password)
      .then(async (data) => {
            await setZlToken(data);
            return(data);
      })
      .catch((error) => error);

    if (loggedIn.company_id === null) {
      this.props.history.push("/selectcompany");
    } else if (loggedIn.message === "Success") {
      let userData = getZlToken(localStorage.getItem("ZlToken"));
      userData.id=loggedIn.id;
      await setZlToken(userData);
      // localStorage.setItem("id", loggedIn.id);
      if (isBrowser) {
        var OneSignal = window.OneSignal || [];
        // OneSignal.push(["sendTag", "requesting_meal", "Requesting"]);
        await OneSignal.push(function () {
          OneSignal.getUserId(async function (userId) {
            await userService
              .setOneSignalId(loggedIn.id, userId)
              .then((data) => localStorage.setItem("notifyId", userId))
              .catch((error) => {
                console.log("OOPS", error);
              });
          });
          //OneSignal.setExternalUserId(loggedIn.id); akhudairy: commented because it causes crash on iOS and we don't need it now
        });
      }

      const url = new URL(window.location.href);
      let tempUrl = url.href.split("/");
      const prev =
        urlService.getParameterByName("prev") || localStorage.getItem("prev");

      tempUrl.pop();
      tempUrl.push(prev);
      if (prev) {
        window.location.replace(tempUrl.join("/"));
        localStorage.removeItem("prev");
      } else {
        this.props.history.push("/dailymenu");
        window.scrollTo(0, 0);
      }
    } else {
      this.setState({ APIError: true });
    }
  };

  render() {
    return (
      <div>
        <Link className="login-back" to="/">
          <i className="fa fa-arrow-left xs-block d-md-none" id="backArrow"></i>
        </Link>
        <Row className="m-0">
          <Col sm="12" id="Right" className="p-0 xs-block d-md-none">
            <img src={image} alt="" />
          </Col>
          <Col md="12" id="Right" className="p-0 d-none d-md-block">
            <img src={image2} alt="" />
          </Col>
          <Col md="12" sm="12" className="buttomDiv">
            <div id="login-mob-form">
              <h3 id="login-title">Welcome back</h3>
              <p id="login-account">Log in to your account</p>
              {this.state.APIError && (
                <label className="errorMessage apiError">
                  Your email or password is incorrect. Please try again.
                </label>
              )}
              <form onSubmit={this.handleSubmit}>
                <FormGroup className="sign-up-form">
                  <Input
                    className={`form-group form-input ${this.errorClass(
                      this.state.emailError
                    )}`}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    noValidate
                    value={this.state.value}
                    onChange={this.handleChange}
                    onBlur={this.checkEmailValid}
                  />
                  {this.state.emailError.length > 0 && (
                    <label className="errorMessage">
                      {this.state.emailError}
                    </label>
                  )}
                </FormGroup>
                <FormGroup className="sign-up-form">
                  <Input
                    className={`form-group form-input ${this.errorClass(
                      this.state.passwordError
                    )}`}
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    noValidate
                    onChange={this.handleChange}
                    value={this.state.value}
                    onBlur={this.checkPasswordValid}
                  />
                  {this.state.passwordError && (
                    <label className="errorMessage">
                      {this.state.passwordError}
                    </label>
                  )}
                </FormGroup>
                <FormGroup>
                  <Button color="danger" id="btn" type="submit">
                    Log In
                  </Button>
                </FormGroup>
              </form>
              <div className="text-center" style={{ marginTop: 20 }}>
                <p className="forgot-you-password">
                  <Link to="/forgotpassword" id="forgotPassword">
                    Forgot your password?
                  </Link>
                </p>
                <p>
                  Don't have an account?{" "}
                  <Link to="/searchaddress" id="link-to">
                    Sign up
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default LogIn;
