import React, { memo } from "react";
const s = {
  container: "abs fullW fullH",
  slideImage: "fullH fullW imgCover"
};
const Slide = ({ position, transition, image, item }) => {
  return (
    <div className={s.container + " " + position + " " + transition}>
      <img src={image} className={s.slideImage} alt="slide" />
      <div className="d-block">
        <h3>{item.altText}</h3>
        <p>{item.caption}</p>
      </div>
    </div>
  );
};

export default memo(Slide);
