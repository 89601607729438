import httpService from "./httpService";

const url = process.env.REACT_APP_APIURL;

// by monther
async function get_meal_by_id(mealId) {
  const data = await httpService.get(`${url}/meals/get-meal?id=${mealId}`);
  return data;
}

async function get_Meals() {
  const data = await httpService.get(`${url}/meals/get-meals`);
  return data;
}

async function post_Order(hubMealId, mealId, amount, date, corporateLimit, corporateCardId) {
  const token = localStorage.getItem("ZlToken")
  const userToken = localStorage.getItem("user");
  const data = await httpService
    .post(
      `${url}/orders/create-order${corporateCardId ? (`?corporate_limit=${corporateLimit}&corporate_card_id=${corporateCardId}`) : ""}`,
      {
        details: {
          hub_meal_id: hubMealId,
          meal_id: mealId,
          amount: amount,
          date: date,
        },
      },
      { withCredentials: true }
    )
    .catch((error) => {
      console.log(error);
    });
  return data;
}

async function get_Weekly_Orders(date) {
  const data = await httpService.get(
    `${url}/orders/weekly-orders?date=${date}`,
    {
      withCredentials: true,
    }
  );
  return data.data;
}

// /api/orders/v2/weekly-orders?date=2020-02-13
// /orders/get-customer-orders?start_date=2020-03-01&end_date=2020-03-30
// eslint-disable-next-line no-unused-vars
async function get_Weekly_Orders_V2(date, isForceDate = false) {
  let forceDateAddition = "";
  if (isForceDate) {
    forceDateAddition = "force_start_date=true";
  }
  const data = await httpService.get(
    `${url}/orders/v2/weekly-orders?date=${date}&${forceDateAddition}`,
    {
      withCredentials: true,
    }
  );
  return data.data;
}

async function get_Orders(userId, date) {
  const data = await httpService.get(
    `${url}/orders/get-orders?id=${userId}&date=${date}`,
    {
      withCredentials: true,
    }
  );
  return data.data;
}

async function cancel_Order(orderId, userId) {
  const data = await httpService
    .post(
      `${url}/orders/cancel-order`,
      {
        order_ids: orderId,
        user_id: userId,
      },
      {
        withCredentials: true,
      }
    )
    .then((data) => data)
    .catch((error) => error.response);
  return data;
}

async function get_Message() {
  const data = await httpService.get(
    `${url}/contents/content-by-address-id`,
    {
      withCredentials: true,
    }
  );
  return data.data;
}

export default {
  get_Meals,
  get_meal_by_id,
  post_Order,
  get_Orders,
  cancel_Order,
  get_Weekly_Orders,
  get_Weekly_Orders_V2,
  get_Message,
};
