import { toast } from 'react-toastify';

const defaultOptions = {
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: true,
  pauseOnFocusLoss: false,
  autoClose: 3000,
  bodyClassName: 'toast-message'
};

const success = (message, options = defaultOptions) => {
  toast.success(message, options);
};

const error = (message, options = defaultOptions) => {
  toast.error(message, options);
};

const warn = (message, options = defaultOptions) => {
  toast.warn(message, options);
};

const info = (message, options = defaultOptions) => {
  toast.info(message, options);
};
export default {
  success,
  error,
  warn,
  info
};
