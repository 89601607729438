import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Spinner } from 'reactstrap';

export default props => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div className="spinnerdiv">
        <Spinner className="spinner" style={{ width: '3rem', height: '3rem' }} color="danger" />
      </div>
    )
  );
};
