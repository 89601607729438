/* eslint-disable */ import React from "react";
import { isBrowser, isMobile } from "react-device-detect";
import MealHeader from "../meals/mealHeader";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, CardText } from "reactstrap";
import cardService from "../../services/creditCardService";
import EmployeeService from "../../services/employeeService";
import AddCardForm from "./add_card_from";
import ConfirmDelete from "./confirm_delete";
import MobileHeader from "../shared/mobile_header";
import { getZlToken } from "../shared/common"
import CompanyInfo from "./company_info";

class PaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      logo: [{ V: "fab fa-cc-visa" }, { MC: "fab fa-cc-mastercard" }]
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    e.preventDefault();
  };

  handleCompaniesData = async (id) => {
    const employeeCorporatesInfo = await EmployeeService.handleCompaniesData(id);
    if (employeeCorporatesInfo.result) {
      this.setState({ employeeCorporatesInfo });
    }
  }

  getUserInfo = () => {
    const token = JSON.parse(localStorage.getItem("user")).accessToken;
    const userInfo = getZlToken(token);
    this.handleCompaniesData(userInfo.id);
    this.setState({ userInfo });
  }

  getCards = async () => {
    const cards = await cardService.get_cards();
    let arrClone = [...cards.result];
    let final = [];
    cards.result.map((e, i) => {
      if (e.isdefault === "true") {
        final = arrClone.splice(i, 1);
      }
    });
    this.setState({ cards: [...final, ...arrClone] });
  };

  makeDefault = async card_id => {
    await cardService.set_default_card(card_id);
    this.getCards();
  };

  componentDidMount = async () => {
    this.getCards();
    this.getUserInfo();
  };

  render() {
    if (isMobile) {
      return (
        <div>
          <MobileHeader title="Payment Method" link="/profilepage" />
          <div className="gray">
            <p className="pt-4 pl-4">CREDIT CARDS</p>
            {this.state.cards.map((c, i) => (
              <Card
                key={i}
                className={`ml-4 mr-4 pt-3 pb-3 mb-3 credit-card ${c.isdefault === "true" ? "dafaul-border" : ""
                  }`}
              >
                <Row className="m-0">
                  <Col xs="3">
                    {this.state.logo.map((l, i) => (
                      <i key={i} className={l[c.cardType]}></i>
                    ))}
                  </Col>
                  <Col xs="7" className="m-auto p-0">
                    <span>**** **** **** {c.cardNumber}</span>
                  </Col>

                  <Col xs="2" className="m-auto">
                    <ConfirmDelete card_id={c.id} getCards={this.getCards} />
                  </Col>
                </Row>

                {c.isdefault === "true" ? (
                  <span className="default"></span>
                ) : (
                  <div className="ml-3 p-0 mt-2">
                    <span
                      className="make-default"
                      onClick={e => {
                        this.makeDefault(c.id);
                      }}
                    >
                      Set as Default
                    </span>
                  </div>
                )}
              </Card>
            ))}
            <AddCardForm getCards={this.getCards} />

            {this.state.employeeCorporatesInfo && this.state.employeeCorporatesInfo.result.length > 0 && (
              <>
                <span className="pt-4 pl-4 mt-5">COMPANIES</span>
                <Row md={3}>
                  {this.state.employeeCorporatesInfo.result.map(data => {
                    return <CompanyInfo companyInfo={data} key={`data-${data.id}`} />
                  })}
                </Row>
              </>
            )}
          </div>
        </div>
      );
    }
    if (isBrowser) {
      return (
        <div className="payment-browser-body m-0">
          <MealHeader title={"Payment Method"} />
          <div className="gray notification-form-web">
            <div className="gray">
              <p className="pt-4 pl-4">CREDIT CARDS</p>
              <Row>
                {this.state.cards.map((c, i) => (
                  <Col key={i} xs={12} md={4}>
                    <Card className="ml-4 mr-4 pt-3 pb-3 mb-3 credit-card">
                      <Row className="m-0">
                        <Col xs="3">
                          {this.state.logo.map((l, i) => (
                            <i key={i} className={l[c.cardType]}></i>
                          ))}
                        </Col>
                        <Col xs="8" className="m-auto p-0">
                          <span>**** **** **** {c.cardNumber}</span>
                        </Col>

                        <Col xs="1" className="m-auto p-0">
                          <ConfirmDelete
                            card_id={c.id}
                            getCards={this.getCards}
                          />
                        </Col>
                      </Row>

                      {c.isdefault === "true" ? (
                        <span className="default"></span>
                      ) : (
                        <div className="ml-3 p-0 mt-2">
                          <span
                            className="make-default"
                            onClick={e => {
                              this.makeDefault(c.id);
                            }}
                          >
                            Make this Card Default?
                          </span>
                        </div>
                      )}
                    </Card>
                  </Col>
                ))}
              </Row>
              <AddCardForm getCards={this.getCards} />

              {this.state.employeeCorporatesInfo && this.state.employeeCorporatesInfo.result.length > 0 && (
                <>
                  <span className="pt-4 pl-4 mt-5">COMPANIES</span>
                  <Row md={3}>
                    {this.state.employeeCorporatesInfo.result.map(data => {
                      return <CompanyInfo companyInfo={data} key={`data-${data.id}`} />
                    })}
                  </Row>
                </>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PaymentMethod;
