import React, { Component } from 'react';
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
 
 export default class Toast extends Component {
    notify = () => toast("Unexpected Error");
    render(){
      return (
        <div>
          <ToastContainer />
        </div>
      );
    }
  }