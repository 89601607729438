import { setZlToken } from "../components/shared/common";
import httpService from "./httpService";
import toastService from "./toast-service";

const url = process.env.REACT_APP_APIURL;

async function add_company(name, location, unit, floor, lat, lng) {
  const data = await httpService.post(`${url}/companies/request-company`, {
    name: name,
    location: location,
    unit: unit,
    floor: floor,
    lat: lat,
    lng: lng,
  });
  return data;
}

async function get_company() {
  const data = await httpService.get(`${url}/companies/get-companies`);
  return data;
}

async function assign_address(id, companyId) {
  const data = await httpService.post(`${url}/users/assign-company`, {
    id: id,
    company_id: companyId,
  });
  return data;
}

async function get_company_details(companyId) {
  const data = await httpService.get(
    `${url}/companies/get-company?company_id=${companyId}`
  );
  return data.data;
}

async function check_company(companyName) {
  const data = await httpService.get(
    `${url}/companies/company-exists?name=${companyName}`
  );
  return data.data;
}

async function get_address(address) {
  const data = await httpService.get(
    `${url}/companies/get-companies-filtered?key=${address}`
  );
  return data.data.result;
}

async function add_new_address(address, email, company) {
  const data = await httpService.post(`${url}/request-new-address`, {
    location: address,
    email: email,
    comp_name: company,
  });
  return data.data;
}

const deleteUserAddress = async (id) => {
  await httpService.put(`${url}/user_addresses/delete-user-Addresses?id=${id}`);
};

const updateUserAddress = async (id, name) => {
  await httpService.put(`${url}/user_addresses/update-user-Addresses`, { id, name });
};

const getUserAddresses = async (user_id) => {
  const data = await httpService.get(`${url}/user_addresses/get-user-Addresses-by-user-id?user_id=${user_id}`);
  return data.data.result;
};

const addAddress = async (user_id, address_id, name) => {
  const data = await httpService.post(`${url}/user_addresses/create-user-Addresses?user_id=${user_id}&address_id=${address_id}&name=${name}`);
  if (data.data.result === 'success') {
    toastService.success('The Address Added Successfully');
  }
  else if (data.data.result === 'duplicated') {
    toastService.error('Address already exists');
  }
  else {
    toastService.error('Unexpected error happened');
  };
  return data
};

const changeUserAddress = async (newData, addressId) => {
  await setZlToken(newData);
  const addressAssigned = await assign_address(newData.id, addressId);
  const user = JSON.parse(localStorage.user);
  user.accessToken = addressAssigned.headers.authorization;
  localStorage.setItem("user", JSON.stringify(user));
}

export default {
  add_company,
  get_company,
  get_company_details,
  assign_address,
  check_company,
  get_address,
  add_new_address,
  deleteUserAddress,
  getUserAddresses,
  addAddress,
  changeUserAddress,
  updateUserAddress
};
