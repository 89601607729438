/* eslint-disable */
import React from "react";
import { Row, Col } from "reactstrap";
import { isBrowser, isMobile } from "react-device-detect";
import MealHeader from "../meals/mealHeader";
import notificationService from "../../services/notificationService";
import MobileHeader from "../shared/mobile_header";

class NotificationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      daily_push: false,
      daily_sms: false,
      daily_email: false,
      daily_slack: false,
      weekly_menu_email: false,
      weekly_menu_slack: false,
      weekly_menu_whatsapp: false,
      weekly_summary_email: false
    };
  }

  showen = state => {
    if (state === true) {
      return "red";
    } else {
      return "hide";
    }
  };

  red = state => {
    if (state === true) {
      return "red";
    } else {
      return "";
    }
  };

  getNotifications = async () => {
    const result = await notificationService.get_notifications();
    this.setState({
      daily_push: result.daily_push,
      daily_sms: result.daily_sms,
      daily_email: result.daily_email,
      daily_slack: result.daily_slack,
      weekly_menu_email: result.weekly_menu_email,
      weekly_menu_slack: result.weekly_menu_slack,
      weekly_menu_whatsapp: result.weekly_menu_whatsapp,
      weekly_summary_email: result.weekly_summary_email
    });
  };

  setNotifications = async () => {
    await notificationService.set_notifications(this.state);
  };

  handleClick = e => {
    let state = e.target.id;
    this.setState(
      prevState => ({
        [state]: !prevState[state]
      }),
      () => {
        this.setNotifications();
      }
    );
  };

  UNSAFE_componentWillMount() {
    const user = localStorage.getItem("user");
    if (!user) { //if not logged in redirect to login
      let x = window.location.href.match(/\/(\w+)$/)[1];
      window.location.replace(`#/login?prev=${x}`);
    }
    else {
      this.getNotifications();
    }
    
  }

  render() {
    if (isMobile) {
      return (
        <div>
          <MobileHeader title="Notification Settings" link="/profilepage" />
          <div className="gray notification-form">
            <div className="notification-content">
              <p className="notification-title">DAILY ORDER REMINDERS</p>
              {this.state.daily_push == true ? (
                <Row
                  id="daily_push"
                  className={this.red(this.state.daily_push)}
                  onClick={this.handleClick}
                >
                  <Col xs={11}>
                    <p id="daily_push">Push Notification</p>
                  </Col>
                  <Col xs={1}>
                    <i
                      id="daily_push"
                      className={`fas fa-check ${this.showen(
                        this.state.daily_push
                      )}`}
                    ></i>
                  </Col>
                </Row>
              ) : (
                  <Row
                    id="daily_push"
                    className={this.red(this.state.daily_push)}
                    onClick={this.handleClick}
                  >
                    <Col xs={11}>
                      <p id="daily_push">Push Notification</p>
                    </Col>
                  </Row>
                )}
              {this.state.daily_sms == true ? (
                <Row
                  id="daily_sms"
                  className={this.red(this.state.daily_sms)}
                  onClick={this.handleClick}
                >
                  <Col xs={11}>
                    <p id="daily_sms">SMS</p>
                  </Col>
                  <Col xs={1}>
                    <i
                      id="daily_sms"
                      className={`fas fa-check ${this.showen(
                        this.state.daily_sms
                      )}`}
                    ></i>
                  </Col>
                </Row>
              ) : (
                  <Row
                    id="daily_sms"
                    className={this.red(this.state.daily_sms)}
                    onClick={this.handleClick}
                  >
                    <Col xs={11}>
                      <p id="daily_sms">SMS</p>
                    </Col>
                  </Row>
                )}
              {this.state.daily_email == true ? (
                <Row
                  id="daily_email"
                  className={this.red(this.state.daily_email)}
                  onClick={this.handleClick}
                >
                  <Col xs={11}>
                    <p id="daily_email">Email</p>
                  </Col>
                  <Col xs={1}>
                    <i
                      id="daily_email"
                      className={`fas fa-check ${this.showen(
                        this.state.daily_email
                      )}`}
                    ></i>
                  </Col>
                </Row>
              ) : (
                  <Row
                    id="daily_email"
                    className={this.red(this.state.daily_email)}
                    onClick={this.handleClick}
                  >
                    <Col xs={11}>
                      <p id="daily_email">Email</p>
                    </Col>
                  </Row>
                )}
              {/* <Row
                id="daily_slack"
                className={this.red(this.state.daily_slack)}
                onClick={this.handleClick}
              >
                <Col xs={11}>
                  <p id="daily_slack">Slack</p>
                </Col>
                <Col xs={1}>
                  <i
                    id="daily_slack"
                    className={`fas fa-check ${this.showen(
                      this.state.daily_slack
                    )}`}
                  ></i>
                </Col>
              </Row> */}
            </div>
            <div className="notification-content">
              <p className="notification-title">WEEKLY MENU REMINDERS</p>
              {this.state.weekly_menu_email == true ? (
                <Row
                  id="weekly_menu_email"
                  className={this.red(this.state.weekly_menu_email)}
                  onClick={this.handleClick}
                >
                  <Col xs={11}>
                    <p id="weekly_menu_email">Email</p>
                  </Col>
                  <Col xs={1}>
                    <i
                      id="weekly_menu_email"
                      className={`fas fa-check ${this.showen(
                        this.state.weekly_menu_email
                      )}`}
                    ></i>
                  </Col>
                </Row>
              ) : (
                  <Row
                    id="weekly_menu_email"
                    className={this.red(this.state.weekly_menu_email)}
                    onClick={this.handleClick}
                  >
                    <Col xs={11}>
                      <p id="weekly_menu_email">Email</p>
                    </Col>
                  </Row>

                )}

            </div>
            {/*
              <Row
                id="weekly_menu_slack"
                className={this.red(this.state.weekly_menu_slack)}
                onClick={this.handleClick}
              >
                <Col xs={11}>
                  <p id="weekly_menu_slack">Slack</p>
                </Col>
                <Col xs={1}>
                  <i
                    id="weekly_menu_slack"
                    className={`fas fa-check ${this.showen(
                      this.state.weekly_menu_slack
                    )}`}
                  ></i>
                </Col>
              </Row>
              <Row
                id="whatsApp"
                className={this.red(this.state.weekly_menu_whatsapp)}
                onClick={this.handleClick}
              >
                <Col xs={11}>
                  <p id="weekly_menu_whatsapp">WhatsApp</p>
                </Col>
                <Col xs={1}>
                  <i
                    id="weekly_menu_whatsapp"
                    className={`fas fa-check ${this.showen(
                      this.state.weekly_menu_whatsapp
                    )}`}
                  ></i>
                </Col>
              </Row>
            </div>
            <div className="notification-content">
              <p className="notification-title">WEEKLY SUMMARY EMAIL</p>
              <Row
                id="weekly_summary_email"
                className={this.red(this.state.weekly_summary_email)}
                onClick={this.handleClick}
              >
                <Col xs={11}>
                  <p id="weekly_summary_email">On</p>
                </Col>
                <Col xs={1}>
                  <i
                    id="weekly_summary_email"
                    className={`fas fa-check ${this.showen(
                      this.state.weekly_summary_email
                    )}`}
                  ></i>
                </Col>
              </Row>
              <Row
                id="weekly_summary_email"
                className={this.red(!this.state.weekly_summary_email)}
                onClick={this.handleClick}
              >
                <Col xs={11}>
                  <p id="weekly_summary_email">Off</p>
                </Col>
                <Col xs={1}>
                  <i
                    id="weekly_summary_email"
                    className={`fas fa-check ${this.showen(
                      !this.state.weekly_summary_email
                    )}`}
                  ></i>
                </Col>
              </Row>
            </div> */}
          </div>
        </div>
      );
    }
    if (isBrowser) {
      return (
        <div className="notification-body m-0">
          <MealHeader title={"Notification Settings"} />
          <div className="gray notification-web">
            <div className="notification-content">
              <p className="notification-title">DAILY ORDER REMINDERS</p>
              {this.state.daily_push == true ? (
                <Row
                  id="daily_push"
                  className={this.red(this.state.daily_push)}
                  onClick={this.handleClick}
                >
                  <Col xs={11}>
                    <p id="daily_push">Push Notification</p>
                  </Col>
                  <Col xs={1}>
                    <i
                      id="daily_push"
                      className={`fas fa-check ${this.showen(
                        this.state.daily_push
                      )}`}
                    ></i>
                  </Col>
                </Row>
              ) : (
                  <Row
                    id="daily_push"
                    className={this.red(this.state.daily_push)}
                    onClick={this.handleClick}
                  >
                    <Col xs={11}>
                      <p id="daily_push">Push Notification</p>
                    </Col>
                  </Row>
                )}
              {this.state.daily_sms == true ? (
                <Row
                  id="daily_sms"
                  className={this.red(this.state.daily_sms)}
                  onClick={this.handleClick}
                >
                  <Col xs={11}>
                    <p id="daily_sms">SMS</p>
                  </Col>
                  <Col xs={1}>
                    <i
                      id="daily_sms"
                      className={`fas fa-check ${this.showen(
                        this.state.daily_sms
                      )}`}
                    ></i>
                  </Col>
                </Row>
              ) : (
                  <Row
                    id="daily_sms"
                    className={this.red(this.state.daily_sms)}
                    onClick={this.handleClick}
                  >
                    <Col xs={11}>
                      <p id="daily_sms">SMS</p>
                    </Col>
                  </Row>
                )}
              {this.state.daily_email ? (
                <Row
                  id="daily_email"
                  className={this.red(this.state.daily_email)}
                  onClick={this.handleClick}
                >
                  <Col xs={11}>
                    <p id="daily_email">Email</p>
                  </Col>
                  <Col xs={1}>
                    <i
                      id="daily_email"
                      className={`fas fa-check ${this.showen(
                        this.state.daily_email
                      )}`}
                    ></i>
                  </Col>
                </Row>
              ) : (
                  <Row
                    id="daily_email"
                    className={this.red(this.state.daily_email)}
                    onClick={this.handleClick}
                  >
                    <Col xs={11}>
                      <p id="daily_email">Email</p>
                    </Col>
                  </Row>
                )}
              {/* <Row
                id="daily_slack"
                className={this.red(this.state.daily_slack)}
                onClick={this.handleClick}
              >
                <Col xs={11}>
                  <p id="daily_slack">Slack</p>
                </Col>
                <Col xs={1}>
                  <i
                    id="daily_slack"
                    className={`fas fa-check ${this.showen(
                      this.state.daily_slack
                    )}`}
                  ></i>
                </Col>
              </Row> */}
            </div>
            <div className="notification-content">
              <p className="notification-title">WEEKLY MENU REMINDERS</p>
              <Row
                id="weekly_menu_email"
                className={this.red(this.state.weekly_menu_email)}
                onClick={this.handleClick}
              >
                <Col xs={11}>
                  <p id="weekly_menu_email">Email</p>
                </Col>
                <Col xs={1}>
                  <i
                    id="weekly_menu_email"
                    className={`fas fa-check ${this.showen(
                      this.state.weekly_menu_email
                    )}`}
                    style={{
                      display: this.state.weekly_menu_email ? "block" : "none"
                    }}
                  ></i>
                </Col>
              </Row>
            </div>
            {/*
              <Row
                id="weekly_menu_slack"
                className={this.red(this.state.weekly_menu_slack)}
                onClick={this.handleClick}
              >
                <Col xs={11}>
                  <p id="weekly_menu_slack">Slack</p>
                </Col>
                <Col xs={1}>
                  <i
                    id="weekly_menu_slack"
                    className={`fas fa-check ${this.showen(
                      this.state.weekly_menu_slack
                    )}`}
                  ></i>
                </Col>
              </Row>
              <Row
                id="whatsApp"
                className={this.red(this.state.weekly_menu_whatsapp)}
                onClick={this.handleClick}
              >
                <Col xs={11}>
                  <p id="weekly_menu_whatsapp">WhatsApp</p>
                </Col>
                <Col xs={1}>
                  <i
                    id="weekly_menu_whatsapp"
                    className={`fas fa-check ${this.showen(
                      this.state.weekly_menu_whatsapp
                    )}`}
                  ></i>
                </Col>
              </Row>
            </div>
            <div className="notification-content">
              <p className="notification-title">WEEKLY SUMMARY EMAIL</p>
              <Row
                id="weekly_summary_email"
                className={this.red(this.state.weekly_summary_email)}
                onClick={this.handleClick}
              >
                <Col xs={11}>
                  <p id="weekly_summary_email">On</p>
                </Col>
                <Col xs={1}>
                  <i
                    id="weekly_summary_email"
                    className={`fas fa-check ${this.showen(
                      this.state.weekly_summary_email
                    )}`}
                  ></i>
                </Col>
              </Row>
              <Row
                id="weekly_summary_email"
                className={this.red(!this.state.weekly_summary_email)}
                onClick={this.handleClick}
              >
                <Col xs={11}>
                  <p id="weekly_summary_email">Off</p>
                </Col>
                <Col xs={1}>
                  <i
                    id="weekly_summary_email"
                    className={`fas fa-check ${this.showen(
                      !this.state.weekly_summary_email
                    )}`}
                  ></i>
                </Col>
              </Row>
            </div> */}
          </div>
        </div>
      );
    }
  }
}

export default NotificationSettings;
