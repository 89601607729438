import React from "react";
import OrderSuccess from "./order_success";
import {
  Button,
  Modal,
  ModalBody,
  ButtonGroup,
  CardImg,
  Row,
  Col,
  CardTitle,
  Input,
  CardText,
} from "reactstrap";
import mealsService from "../../services/mealsService";
import AddCardOrder from "../profile/add_card_order";
import filestackService from "../../services/file-stackService";
import image from "../../assets/images/loading.gif";
import OrderFailure from "./order_failure";
import closeImage from "../../assets/images/close_card.png";
import { checkCutOffTime, getZlToken } from "../shared/common";
const mealTypes = Object.freeze({
  breakfast: "Breakfast",
  dinner: "Dinner",
  lunch: "Lunch",
});

class MealDescription extends React.Component {
  constructor(props) {
    let userData = getZlToken(localStorage.getItem("ZlToken"));
    super(props);
    this.state = {
      personIsEmployee: false,
      cards: this.props.cards,
      userId: userData.id,
      modal: false,
      newModal: false,
      paymentForm: false,
      mealSelect: "1",
      mealType: this.props?.meal?.restaurants?.meal_type,
      newPrice: this.props.hubMeal.price_amount,
      quantity: this.props.hubMeal.meals.quantity,
      mealTags: [],
      showPlaceOrder: true,
      OrderFailure: false,
      showLoading: false,
      selectedDay: this.props.selectedDay,
    };
  }

  componentDidMount() {
    this.mealTag();
    let cachedHubMealId = localStorage.getItem("mealId")
      ? localStorage.getItem("mealId")
      : null;
    let cachedSelectedDay = localStorage.getItem("selectedDay")
      ? localStorage.getItem("selectedDay")
      : null;
    let cachedPrice = localStorage.getItem("newPrice")
      ? localStorage.getItem("newPrice")
      : null;
    let cachedMealSelect = localStorage.getItem("mealSelect")
      ? localStorage.getItem("mealSelect")
      : null;
    if (
      cachedHubMealId === this.props.hubMeal.id &&
      this.props.selectedDay === cachedSelectedDay
    ) {
      this.setState({
        modal: true,
        mealSelect: cachedMealSelect,
        newPrice: cachedPrice,
      });
      localStorage.setItem("selectedMealInfo", null);
    }
  }

  soldOutClass() {
    if (this.state.meal.sold_out === true) {
      return "sold-out";
    } else {
      return "not-sold-out";
    }
  }

  errorClass() {
    return this.state.mealSelect.length === 0 ? "has-error-quantitiy" : "";
  }

  mealTag = () => {
    var tags = this.props.hubMeal.tags
      .reduce((a, c) => (a += c.name + ";"), "")
      .split(";");
    this.setState({ mealTags: tags });
  };

  handleChange = (e) => {
    this.errorClass();
    let price = (
      this.props.hubMeal.price_amount.toFixed(2).replace("$", "") *
      e.target.value
    ).toFixed(2);
    if (price > 0 || e.target.value.length === 0) {
      this.setState({
        [e.target.id]: e.target.value,
        newPrice: price,
      });
    }
  };

  orderMeal = async () => {
    this.setState({
      showLoading: true,
    });
    this.state.showPlaceOrder = false;
    let amount = Number(this.state.mealSelect);
    const createOrder = await mealsService.post_Order(
      this.props.hubMeal.id,
      this.props.hubMeal.meal_id,
      amount,
      this.state.selectedDay,
      this.props.corporateData ? this.props.corporateData.limit : "",
      this.props.corporateData ? this.props.corporateData.card_id : ""
    );

    if (createOrder && createOrder.data.result === "success") {
      this.setState((prevState) => ({
        modal: !prevState.modal,
        newModal: !prevState.newModal,
      }));
    } else {
      this.toggle();
      this.setState({ OrderFailure: true, showLoading: false });
    }
    this.setState({ mealSelect: "1", showLoading: false });
  };

  checkPhoneNumber() {
    const newData = getZlToken(localStorage.getItem("ZlToken"));
    const phoneCheck = {
      phone: !!newData.phone,
      phone_verified: newData.phone_verified,
    };
    return phoneCheck;
  }

  newToggle = async () => {
    const phoneCheck = this.checkPhoneNumber();

    if (await checkCutOffTime(this.props.hubMeal.id)) {
      this.props.updateCutOff(this.props);
      return;
    } else {
      if (
        this.state.mealSelect.length === 0 ||
        Number(this.state.mealSelect) < 1
      ) {
        this.errorClass();
        return;
      }
      if (this.state.cards.length === 0) {
        this.setState((prevState) => ({
          mealSelect: "1",
          newPrice: this.props.hubMeal.price_amount.toFixed(2),
        }));
        this.setMealId();
        const { selectedDay, mealSelect, newPrice } = this.state;
        localStorage.setItem("mealId", this.props.hubMeal.id);
        localStorage.setItem("selectedDay", selectedDay);
        localStorage.setItem("mealSelect", mealSelect);
        localStorage.setItem("newPrice", newPrice);
        if (!this.props.corporateData || this.props.mealPrice > 0) {
          return this.props.history.push({
            pathname: "/add_new_card",
          });
        }
      }

      if (!phoneCheck.phone_verified) {
        this.setState((prevState) => ({
          modal: !prevState.modal,
          paymentForm: !prevState.paymentForm,
          mealSelect: "1",
          newPrice: this.props.hubMeal.price_amount.toFixed(2),
        }));
        this.setMealId();
        const { selectedDay, mealSelect, newPrice } = this.state;
        localStorage.setItem("mealId", this.props.hubMeal.id);
        localStorage.setItem("selectedDay", selectedDay);
        localStorage.setItem("mealSelect", mealSelect);
        localStorage.setItem("newPrice", newPrice);
        return this.props.history.push({
          pathname: phoneCheck.phone ? "/verificationcode" : "/verifyphone",
        });
      }
      this.orderMeal();
      this.setState({
        newPrice: this.props.hubMeal.price_amount.toFixed(2),
      });
      localStorage.setItem("mealId", null);
      localStorage.setItem("selectedDay", null);
      localStorage.setItem("mealSelect", null);
      localStorage.setItem("newPrice", null);
    }
  };

  plusFunction = (e) => {
    let num = Number(this.state.mealSelect);
    num += 1;
    let price = (
      this.props.hubMeal.price_amount.toFixed(2).replace("$", "") * num
    ).toFixed(2);
    this.setState({
      mealSelect: num,
      newPrice: price,
    });
    this.props.handlePriceRender(price);
  };

  minusFunction = (e) => {
    let num = Number(this.state.mealSelect);
    if (num - 1 > 0) {
      num -= 1;
      let price = (
        this.props.hubMeal.price_amount.toFixed(2).replace("$", "") * num
      ).toFixed(2);
      this.setState({
        mealSelect: num,
        newPrice: price,
      });
      this.props.handlePriceRender(price);
    }
  };

  toggle = async () => {
    if (!this.state.modal) {
      if (await checkCutOffTime(this.props.hubMeal.id)) {
        this.props.updateCutOff(this.props);
        return;
      }
    }

    this.setState((prevState) => ({
      modal: !prevState.modal,
      mealSelect: "1",
      newPrice: this.props.hubMeal.price_amount,
    }));

    localStorage.setItem("mealId", null);
    localStorage.setItem("selectedDay", null);
    localStorage.setItem("mealSelect", null);
    localStorage.setItem("newPrice", null);
  };

  is_disabled(is_passed_cut_off_time, sold_out, btn_type) {
    let customClass = "";
    if (btn_type === "plus") customClass = "button-plus";
    if (btn_type === "minus") customClass = "button-minus";
    if ((is_passed_cut_off_time === true && sold_out === 0) || sold_out === 1) {
      customClass = customClass.concat(" disabled-button");
    }
    return customClass;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newModal !== this.props.newModal) {
      this.toggle();
    }
    if (nextProps.cards !== this.props.cards) {
      this.setState({ cards: nextProps.cards });
    }
  }

  onCloseSuccess = () => {
    this.props.orderSuccessHandler();
  };

  onCloseFailure = () => {
    this.setState({ OrderFailure: false }, () => {
      this.props.orderFailureHandler();
    });
  };

  setMealId = () => {
    localStorage.setItem("mealId", JSON.stringify(this.props.hubMeal.id));
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={`${this.props.className} meal_card`}
        >
          <img
            className="close-card"
            src={closeImage}
            alt=""
            onClick={this.toggle}
            style={{ width: "20px" }}
          />
          <CardImg
            id="meal-card-image"
            top
            width="100%"
            src={filestackService.getFileUrl(
              this.props.hubMeal.meals.photo,
              false
            )}
            alt="Card image cap"
            className="is-web-image"
          />
          <ModalBody>
            <div>
              <CardTitle>
                <p id="meal-card-resturant-name">
                  {this.props.hubMeal.restaurants.name}
                </p>
                <p id="meal-card-title">{this.props.hubMeal.meals.name}</p>
                <p id="imprefect">
                  {`${
                    this.props.hubMeal.meal_type
                      ? `${mealTypes[this.props.hubMeal.meal_type]} - `
                      : ""
                  } ${
                    this.props.hubMeal.delivery_time
                      ? `Delivery From ${this.props.hubMeal.delivery_time}`
                      : ""
                  }`}
                </p>
              </CardTitle>
              <div id="dailyMenu" />
              <Row className="meal-card-title2">
                <Col xs="4">
                  <p id="meal-card-price">
                    ${this.props.hubMeal.price_amount.toFixed(2)}
                  </p>
                </Col>
                <Col xs="8">
                  <p></p>
                </Col>
              </Row>
              <div className="meal-card-title3">
                <div className="scroll-tags">
                  {this.state.mealTags.map((kind) => {
                    return kind ? (
                      <span key={kind} className="meal-card-type">
                        &nbsp;&nbsp;{kind}&nbsp;&nbsp;
                      </span>
                    ) : (
                      ""
                    );
                  })}
                </div>
              </div>
              <div className="meal-description">
                <CardText className="meal-card-text">
                  {this.props.hubMeal.meals.description}
                </CardText>
              </div>
            </div>
          </ModalBody>
          <div>
            <ButtonGroup className="plusandminus">
              <Input
                type="button"
                value="-"
                className={this.is_disabled(
                  this.props.hubMeal.sold_out,
                  this.props.is_passed_cut_off_time,
                  "minus"
                )}
                data-field="quantity"
                onClick={this.minusFunction}
              ></Input>
              <Input
                className={this.errorClass()}
                type="number"
                name="select"
                min=""
                max={this.state.quantity}
                id="mealSelect"
                value={this.state.mealSelect}
                onChange={this.handleChange}
                disabled
              ></Input>
              <Input
                type="button"
                value="+"
                className={this.is_disabled(
                  this.props.hubMeal.sold_out,
                  this.props.is_passed_cut_off_time,
                  "plus"
                )}
                data-field="quantity"
                onClick={this.plusFunction}
              ></Input>
            </ButtonGroup>
            {this.props.hubMeal.sold_out === false &&
              this.props.is_passed_cut_off_time === 0 && (
                <Button
                  className="my-3"
                  id="select-button"
                  color="danger"
                  onClick={this.newToggle}
                  style={{
                    display: this.state.showLoading ? "none" : "block",
                  }}
                >
                  {this.props.corporateData ? (
                    <>Place Order ${this.props.mealPrice}</>
                  ) : (
                    <>Place Order ${this.state.newPrice}</>
                  )}
                </Button>
              )}
            {this.props.hubMeal.sold_out === true && (
              <Button
                id="select-button"
                color="disabled"
                style={{ cursor: "not-allowed" }}
              >
                SOLD OUT
              </Button>
            )}
            {this.props.is_passed_cut_off_time === 1 &&
              this.props.hubMeal.sold_out !== true && (
                <Button
                  id="select-button"
                  color="disabled"
                  style={{ cursor: "not-allowed" }}
                >
                  ORDERING ENDED
                </Button>
              )}
            <div
              id="loadingDiv"
              style={{
                textAlign: "center",
                padding: "15px",
                display: this.state.showLoading ? "block" : "none",
              }}
            >
              <img
                src={image}
                style={{ height: "40px", width: "40px" }}
                alt=""
              ></img>
            </div>
          </div>
        </Modal>
        <OrderSuccess
          newModal={this.state.newModal}
          deliveryTime={this.props.hubMeal.delivery_time}
          floor={this.props.floor}
          pickupInstructions={this.props.pickupInstruction}
          onCloseModal={this.onCloseSuccess}
        />
        {this.state.OrderFailure === true && (
          <OrderFailure
            newModal={this.state.OrderFailure}
            onClose={this.onCloseFailure}
          />
        )}
        {
          <AddCardOrder
            amount={Number(this.state.mealSelect)}
            hubMealId={this.props.hubMeal.id}
            paymentForm={this.state.paymentForm}
            orderMeal={this.orderMeal}
            newModal={this.state.newModal}
            pickupInstructions={this.props.pickupInstruction}
            deliveryTime={this.props.hubMeal.restaurants.delivery_time}
            getCards={this.props.getCards}
          />
        }
      </div>
    );
  }
}

export default MealDescription;
