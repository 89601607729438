function activate() {
  document.getElementById("countdown").hidden = false;
  document.getElementById("countdown-label").hidden = false;
  var countdownNumberEl = document.getElementById("countdown-number");
  var countdown = 60;
  countdownNumberEl.textContent = 60;
  setInterval(function() {
    countdown = --countdown;
    if (countdown < 1) {
      return;
    }
    countdownNumberEl.textContent = countdown;
  }, 1000);
}

export default {
  activate
};
