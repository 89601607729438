import React from "react";

class ErrorMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      let element = document.getElementById("ErrorMobile");
      element.classList.add("hidden");
    }, 5000);
  }

  render() {
    return (
      <div
        className="Toastify__toast-container Toastify__toast-container--top-right hidden"
        id="ErrorMobile"
      >
        <div className="Toastify__toast Toastify__toast--error">
          <div role="alert" className="Toastify__toast-body toast-message">
            Invalid credit card
          </div>
          {/* <button
            className="Toastify__close-button Toastify__close-button--error"
            type="button"
            aria-label="close"
            onClick={e=>{ 
              let element = document.getElementById("ErrorMobile");
              element.classList.add("hidden");}}
          >
            ✖
          </button> */}
          <div className="Toastify__progress-bar Toastify__progress-bar--animated Toastify__progress-bar--error"></div>
        </div>
      </div>
    );
  }
}

export default ErrorMobile;
