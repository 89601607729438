import httpService from "./httpService";

const url = process.env.REACT_APP_APIURL;

async function set_notifications(item) {
  const data = await httpService
    .post(`${url}/users/set-notification-settings`, item, {
      withCredentials: true
    })
    .then(data => data)
    .catch(error => error.response);
  return data;
}

async function get_notifications(item) {
    const data = await httpService
      .get(`${url}/users/get-notification-settings`)
      .then(data => data)
      .catch(error => error.response);
    return data.data.result;
  }

export default {
  set_notifications,
  get_notifications
};
