/* eslint-disable */
import React from "react";
import { isMobile } from "react-device-detect";
import mealsService from "../../services/mealsService";
import MealHeader from "../meals/mealHeader";
import { Row, Col } from "reactstrap";
import filestackService from "../../services/file-stackService";
import MobileHeader from "../shared/mobile_header";
import userService from "../../services/userService";
import { getZlToken } from "../shared/common";

import Moment from "react-moment";

const url = process.env.REACT_APP_APIURL;
class MyOrder extends React.Component {
  constructor(props) {
    let userData = getZlToken(localStorage.getItem("ZlToken"));
    super(props);
    this.state = {
      startDate: new Date(),
      id: "",
      userId: userData.id,
      date: localStorage.getItem("today"),
      ordersOpen: [],
      newModal: false,
      Active: [],
      History: [],
      hideEmpty: false,
    };
  }

  getOrders = async () => {
    if (localStorage.getItem("user") !== null) {
      this.setState(
        {
          ordersOpen: await mealsService.get_Weekly_Orders_V2(
            this.state.date,
            false
          ),
        },
        () => {
          this.setState(
            {
              Active: this.state.ordersOpen.result.map((key, value) => {
                return this.state.ordersOpen.result[value].status_id == 0 ||
                  this.state.ordersOpen.result[value].status_id == 1
                  ? this.state.ordersOpen.result[value]
                  : null;
              }),
              History: this.state.ordersOpen.result.map((key, value) => {
                return this.state.ordersOpen.result[value].status_id == 4 ||
                  this.state.ordersOpen.result[value].status_id == 3 ||
                  this.state.ordersOpen.result[value].status_id == 2
                  ? this.state.ordersOpen.result[value]
                  : null;
              }),
            },
            () => {
              this.setState(
                {
                  Active: this.state.Active.filter(function (el) {
                    return el != null;
                  }).sort(function (a, b) {
                    if (a && b) {
                      return a.created < b.created
                        ? -1
                        : a.created > b.created
                        ? 1
                        : 0;
                    }
                  }),
                  History: this.state.History.filter(function (el) {
                    return el != null;
                  })
                    .sort(function (a, b) {
                      if (a && b) {
                        return a.created < b.created
                          ? -1
                          : a.created > b.created
                          ? 1
                          : 0;
                      } else return null;
                    })
                    .reverse(),
                },
                () => {
                  this.setState({
                    hideEmpty: true,
                  });
                }
              );
            }
          );
        }
      );
    } else {
      window.location.replace(`#/login`);
      const loggedIn = await userService.login();
    }
  };

  redirectToInfo = (key) => {
    this.props.history.push({
      pathname: "/orderInfo",
      state: { data: key },
    });
  };

  componentDidMount = async () => {
    this.getOrders();
  };
  redirectToDaily = () => {
    this.props.history.push("/dailymenu");
  };

  render() {
    return (
      <div>
        {isMobile ? (
          <MobileHeader title="My Orders" link="/profilepage" />
        ) : (
          <MealHeader title={"My Orders"} className="BrowserHeader" />
        )}

        <div className="HoldOrders">
          <div className="activeHeader">
            {this.state.Active.length > 0 ? <h1>Active</h1> : null}
          </div>
          <div className="active">
            {this.state.Active.length > 0 ? (
              this.state.Active.map((order, val) => {
                return order !== null && order.meals ? (
                  <div
                    key={`${order.id}-${val}`}
                    className="orderCard"
                    onClick={() => this.redirectToInfo(order)}
                  >
                    <div className="orderImg">
                      <img
                        src={filestackService.getFileUrl(order.meals.photo, true)}
                      />
                    </div>
                    <div className="orderInfo">
                      <p>
                        {order.meals.name} X {order.amount}
                      </p>
                      <Moment format="ddd, D MMM YYYY" utc="0">
                        {order.created}
                      </Moment>
                      <p>#{order.id}</p>
                      <div className="orderButton">
                        <button className="activeButton">
                          {order.status_id == 0 ? "Open " : "Being Made"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                );
              })
            ) : (
              <div
                className="empty"
                style={{
                  display: this.state.hideEmpty ? "flex" : "none",
                }}
              >
                <h1> You Dont Have Any Active Order Yet</h1>
                <a onClick={this.redirectToDaily}>Order Now</a>
              </div>
            )}
          </div>
        </div>

        <div className="activeHeader">
          {this.state.History.length > 0 ? <h1>History</h1> : null}
        </div>
        <div className="historyOrder">
          {this.state.History.length > 0
            ? this.state.History.map((order, val) => {
                return (order !== null && order.meals ? (
                  <div
                    key={`${val}`}
                    className={`historyBox ${val}`}
                    onClick={() => this.redirectToInfo(order)}
                  >
                    <div className="storyInfo">
                      <div
                        className={`orderButtonHistory ${
                          order.status_id === 2
                            ? "orderDelivered"
                            : "orderCancelled"
                        }`}
                      >
                        <button>
                          {order.status_id === 2 ? "Delivered" : "Cancelled"}
                        </button>
                      </div>
                      <Moment format="ddd, D MMM YYYY" utc="0">
                        {order.created}
                      </Moment>
                      <p className="order-number">#{order.id}</p>
                      <p>{order.meals.name}</p>
                    </div>
                  </div>
                ) : (
                  ""
                ));
              })
            : ""}
        </div>
      </div>
    );
  }
}

export default MyOrder;