import React from "react";
import {
  Modal,
  ModalBody,
  Button,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import cardValidationService from "../../services/cardValidationService";
import creditCardService from "../../services/creditCardService";
import OrderSuccess from "../meals/order_success";
import mealsService from "../../services/mealsService";

class AddCardOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      floor: this.props.floor,
      pickupInstructions: this.props.pickupInstructions,
      newModal: false,
      deliveryTime: this.props.deliveryTime,
      modal: false,
      cardHolder: "",
      cardNumber: "",
      cardExpMMYY: "",
      cardCCV: "",
      cardHolderError: "",
      cardNumberError: "",
      cardExpMMYYError: "",
      cardCCVError: "",
      cardHolderValid: false,
      cardNumberValid: false,
      cardExpMMYYValid: false,
      cardCCVValid: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  checkCardHolderValid = () => {
    let cardHolderChecked = cardValidationService.validateName(
      this.state.cardHolder
    );
    this.setState({
      cardHolderError: cardHolderChecked.erroeMessage,
      cardHolderValid: cardHolderChecked.cardHolderValid,
    });
  };

  checkCardNumberValid = () => {
    let cardNumberChecked = cardValidationService.validateNumber(
      this.state.cardNumber
    );
    this.setState({
      cardNumberError: cardNumberChecked.erroeMessage,
      cardNumberValid: cardNumberChecked.cardNumberValid,
    });
  };

  checkCardExpMMYYValid = () => {
    let cardExpMMYYChecked = cardValidationService.validateMMYY(
      this.state.cardExpMMYY
    );
    this.setState({
      cardExpMMYYError: cardExpMMYYChecked.erroeMessage,
      cardExpMMYYValid: cardExpMMYYChecked.cardExpMMYYValid,
    });
  };

  checkCardCCVValid = () => {
    let cardCCVChecked = cardValidationService.validateCCV(this.state.cardCCV);
    this.setState({
      cardCCVError: cardCCVChecked.erroeMessage,
      cardCCVValid: cardCCVChecked.cardCCVValid,
    });
  };

  checkAllFieldsValidations = () => {
    this.checkCardHolderValid();
    this.checkCardNumberValid();
    this.checkCardExpMMYYValid();
    this.checkCardCCVValid();
  };

  handleCardNumberChange = (e) => {
    if (e.keyCode === 8) {
    }
    if (e.target.value.length > 16) return;
    else {
      this.setState({
        [e.target.id]: e.target.value,
      });
      e.preventDefault();
    }
  };

  handleCardCCVChange = (e) => {
    if (e.keyCode === 8) {
    }
    if (e.target.value.length > 4) return;
    else {
      this.setState({
        [e.target.id]: e.target.value,
      });
      e.preventDefault();
    }
  };

  handleMonthYearChange = (e) => {
    if (onkeydown === 8) {
    }
    if (e.target.value.length > 4) return;
    else {
      this.setState({
        [e.target.id]: e.target.value,
      });
      e.preventDefault();
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.preventDefault();
  };

  newToggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      newModal: !prevState.newModal,
      mealSelect: "1",
    }));
  };

  orderMeal = async () => {
    const createOrder = await mealsService.post_Order(
      this.props.mealId,
      this.props.amount
    );
    console.log("createOrder",createOrder);
    if (createOrder && createOrder.data.result === "success") {
      this.newToggle();
      this.props.getCards();
    }
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !(
        this.state.cardHolder &&
        this.state.cardNumber &&
        this.state.cardExpMMYY &&
        this.state.cardCCV
      )
    ) {
      this.checkAllFieldsValidations();
      return;
    }

    const month = this.state.cardExpMMYY[0] + this.state.cardExpMMYY[1];
    const year = this.state.cardExpMMYY[2] + this.state.cardExpMMYY[3];
    await creditCardService
      .add_card(
        this.state.cardHolder,
        this.state.cardNumber,
        month,
        year,
        this.state.cardCCV
      )
      .then((data) => {
        this.orderMeal();
        return data;
      })
      .catch((error) => error);
  };

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.paymentForm !== this.props.paymentForm) {
      this.setState({ paymentForm: nextProps.paymentForm });
      this.toggle();
    }
  }

  render() {
    return (
      <div>
        <OrderSuccess
          newModal={this.state.newModal}
          floor={this.state.floor}
          deliveryTime={this.state.deliveryTime}
          pickupInstructions={this.state.pickupInstructions}
        />
        <Modal
          centered
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={`${this.props.className} reset-password`}
        >
          <div>
            <ModalBody>
              <p className="popup-body">Add a credit card</p>
              <form onSubmit={this.handleSubmit}>
                <FormGroup className="sign-up-form">
                  <Input
                    className={`form-group form-input ${this.errorClass(
                      this.state.cardHolderError
                    )}`}
                    type="text"
                    name="cardHolder"
                    id="cardHolder"
                    placeholder="Name on Card"
                    noValidate
                    onChange={this.handleChange}
                    value={this.state.value}
                    onBlur={this.checkCardHolderValid}
                  />
                </FormGroup>
                <FormGroup className="sign-up-form">
                  <Input
                    className={`form-group form-input ${this.errorClass(
                      this.state.cardNumberError
                    )}`}
                    type="number"
                    name="cardNumber"
                    id="cardNumber"
                    placeholder="Card Number"
                    noValidate
                    onChange={this.handleCardNumberChange}
                    value={this.state.cardNumber}
                    onBlur={this.checkCardNumberValid}
                  />
                </FormGroup>
                <Row form>
                  <Col xs={6}>
                    <FormGroup>
                      <Input
                        className={`form-group form-input ${this.errorClass(
                          this.state.cardExpMMYYError
                        )}`}
                        type="number"
                        name="cardExpMMYY"
                        id="cardExpMMYY"
                        placeholder="MMYY"
                        noValidate
                        onChange={this.handleMonthYearChange}
                        value={this.state.cardExpMMYY}
                        onBlur={this.checkCardExpMMYYValid}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Input
                        className={`form-group form-input ${this.errorClass(
                          this.state.cardCCVError
                        )}`}
                        pattern="[0-9]*"
                        inputMode="numeric"
                        type="password"
                        name="cardCCV"
                        id="cardCCV"
                        placeholder="CVV"
                        noValidate
                        onChange={this.handleCardCCVChange}
                        value={this.state.cardCCV}
                        onBlur={this.checkCardCCVValid}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className="mb-0">
                  <Button color="danger" id="btn" type="submit">
                    Add Card & Order
                  </Button>
                </FormGroup>
                <FormGroup className="cancel-add-card">
                  <span onClick={this.toggle}>Cancel Order</span>
                </FormGroup>
              </form>
            </ModalBody>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AddCardOrder;
