/* eslint-disable */
function validatEmail(email) {
  const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  let erroeMessage = emailValid
    ? ""
    : email.length === 0
    ? "Please enter your Email Address"
    : "Please enter a valid email address";
  return { erroeMessage: erroeMessage, emailValid: emailValid };
}

function validatPassword(password) {
  const passwordValid = password.length >= 8;
  let erroeMessage = passwordValid ? "" : "Must have at least 8 characters";
  return { erroeMessage: erroeMessage, passwordValid: passwordValid };
}

function validatConfirmPassword(confirmPassword) {
  const confirmPasswordValid = confirmPassword.length >= 8;
  let erroeMessage = confirmPasswordValid
    ? ""
    : "Must have at least 8 characters";
  return {
    erroeMessage: erroeMessage,
    confirmPasswordValid: confirmPasswordValid
  };
}

function validatEditName(name) {
  name = name.split(" ");
  const nameValid = name.length === 2;
  let erroeMessage = nameValid
    ? ""
    : "Please enter your First Name and Last Name.";

  return { erroeMessage: erroeMessage, nameValid: nameValid };
}

function validatName(name) {
  const nameValid = name.trim().length >= 1;
  let erroeMessage = nameValid ? "" : "Please enter ";
  return { erroeMessage: erroeMessage, nameValid: nameValid };
}

function validatLocation(name) {
  const nameValid = name.length >= 3;
  let erroeMessage = nameValid ? "" : "Please enter your Location";
  return { erroeMessage: erroeMessage, nameValid: nameValid };
}

function validateCompany(name) {
  const nameValid = name.length >= 1;
  let erroeMessage = nameValid ? "" : "Please enter your ";
  return { erroeMessage: erroeMessage, nameValid: nameValid };
}

function checkPassword(password, confirmPassword) {
  if (password.length >= 8 && confirmPassword.length >= 8) {
    if (!(password === confirmPassword)) {
      return true;
    } else {
      return false;
    }
  }
}

function checkPhone(phone) {
  let phoneToTest = phone.replace("(", ""); //remove formatting
  phoneToTest = phoneToTest.replace(")", ""); //remove formatting
  phoneToTest = phoneToTest.replace(/\s/g, ""); //remove formatting
  const phoneValid = phoneToTest.length >= 9;
  let erroeMessage = phoneValid ? "" : "Please enter a valid phone number";
  return { erroeMessage: erroeMessage, phoneValid: phoneValid };
}

function checkPhoneNumber(value) {
  value = value.replace(/[\s\)\(]/g, "");
  if (!isNaN(Number(value))) {
    return true;
  } else {
    return false;
  }
}

function replacePhone(value) {
  value = value.replace(/\D/g, "");
  return value;
}

function formatPhone(value) {
  const numbers = value.replace(/\D/g, ""),
    char = { 0: "(", 3: ") ", 6: " " };
  value = "";
  for (let i = 0; i < numbers.length; i++) {
    value += (char[i] || "") + numbers[i];
  }
  return value;
}

function checkCard(cardNumber) {
  let cardNumberValid = cardNumber.length >= 19;
  let erroeMessage = cardNumberValid
    ? ""
    : cardNumber.length === 0
    ? "Please enter your card number"
    : "Please enter a valid card number";
  return { erroeMessage: erroeMessage, cardNumberValid: cardNumberValid };
}

function checkCardNumber(value) {
  value = value.replace(/[\s]/g, "");
  if (!isNaN(Number(value))) {
    return true;
  } else {
    return false;
  }
}

function replaceCard(value) {
  value = value.replace(/\D/g, "");
  return value;
}

function formatCard(value) {
  const numbers = value.replace(/\D/g, ""),
    char = { 4: " ", 8: " ", 12: " " };
  value = "";
  for (let i = 0; i < numbers.length; i++) {
    value += (char[i] || "") + numbers[i];
  }
  return value;
}

export default {
  validateCompany,
  validatEmail,
  validatPassword,
  validatConfirmPassword,
  validatEditName,
  validatName,
  checkPassword,
  checkPhone,
  checkPhoneNumber,
  replacePhone,
  formatPhone,
  checkCard,
  checkCardNumber,
  formatCard,
  validatLocation,
  replaceCard
};
