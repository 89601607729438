import httpService from "./httpService";
const url = process.env.REACT_APP_APIURL;


async function getCorporateData(id) {
    try {
        const data = await httpService.get(`${url}/corporates/get-corporate-data?id=${id}`);
        return data.data;
    } catch (error) {
        throw error;
    }
}

export default {
    getCorporateData
}