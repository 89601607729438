import httpService from "./httpService";

const url = process.env.REACT_APP_APIURL;


async function log (text){
    
    const data = await httpService
    .post(
      `${url}/log`,{
        message: text 
      }
    )
  return data;
  
  }


  export default {
    log
  };