import React, { Component } from "react";
import Slide from "./Slide";
import Dots from "./Dots";
const s = {
  container: "fullW fullH rel overflowH",
  onScreen: "left0",
  offScreenRight: "left100vw",
  offScreenLeft: "leftM100vw",
  transition: "transition1l",
};
class Slideshow extends Component {
  state = {
    slide1: {
      id: 0,
      position: s.onScreen,
      transition: true,
    },
    slide2: {
      id: 1,
      position: s.offScreenRight,
      transition: true,
    },
    currentId: 0,
  };

  componentDidMount() {
    this.startCarousel();
  }

  componentWillUnmount() {
    clearInterval(this.carouselInterval);
  }

  startCarousel = () => {
    this.carouselInterval = setInterval(() => {
      this.transitionSlides();
    }, 8000);
  };

  setSlideState = (slide1, slide2, currentId) => {
    this.setState({
      slide1: slide1,
      slide2: slide2,
    });
    setTimeout(() => {
      this.setState({
        currentId: currentId,
      });
    }, 400);
  };

  transitionSlides = () => {
    const { slide1, slide2 } = this.state;
    let currentId;
    if (slide1["position"] === s.onScreen) {
      slide1["position"] = s.offScreenLeft;
      slide2["position"] = s.onScreen;
      currentId = slide2.id;
    } else {
      slide1["position"] = s.onScreen;
      slide2["position"] = s.offScreenLeft;
      currentId = slide1.id;
    }
    this.setSlideState(slide1, slide2, currentId);
    setTimeout(() => {
      this.resetSlideOffScreen();
    }, 1000);
  };

  resetSlideOffScreen = () => {
    const { slide1, slide2, currentId } = this.state;
    const { slides } = this.props;
    if (slide1["position"] === s.offScreenLeft) {
      slide1["transition"] = false;
      slide1["position"] = s.offScreenRight;
      slide1["id"] = slide2.id + 1 === slides.length ? 0 : slide2.id + 1;
    } else {
      slide2["transition"] = false;
      slide2["position"] = s.offScreenRight;
      slide2["id"] = slide1.id + 1 === slides.length ? 0 : slide1.id + 1;
    }
    this.setSlideState(slide1, slide2, currentId);
    this.resetSlideTransitions(slide1, slide2, currentId);
  };

  resetSlideTransitions = (slide1, slide2, currentId) => {
    setTimeout(() => {
      slide1["transition"] = true;
      slide2["transition"] = true;
      this.setSlideState(slide1, slide2, currentId);
    }, 500);
  };

  render() {
    const { slide1, slide2, currentId } = this.state;
    const { slides } = this.props;
    const { items } = this.props;
    return (
      <div className={s.container}>
        <Slide
          image={slides[slide1.id]}
          position={slide1.position}
          transition={slide1.transition ? s.transition : ""}
          item={items[currentId]}
        />
        <Slide
          image={slides[slide2.id]}
          position={slide2.position}
          transition={slide2.transition ? s.transition : ""}
          item={items[currentId]}
        />
        <Dots slideId={currentId} slides={slides} />
      </div>
    );
  }
}

export default Slideshow;
