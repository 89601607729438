import React from "react";
import ResetPasswordPopup from "./reset_password_popup.jsx";
import { Link } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import {getZlToken} from "../shared/common";
class waitingPage extends React.Component {
  constructor(props) {
    super(props);
    const userData = getZlToken(localStorage.getItem("ZlToken"));
    this.state = {
      email: userData.email,
      modal: false
    };
    
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    if (isMobile) {
      return (
        <div id="wait-mob-form">
          <Link to="/">
            <i className="fa fa-arrow-left" id="backArrow" />
          </Link>
          <h3 id="waiting-page-title">Please check your email</h3>
          <p id="waiting-page">
            An email with instructions to setup a new password has been sent to{" "}
            {}
            {this.state.email}. 
          </p>
          <ResetPasswordPopup />
        </div>
      );
    }
    if (isBrowser) {
      return (
        <div id="password-mob-form">
          <h3 id="waiting-page-title">Please check your email</h3>
          <p id="waiting-page">
            An email with instructions to setup a new password has been sent to{" "}
            {}
            {this.state.email} .
          </p>
          <p className="text-center">
            <Link to="/" id="HomePage">
              Go to Home page
            </Link>
          </p>
          <ResetPasswordPopup />
        </div>
      );
    }
  }
}

export default waitingPage;