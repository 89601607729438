import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import image from "../../assets/images/green_check.png";
import { Link } from "react-router-dom";

class OrderSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryTime: this.props.deliveryTime,
      modal: false,
      newPrice: "$",
      pickupInstructions: this.props.pickupInstructions
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    if (this.state.modal) {
      this.props.onCloseModal();
    }
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newModal !== this.props.newModal) {
      this.toggle();
    }
  }

  handleClick = () => {
    this.toggle();
  };

  render() {
    return (
      <div>
        <Modal
          centered
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={`${this.props.className} reset-password`}
        >
          <div>
            <ModalBody id="reset-password-image-body">
              <img id="reset-password-image" src={image} alt="" />
            </ModalBody>
            <ModalBody>
              <p className="popup-body">Your order is successful</p>
              <p className="verify-phone-text">
                Your food will arrive between {this.state.deliveryTime}, pick up
                instructions ({this.state.pickupInstructions})
              </p>
              <Button color="danger" id="btn" onClick={this.handleClick}>
                Order More
              </Button>
              <Link to="/refferalcode">
                <label id="save-order">Save 50% on your next order!</label>
              </Link>
            </ModalBody>
          </div>
        </Modal>
      </div>
    );
  }
}

export default OrderSuccess;
