import React from "react";
import { Link } from "react-router-dom";
import { Button, FormGroup, Input } from "reactstrap";
import userService from "../../services/userService";
import validationService from "../../services/validationService";
import { isBrowser, isMobile } from "react-device-detect";
import { setZlToken, getZlToken } from "../shared/common"
class VerifyPhone extends React.Component {
  constructor(props) {
    super(props);
    let userData = getZlToken(localStorage.getItem("ZlToken"));
    this.state = {
      id: userData.id,
      countryCode: "+1",
      phoneNumber: "",
      phoneError: "",
      phoneExist: false,
      phoneValid: false,
      apiValid: true,
    };
  }

  handleClear() {
    this.setState({ phoneNumber: "" });
  }

  checkPhoneValid = () => {
    let phoneChecked = validationService.checkPhone(this.state.phoneNumber);
    this.setState({
      phoneError: phoneChecked.erroeMessage,
      phoneValid: phoneChecked.phoneValid,
    });
  };

  handleChangeCountryCode = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleChange = (e) => {
    if (!validationService.checkPhoneNumber(e.target.value)) {
      return;
    }

    e.target.value = validationService.formatPhone(e.target.value);

    this.setState({
      [e.target.id]: e.target.value,
    });

    e.preventDefault(e);
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }


  handleSubmit = async (e) => {
    e.preventDefault();

    if (!this.state.phoneValid) {
      this.checkPhoneValid();
      return;
    }

    let allPhone = this.state.phoneNumber;
    let value = validationService.replacePhone(allPhone);
    value = value = this.state.countryCode + value;
    let phone = value;
    const phoneUpdated = await userService
      .update_phone(this.state.id, phone)
      .then((data) => data)
      .catch((error) => error);

    if (phoneUpdated.result === "authentication required") {
      this.props.history.push("/login");
      localStorage.clear();
    } else if (
      phoneUpdated.result &&
      phoneUpdated.result.phone_added === true
    ) {
      this.setState({ phoneNumber: phone });
      let newData = getZlToken(localStorage.getItem("ZlToken"));
      newData.phone = phone;
      await setZlToken(newData);
      this.props.history.push("/verificationcode");
    } else {
      this.setState({ apiValid: false, phoneExist: true });
    }
  };

  render() {
    if (isMobile) {
      return (
        <div>
          <Link to="profilepage">
            <i
              className="fa fa-arrow-left xs-block d-md-none"
              id="backArrow"
            ></i>
          </Link>
          <div id="phone-mobile-form">
            <h3 id="welcome-ver"> Phone Verification Method</h3>
            <p className="verify-phone-text">
              We will send you a reminder of the daily menu options through SMS
            </p>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <FormGroup className='h-100' row>
                {/* <Input
                sm={2}
                type="text"
                name="country-code"
                id="country-code"
                maxLength="2"
                placeholder="+1"
                value="+1"
                onChange={this.handleChange.bind(this)}
                disabled
              /> */}
                <Input
                  sm={2}
                  type="select"
                  name="countryCode"
                  id="countryCode"
                  onChange={this.handleChangeCountryCode}
                >
                  <option>+1</option>
                  <option>+962</option>
                  <option>+971</option>
                </Input>
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.phoneError
                  )}`}
                  sm={10}
                  type="phone"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="(647) 123 4567"
                  maxLength="14"
                  noValidate
                  value={this.state.phoneNumber}
                  onChange={this.handleChange}
                  onBlur={this.checkPhoneValid}
                />
                <i
                  className="fas fa-times-circle clear-phone"
                  onClick={() => this.handleClear()}
                ></i>
                {this.state.phoneError.length > 0 && (
                  <label className="errorMessagePhone">
                    {this.state.phoneError}
                  </label>
                )}
                {this.state.phoneExist && (
                  <label className="phoneExist">
                    Phone number above already exists with another account.
                    <Link className="log-in-insted" to="/login">
                      login instead
                    </Link>
                    ,
                    <Link className="log-in-insted" to="/forgotpassword">
                      {" "}
                      Reset your password{" "}
                    </Link>
                    or use another number to register a new account
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Button color="danger" id="btn">
                  Next
                </Button>
              </FormGroup>
            </form>
          </div>
        </div>
      );
    }
    if (isBrowser) {
      return (
        <div>
          <Link to="dailymenu">
            <i
              className="fa fa-arrow-left xs-none d-md-block"
              id="backArrow"
            ></i>
          </Link>
          <div id="phone-mobile-form">
            <h3 id="welcome-ver"> Phone Verification Method</h3>
            <p className="verify-phone-text">
              We will send you a reminder of the daily menu options through SMS
            </p>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <FormGroup className='h-100' row>
                {/* <Input
                          sm={2}
                          type="text"
                          name="country-code"
                          id="country-code"
                          maxLength="2"
                          placeholder="+1"
                          value="+1"
                          onChange={this.handleChange.bind(this)}
                          disabled
                        /> */}
                <Input
                  sm={2}
                  type="select"
                  name="countryCode"
                  id="countryCode"
                  onChange={this.handleChangeCountryCode}
                >
                  <option>+1</option>
                  <option>+962</option>
                  <option>+971</option>
                </Input>
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.phoneError
                  )}`}
                  sm={10}
                  type="phone"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="(647) 123 4567"
                  maxLength="14"
                  noValidate
                  value={this.state.phoneNumber}
                  onChange={this.handleChange}
                  onBlur={this.checkPhoneValid}
                />
                <i
                  className="fas fa-times-circle clear-phone"
                  onClick={() => this.handleClear()}
                ></i>
                {this.state.phoneError.length > 0 && (
                  <label className="errorMessagePhone">
                    {this.state.phoneError}
                  </label>
                )}
                {this.state.phoneExist && (
                  <label className="phoneExist">
                    Phone number above already exists with another account.
                    <Link className="log-in-insted" to="/">
                      login instead
                    </Link>
                    ,
                    <Link className="log-in-insted" to="/forgotpassword">
                      {" "}
                      Reset your password{" "}
                    </Link>
                    or use another number to register a new account
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Button color="danger" id="btn">
                  Next
                </Button>
              </FormGroup>
            </form>
          </div>
        </div>
      );
    }
  }
}

export default VerifyPhone;
