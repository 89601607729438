import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { Button, Card, Col, Input, Row } from "reactstrap";
import companyService from "../../services/companyService";
import toastService from "../../services/toast-service";
import MealHeader from "../meals/mealHeader";
import { getZlToken } from "../shared/common";
import MobileHeader from "../shared/mobile_header";

const UserAddresses = () => {

  const [addresses, setAddresses] = useState([]);
  const [isEditing, setIsEditing] = useState(NaN);
  const [newName, setNewName] = useState("");
  const [user, setUser] = useState({});

  useEffect(() => {
    getUserAddresses();
  }, [])

  const getUserAddresses = async () => {
    const userDecoded = getZlToken(localStorage.getItem("ZlToken"));
    const userAddresses = await companyService.getUserAddresses(userDecoded.id);
    setUser(userDecoded);
    setAddresses(userAddresses);
  };

  const deleteAddress = async (e, id, i, addressId) => {
    e.preventDefault();

    if (addresses.length === 1) {
      return toastService.error("You have to have at least one address. To change your address, add a new one before deleting the old address.");
    };

    if (addressId === user.company_id) {
      const userData = { ...user };
      const newDefault = addresses[i + 1] ? addresses[i + 1] : addresses[i - 1]
      userData.company_id = newDefault.address_id
      setUser(userData)
      companyService.changeUserAddress(userData, newDefault.address_id);
    };

    await companyService.deleteUserAddress(id);
    const userAddresses = [...addresses];
    userAddresses.splice(i, 1);
    setAddresses(userAddresses);
  };

  const handleEdit = (index, name) => {
    setNewName(name)
    setIsEditing(index);
  };

  const handleSave = async (id, name) => {
    setIsEditing(NaN);
    if (!newName || newName === name) return
    await companyService.updateUserAddress(id, newName)
    setNewName("");
    await getUserAddresses();
  };

  return (
    <div>
      {isMobile ? <MobileHeader link={'/profilepage'} title="Delivery Addresses" /> : <MealHeader title="Delivery Addresses" />}
      <div className="gray text-center text-dark">
        <p className="pt-4">All Addresses</p>
        {addresses.map(({ name, address, id }, i) => (
          <Card
            key={id}
            className="credit-card my-4 addressCard pt-3"
          >
            <Row className="position-absolute ml-5 pl-4">
              {user.company_id === address.id && <p className="text-danger pb-5 ml-3" >Current Address</p>}
            </Row>

            <Row xs="7" className=" mx-4 pl-1 py-4 h-25">
              {i === isEditing ?
                <Input value={newName} onChange={(e) => { setNewName(e.target.value) }} /> :
                <p className="mt-2">{name}</p>
              }
            </Row>

            <Row xs="7" className="m-auto col-11 pt-4 h-50">
              <p>{address.name} - {address.location}</p>
            </Row>

            <Row xs="2" className="d-flex align-items-center m-auto col-11 pb-3 h-25">
              <Col>
                {i === isEditing ?
                  <Button color='danger' className="rounded border-0 text-white" onClick={() => handleSave(id, name)} >Save</Button> :
                  <Button className="rounded bg-light border-0 text-dark" onClick={() => handleEdit(i, name)} >Edit</Button>
                }
              </Col>

              <Col>
                <i
                  className="fas fa-trash-alt pb-2"
                  onClick={(e) => { deleteAddress(e, id, i, address.id) }}
                ></i>
              </Col>
            </Row>
          </Card>
        ))}
        <div className="mt-2">
          <Link className="text-danger pb-5" to={'/selectcompany'}>Add New Address</Link>
        </div>
      </div>
    </div>
  );
}

export default UserAddresses