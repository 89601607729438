import React from "react";
import { Card, CardImg, CardTitle, Col, Row } from "reactstrap";
import filestackService from "../../services/file-stackService";
import MealDescription from "./meal_description";
import { isMobile } from "react-device-detect";
import { getZlToken } from "../shared/common";
import employeeService from "../../services/employeeService";
import CorporateService from "../../services/corporateService";

class MealModals extends React.Component {
  constructor(props) {
    let userData = getZlToken(localStorage.getItem("ZlToken"));
    super(props);
    this.state = {
      cards: this.props.cards,
      userId: userData.id,
      modal: false,
      mealSelect: "1",
      mealTags: [],
    };

    this.toggle = this.toggle.bind(this);
  }

  mealTag = () => {
    var tags = this.props.hubMeal.tags
      .reduce((a, c) => (a += c.name + ";"), "")
      .split(";");
    this.setState({ mealTags: tags });
  };

  handlePriceRender = (price = this.props.hubMeal.price_amount) => {
    if (this.state.corporateData) {
      if (this.state.corporateData.limit >= Number(price)) {
        this.setState({ mealPrice: 0 })
      } else {
        this.setState({ mealPrice: (price - this.state.corporateData.limit).toFixed(2) })
      }
    }
  }

  getCorporateData = async (user_id) => {
    const userData = getZlToken(localStorage.getItem("ZlToken"));
    const employee = await employeeService.checkIfEmployee(userData.company_id, user_id, this.props.hubMeal.meal_type, this.props.hubMeal.meal_id, this.props.hubMeal.date)
    const corporateData = await CorporateService.getCorporateData(userData.company_id);

    if (employee.checkIfEmployee && employee.remainingLimit && corporateData.result[0]) {
      const coverageDays = corporateData.result[0].days_of_week.split(',');
      const mealDay = new Date(`${this.props.hubMeal.date}T00:00:00`).getDay();
      const coverageObj = { 1: 'lunch', 2: "dinner", 3: "bothMeals" };
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
      if (coverageDays.includes(days[mealDay])) {
        const coverage = coverageObj[corporateData.result[0].coverage]
        if (coverage === "bothMeals" || coverage === this.props.hubMeal.meal_type) {
          corporateData.result[0].limit = employee.remainingLimit
          this.setState({
            corporateData: corporateData.result[0],
          })
        }
      }
    } else {
      this.setState({
        corporateData: null
      })
    }
  }

  async toggle() {
    await this.getCorporateData(this.state.userId)
    this.handlePriceRender()
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.cards !== this.props.cards) {
      this.setState({ cards: nextProps.cards });
    }
  }

  componentDidMount() {
    this.mealTag();
    if (localStorage.getItem("mealId") === this.props.hubMealId) {
      return (
        this.toggle(), localStorage.setItem("mealId", JSON.stringify(null))
      );
    }
  }
  render() {
    return (
      <div onClick={this.toggle} className="meals-card">
        <MealDescription
          handlePriceRender={this.handlePriceRender}
          mealPrice={this.state.mealPrice}
          corporateData={this.state.corporateData}
          company_name={this.props.company_name}
          hubMeal={this.props.hubMeal}
          newModal={this.state.modal}
          cards={this.state.cards}
          floor={this.props.floor}
          getCards={this.props.getCards}
          orderSuccessHandler={this.props.orderSuccessHandler}
          orderFailureHandler={this.props.orderFailureHandler}
          history={this.props.history}
          is_passed_cut_off_time={this.props.is_passed_cut_off_time}
          cut_off_time={this.props.cut_off_time}
          pickupInstruction={this.props.pickupInstruction}
          selectedDay={this.props.selectedDay}
          updateCutOff={this.props.updateCutOff}
          restaurantIndex={this.props.restaurantIndex} //restaurant index
          hubMealIndex={this.props.hubMealIndex}
        />
        <Card className="meal-card">
          <Col className="meals-image">
            {this.props.is_passed_cut_off_time && !this.props.hubMeal.sold_out ? (
              <div className={"sold-out"}>ENDED</div>
            ) : null}
            {this.props.hubMeal.sold_out && (
              <div className={"sold-out"}>SOLD OUT</div>
            )}
            <CardImg
              top
              src={filestackService.getFileUrl(this.props.hubMeal.meals.photo, true, !isMobile)}
              alt="Card image cap"
              className="meal-image is-web-image"
            />
          </Col>
          <div className="meal-info-container">
            <div className="meal-info-text">
              <CardTitle>{this.props.hubMeal.meals.name} </CardTitle>
              <Row className="meals-body">
                {parseFloat(this.props.hubMeal.price_amount) >=
                  parseFloat(this.props.hubMeal.meals.walkin_price.toFixed(2)) ? (
                  <Col xs={12} className="meals-price">
                    <span>{`$${this.props.hubMeal.price_amount}`}</span>{" "}
                  </Col>
                ) : (
                  <Col xs={12} className="meals-price">
                    <span>{`$${this.props.hubMeal.price_amount}`}</span>
                    {" "}
                    <span className="prev-price">{`$${this.props.hubMeal.meals.walkin_price.toFixed(2)}`}</span>
                  </Col>
                )}
              </Row>
            </div>
            {
              (this.state.mealTags || []).filter((item) => item !== "")
                .length ? (
                <Col xs={12} className="meals-type">
                  {this.state.mealTags.map((tag) => {
                    return tag ? (
                      <span key={tag} className="meal-type">
                        &nbsp;&nbsp;{tag}&nbsp;&nbsp;
                      </span>
                    ) : (
                      ""
                    );
                  })}
                </Col>
              ) : (
                ""
              )
            }
          </div >
        </Card >
      </div >
    );
  }
}

export default MealModals;
