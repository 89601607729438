import React, { useEffect, useState } from 'react';
import { Col, Card, CardBody, CardText } from "reactstrap";

export default ({ companyInfo: { days_of_week, corporates: { name }, corporatesUsers: { first_name, last_name }, limit } }) => {

  const [daysCovered, setDaysCovered] = useState("")

  useEffect(() => {
    let daysArray = days_of_week.split(",").map(item => {
      return " " + item.charAt(0).toUpperCase() + item.slice(1)
    });
    setDaysCovered(daysArray.join());
  }, [])

  return (
    <Col>
      <Card className="ml-4 mb-3 mr-4">
        <CardBody>
          <CardText>
            {`Member of ${name}`}
          </CardText>
          <CardText className="font-size-small">
            {`Account Manager: ${first_name} ${last_name} `}
          </CardText>
          <CardText>
            Limit per meal: {limit}$
          </CardText>
          <CardText>
            Days Covered: <small>{daysCovered}</small>
          </CardText>
        </CardBody>
      </Card>
    </Col>
  )
}
