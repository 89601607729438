import moment from "moment";
import httpService from "../../../src/services/httpService";
import UserService from "../../services/userService";
import { promisify } from 'util';

const jwt = require('jsonwebtoken');
const url = process.env.REACT_APP_APIURL;
const key = process.env.REACT_APP_SECRET_KEY;

// to split time hour and time min from total time text
const splitTime = (time) => {
  let hours = Number(time.split(":")[0]);
  const minutes = Number(time.split(":")[1]);
  return { hours, minutes };
};

// to get current time min and hour
const getCurrentTime = () => {
  const torontoDate = new Date().toUTCString()

  const currentTime = torontoDate.match(/\s(\d+:\d+)/)[1];
  const CurrentTimeOpj = splitTime(currentTime);
  let currentHours = CurrentTimeOpj.hours - 5;
  let currentMinutes = CurrentTimeOpj.minutes;

  if (torontoDate.includes("PM") && currentHours !== 12) {
    currentHours = currentHours + 12;
  } else if (torontoDate.includes("AM") && currentHours === 12) {
    currentHours = 0;
  }
  return { torontoDate, currentHours, currentMinutes };
};

// to check cut off time before making order
export const checkCutOffTime = async (mealHubId) => {
  let cutOff = true;
  const hubMealdata = await httpService.get(
    `${url}/hub_meals/search-criteria-hubs-meals?id=${mealHubId}`
  ).then(data => data.data.result[0]);

  let hubMealDate = hubMealdata.date
  const cutOffTime = hubMealdata.cut_off_time;
  const cutoffTimeOpj = splitTime(cutOffTime);
  const cut_off_hour = cutoffTimeOpj.hours;
  const cut_off_mins = cutoffTimeOpj.minutes;
  const currentTimeOpj = getCurrentTime();
  const currentHours = currentTimeOpj.currentHours;
  const currentMinutes = currentTimeOpj.currentMinutes;
  hubMealDate = moment(hubMealDate).format('MM/DD/YYYY');

  if (
    new Date(currentTimeOpj.torontoDate) < new Date(hubMealDate) ||
    ((Number(currentHours) < Number(cut_off_hour) ||
      (Number(currentHours) === Number(cut_off_hour) &&
        Number(currentMinutes) <= Number(cut_off_mins))) &&
      new Date(currentTimeOpj.torontoDate) < new Date(`${hubMealDate} 23:59`))
  ) {
    cutOff = false;
  }
  return cutOff;
};

export const getUserInfoByToken = async () => {
  let user = getZlToken(localStorage.getItem("ZlToken"));
  try {
    let userData = await UserService.getUserById(user.id);
    return userData;
  } catch (error) {
    console.log(error);
  }
}

export const getZlToken = Zltoken => jwt.decode(Zltoken);

export const getZlTokenWithError = async Zltoken => promisify(jwt.verify)(Zltoken, key);

export const setZlToken = async (data) => {
  let userInfo = {
    email: data.email,
    company_id: data.company_id,
    phone: data.phone,
    first_name: data.first_name,
    last_name: data.last_name,
    id: data.id,
    phone_verified: data.phone_verified
  }
  const TWO_WEEKS = 60 * 60 * 24 * 7 * 2;
  const ZlToken = await promisify(jwt.sign)(userInfo, process.env.REACT_APP_SECRET_KEY, {
    expiresIn: TWO_WEEKS
  });
  localStorage.setItem("ZlToken", ZlToken);
}

export const setUserToken = async (data) => {
  const TWO_WEEKS = 60 * 60 * 24 * 7 * 2;
  delete data.exp
  const token = await promisify(jwt.sign)(data, process.env.REACT_APP_SECRET_KEY, {
    expiresIn: TWO_WEEKS
  });
  const user = { accessToken: token }
  localStorage.setItem("user", JSON.stringify(user));
}
