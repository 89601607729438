import httpService from "./httpService";
const url = process.env.REACT_APP_APIURL;

async function handleCompaniesData(id) {
    try {
        const data = await httpService.get(`${url}/employees/get-employee-by-user?id=${id}`);
        return data.data;
    } catch (error) {
        throw error;
    }
}

async function checkIfEmployee(address_id, user_id, mealType, mealId, mealDate) {
    try {
        const data = await httpService.get(`${url}/employees/check-if-employee?address_id=${address_id}&user_id=${user_id}&meal_id=${mealId}&meal_type=${mealType}&meal_date=${mealDate}`);
        return data.data.result;
    } catch (error) {
        throw error;
    }
}

export default {
    handleCompaniesData,
    checkIfEmployee
};