import httpService from "./httpService";
import tokenUtils from "./token-utils";
import { getZlToken } from "../components/shared/common";

const notificationAppKey = process.env.REACT_APP_NOTIFICATIONS_APP_KEY;
const url = process.env.REACT_APP_APIURL;

async function login(email, password, ref) {
  try {
    const data = await httpService.post(`${url}/login`, {
      email: email,
      password: password,
      ref: ref,
    });
    tokenUtils.setLoggedUser({ accessToken: data.data.accessToken });
    registerInNotification(data.data.id);
    return data.data;
  } catch (error) {
    throw error;
  }
}

function registerInNotification(id) {
  document.addEventListener(
    "deviceready",
    async function () {
      window.plugins.OneSignal.getPermissionSubscriptionState(async function (
        status
      ) {
        if (status.subscriptionStatus.subscribed) {
          await setOneSignalId(id, status.subscriptionStatus.userId)
            .then((data) =>
              localStorage.setItem("notifyId", status.subscriptionStatus.userId)
            )
            .catch((error) => error);
          //window.plugins.OneSignal.setExternalUserId(loggedIn.id); akhudairy: commented because it causes crash on iOS and we don't need it now
        } else {
          window.plugins.OneSignal.startInit(notificationAppKey).endInit();
        }
      });
    },
    false
  );
}


async function signup(first_name, last_name, email, password, info) {
  var key = typeof info == "object" ? "company_info" : "company_id";
  const data = await httpService
    .post(`${url}/users/register`, {
      first_name: first_name,
      last_name: last_name,
      email: email,
      password: password,
      [key]: info,
    })
    .then((data) => data)
    .catch((error) => error.response);
  return data;
}

async function check_email(email) {
  const data = await httpService.get(
    `${url}/users/email-exists?email=${email}`
  );
  return data.data;
}

async function update_edit_phone(id, phone) {
  const data = await httpService
    .post(`${url}/users/update-phone`, {
      id: id,
      phone: phone,
    })
    .then((data) => data)
    .catch((error) => error.response.data);
  return data;
}

async function setOneSignalId(id, userId) {
  const data = await httpService
    .post(`${url}/users/notification`, { id, userId })
    .then((data) => data)
    .catch((error) => error.response.data);
  return data;
}

async function removeOneSignalId(id, userId) {
  const data = await httpService
    .post(`${url}/users/remove-notification`, { id, userId })
    .then((data) => data)
    .catch((error) => error.response.data);
  return data;
}

// by monther
async function getUserById(id) {
  const data = await httpService.get(`${url}/users/getUserById?id=${id}`);
  return data;
}

// by monther
async function checkPassword(userID, password) {
  const data = await httpService.get(
    `${url}/users/checkUserPassword?id=${userID}&password=${password}`
  );
  return data;
}

async function update_phone(id, phone) {
  const data = await httpService
    .post(`${url}/users/update-phone`, {
      id: id,
      phone: phone,
    })
    .then((data) => data.data)
    .catch((error) => error.response.data);
  return data;
}

async function verify_phone(id, code) {
  const data = await httpService
    .post(
      `${url}/users/verify-phone`,
      {
        id: id,
        code: code,
      },
      { withCredentials: true }
    )
    .then((data) => data.data)
    .catch((error) => error.response.data);
  return data;
}
async function resend_code(id, phone) {
  const data = await httpService
    .post(
      `${url}/users/resend-code`,
      {
        id: id,
        phone: phone,
      },
      { withCredentials: true }
    )
    .then((data) => data.data)
    .catch((error) => error.response.data);
  return data;
}

async function reset_password(email, code, password) {
  const data = await httpService.get(
    `${url}/users/new-password?email=${email}&code=${code}&password=${password}`
  );
  return data.data;
}

async function forgot_password(email) {
  try {
    const data = await httpService.post(`${url}/users/forgot-password`, {
      email: email,
    });
    return data.data;
  } catch (error) {
    throw error;
  }
}

async function edit_profile(first_name, last_name, email, phone) {
  try {
    const data = await httpService.post(`${url}/users/edit-profile`, {
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
    });
    return data;
  } catch (error) {
    throw error;
  }
}

async function logout() {
  let userData = getZlToken(localStorage.getItem("ZlToken"));
  const id = userData.id;
  const notifyId = localStorage.getItem("notifyId");
  await removeOneSignalId(id, notifyId || "")
    .then(() => {
      localStorage.clear();
      window.location.hash = "login";
    })
    .catch((error) => {
      localStorage.clear();
      window.location.hash = "login";
    });
}

async function save_image(id, imageURL) {
  try {
    const data = await httpService.post(`${url}/users/edit-profile`, {
      profile_pic: imageURL,
    });
    return data;
  } catch (error) {
    throw error;
  }
}

const get_refferal = async () => {
  try {
    const data = await httpService.get(`${url}/users/get-ref-link`);

    return data.data.result;
  } catch (error) {
    throw error;
  }
};

async function help(subject, message) {
  try {
    const data = await httpService.post(`${url}/zendesk`, {
      subject: subject,
      comment: message,
    });
    return data;
  } catch (error) {
    throw error;
  }
}

const deactivateAccount = async (reason) => {
  const response = await httpService.post(`${url}/users/deactivate-account`, {
    reason,
  });
  return response.data.result;
};

export default {
  login,
  signup,
  check_email,
  update_phone,
  verify_phone,
  resend_code,
  reset_password,
  forgot_password,
  save_image,
  edit_profile,
  update_edit_phone,
  logout,
  setOneSignalId,
  get_refferal,
  help,
  getUserById,
  checkPassword,
  deactivateAccount,
};
