import React from "react";
import { FormGroup, Input, Button, Row, Col } from "reactstrap";
import companyService from "../../services/companyService";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { getZlToken } from "../shared/common";

const inputs = [
  {
    header: "Name this address",
    key: 1,
    className: "",
    type: "text",
    bsSize: "lg",
    id: "namingByUser",
    autoComplete: "off",
    placeholder: "Address name, e.g Home, Work...",
    onChange: true,
  },
  {
    header: "Enter an address",
    key: 2,
    className: "company-inputs",
    ref: "companies",
    type: "text",
    bsSize: "lg",
    id: "companyName",
    autoComplete: "off",
    placeholder: "Address/Building name",
    onChange: true,
    onKeyUp: true,
    icon: true
  }
]

class SelectCompany extends React.Component {
  constructor(props) {
    let userData = getZlToken(localStorage.getItem("ZlToken"));
    super(props);
    this.state = {
      id: userData.id,
      namingByUser: "",
      companies: [],
      companyName: "",
      companyId: 0,
      newCompanies: [],
      backArrowVisable: userData.company_id,
      showen: false,
    };
  }

  handleChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.preventDefault();
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  getCompanies = async () => {
    const companyGet = await companyService.get_company();
    if (companyGet.data.result === "authentication required") {
      this.props.history.push("/login");
      localStorage.clear();
    }
    let newData = JSON.parse(localStorage.user);
    newData.accessToken = companyGet.headers.authorization;
    localStorage.setItem("user", JSON.stringify(newData));
    this.setState({
      companies: companyGet.data.result,
    });
  };

  addNewAddress = async () => {
    const id = Number(this.state.id);
    const companyId = this.state.companyId;
    const userNaming = this.state.namingByUser

    const companyAssigned = await companyService.addAddress(id, companyId, userNaming);
    if (companyAssigned.data && companyAssigned.data.result === "success") {
      let newData = getZlToken(localStorage.getItem("ZlToken"));
      newData.company_id = companyId;
      await companyService.changeUserAddress(newData, companyId);
      this.props.history.push("/dailymenu");
    }
  };

  clicked = () => {
    if (this.state.showen === true) {
      return "";
    } else {
      return "notShowen";
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    if (e.target.id === 'companyName') this.setState({ companyId: 0 })
    e.preventDefault(e);
};

autoComplete = () => {
  let input = this.state.companyName;
  if (input.length > 0) {
    let companies = this.state.companies;
    let newCompanies = companies.filter((e) =>
      e.name.toLowerCase().startsWith(input.toLowerCase())
    );

    if (newCompanies.length > 0) {
      this.setState({ newCompanies: newCompanies, showen: true });
    } else {
      this.setState({ newCompanies: [], showen: false });
    }
  } else {
    this.setState({ newCompanies: [], showen: false });
  }
};

requestCompany = async () => {
  this.props.history.push("/requestcompany");
};

componentDidMount() {
  this.getCompanies();
}

handleName = (companyName, companyId) => {
  this.setState({
    companyName,
    companyId,
    newCompanies: []
  });
};

render() {
  return (
    <div>
      <div id="select-company-form">
        {this.state.backArrowVisable && (
          <div>
            {isMobile ? (
              <Link to="profilepage">
                <i
                  className="fa fa-arrow-left xs-block d-md-none"
                  id="backArrow"
                ></i>
              </Link>
            ) : (
              <Link to="/useraddresses">
                <i
                  className="fa fa-arrow-left xs-none d-md-block"
                  id="backArrow"
                ></i>
              </Link>
            )}
          </div>
        )}
        <h3 id="welcome-ver">Add Delivery Address</h3>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <FormGroup>
            <div className="text-xl-center mt-5">
              {inputs.map(({ header, key, className, ref, type, bsSize, id, autoComplete, placeholder, onChange, onKeyUp, onFocus, icon }) => {
                return (<>
                  <h4 className="mt-5">{header}</h4>
                  {icon && <i className="fas fa-map-marker-alt gps-company"></i>}
                  <Input key={key}
                    className={className}
                    ref={ref ? ref : `defaultValue`}
                    type={type}
                    bsSize={bsSize}
                    id={id}
                    value={this.state[id]}
                    autoComplete={autoComplete}
                    placeholder={placeholder}
                    onChange={onChange && this.handleChange}
                    onKeyUp={onKeyUp && this.autoComplete}
                  ></Input>
                </>
                )
              })
              }
              <div className={`companys-border ${this.clicked()}`}>
                {this.state.newCompanies.map((company, index) => {
                  return (
                    <div key={index}>
                      <div
                        className="company-border"
                        onClick={() => this.handleName(company.name, company.id)}
                      >
                        <ul
                          className={`${company.id} m-1`}
                          id={company.name}
                          name={company.id}
                        >
                          {company.name}
                        </ul>
                        <ul
                          className={`${company.id} m-1 color-gray`}
                          id={company.name}
                          name={company.id}
                        >
                          {company.location}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="company-footer">
                <p className="mb-0">
                  Don’t see your building or company listed?
                </p>
                <label id="request-company" onClick={this.requestCompany}>
                  Add ZipLunch to your building or company
                </label>
              </div>

              <Row className="mt-4">
                <Col>
                  <Button onClick={this.addNewAddress} color="danger" disabled={!(this.state.companyId && this.state.namingByUser)} >Save</Button>
                </Col>
                <Col>
                  <Button onClick={() => { this.props.history.push("/useraddresses"); }}>Cancel</Button>
                </Col>
              </Row>
            </div>
          </FormGroup>
        </form>
      </div>
    </div >
  );
}
}

export default SelectCompany;
