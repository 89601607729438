import React from "react";
import { Navbar, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import { isBrowser, isMobile, isIOS } from "react-device-detect";
import logoWhite from "../../assets/images/white_logo.png";
import ProfilePage from "../profile/profile_page";

const environmentName = process.env.REACT_APP_ENVIRONMENT_NAME;

class MealHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      showen: false,
    };

    if (this.props.iosCSSClass) {
      this.iosCSSClass = "ios-safe-padding m-0 " + this.props.iosCSSClass;
    } else {
      this.iosCSSClass = "ios-safe-padding m-0 ios-header-backgroung";
    }
  }

  handleClick = () => {
    this.setState((prevState) => ({
      showen: !prevState.showen,
    }));
  };

  showen = () => {
    if (this.state.showen === true) {
      return "";
    } else {
      return "hide";
    }
  };

  render() {
    if (isMobile) {
      return (
        <>
          <Navbar
            className={`meal-navbar-web main-header ${
              this.props.additionalClass
            } ${isIOS ? "ios-safe-padding m-0" : ""}`}
          >
            <div>
              <Link to="/dailymenu">
                <img className="nav-meal-image" src={logoWhite} alt="" />
              </Link>
            </div>
            <p className="nav-meal-title-web-title">{this.state.title}</p>
            <Nav className="title-nav">
              <Link className="nav-meal-icons" to="/profilepage">
                <p className="nav-meal-icon-web">
                  <i className="fas fa-lg fa-bars"></i>
                </p>
              </Link>
            </Nav>
          </Navbar>
          <Navbar className={isIOS ? this.iosCSSClass : "meal-navbar"}>
            <h2 className="HomeNavTitle">Free Delivery To</h2>
            <p style={{ color: "blue" }}> {environmentName.toString()}</p>
          </Navbar>
        </>
      );
    }

    if (isBrowser) {
      return (
        <nav>
          <Navbar className="meal-navbar-web main-header">
            <div>
              <Link to="/dailymenu">
                <img className="nav-meal-image" src={logoWhite} alt="" />
              </Link>
            </div>
            <p className="nav-meal-title-web-title">{this.state.title}</p>
            <Nav className="title-nav">
              <div>
                <p className="nav-meal-icon-web" onClick={this.handleClick}>
                  <i className="fas fa-lg fa-bars"></i>
                </p>
                <ProfilePage
                  showen={this.state.showen}
                  imageURL={this.props.imageURL}
                  handleClick={this.handleClick}
                />
              </div>
            </Nav>
          </Navbar>
          {this.props.leftCornerTitle && (
            <Navbar className="meal-navbar-web">
              <div>
                  <h2 className="HomeNavTitle">{this.props.leftCornerTitle}</h2>
              </div>
              <p style={{ color: "blue", display: 'none' }}> {environmentName.toString()}</p>
              <p className="nav-meal-title-web">{this.state.title}</p>
            </Navbar>
          )}
        </nav>
      );
    }
  }
}

export default MealHeader;
