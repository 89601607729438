import React, { Component } from "react";
import userService from "../../services/userService";
import { Button, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import validationService from "../../services/validationService";
import { isBrowser, isMobile } from "react-device-detect";
import urlService from "../../services/urlService"

class resetPassword extends Component {
  state = {
    email: "",
    code: "",
    password: "",
    confirmPassword: "",
    passwordError: "",
    confirmPasswordError: "",
    passwordValid: false,
    notSamePassword: false,
    confirmPasswordValid: false
  };

  checkPasswordValid = () => {
    let passwordChecked = validationService.validatPassword(
      this.state.password
    );
    this.setState({
      passwordError: passwordChecked.erroeMessage,
      passwordValid: passwordChecked.passwordValid
    });
  };

  checkConfirmPasswordValid = () => {
    let ConfirmPasswordChecked = validationService.validatPassword(
      this.state.confirmPassword
    );
    this.setState({
      confirmPasswordError: ConfirmPasswordChecked.erroeMessage,
      confirmPasswordValid: ConfirmPasswordChecked.passwordValid
    });
  };

  checkAllFieldsValidations = () => {
    this.checkPasswordValid();
    this.checkConfirmPasswordValid();
  };

  getCode = () => {
    const url = new URL(window.location.href);
    url.href = url.href.replace("#/", "");
    let email = null;
    let code = null;
    /* Saved for later use when using branch.io for reset-password
    if (isMobile) {
      email = window["deeplinkData"]["to"];
      code = window["deeplinkData"]["token"];
    }
    */
    try {
      email = urlService.getParameterByName("utm_campaign");
      code = urlService.getParameterByName("utm_source");
    } catch(err) {
      console.log(err);
    }

    this.setState({ email: email });
    this.setState({ code: code });
  };

  checkPassword = () => {
    let passwordChecked = validationService.checkPassword(
      this.state.password,
      this.state.confirmPassword
    );
    this.setState({ notSamePassword: passwordChecked });
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    e.preventDefault(e);
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  handleSubmit = async e => {
    e.preventDefault();

    if (
      !(
        this.state.passwordValid &&
        this.state.confirmPasswordValid &&
        !this.state.notSamePassword
      )
    ) {
      this.checkAllFieldsValidations();
      return;
    }

    const passwordReset = await userService.reset_password(
      this.state.email,
      this.state.code,
      this.state.password
    );

    if (passwordReset.result === "success") {
      if (isMobile) {
        this.props.history.push("/login");
      }
      if (isBrowser) {
        this.props.history.push("/");
      }
    }
  };

  componentDidMount() {
    this.getCode();
  }

  render() {
    if (isMobile) {
      return (
        <div>
          <div className="marTop d-none d-md-block"></div>
          <Link to="/forgotpassword">
            <i className="fa fa-arrow-left" id="backArrow" />
          </Link>
          <div id="password-mob-form">
            <h3 id="password-tittle">New password</h3>
            <p id="forgot-password-account">Please enter a new password.</p>
            <form onSubmit={this.handleSubmit}>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.passwordError
                  )}`}
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.value}
                  onKeyUp={this.checkPassword}
                  onBlur={this.checkPasswordValid}
                />
                {this.state.passwordError && (
                  <label className="errorMessage">
                    {this.state.passwordError}
                  </label>
                )}
              </FormGroup>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.confirmPasswordError
                  )}`}
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.value}
                  onBlur={this.checkConfirmPasswordValid}
                  onKeyUp={this.checkPassword}
                />
                {this.state.notSamePassword && (
                  <label className="errorMessage">
                    The passwords you entered do not match
                  </label>
                )}
                {this.state.confirmPasswordError && (
                  <label className="errorMessage">
                    {this.state.confirmPasswordError}
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Button color="danger" id="btn" type="submit">
                  Update
                </Button>
              </FormGroup>
            </form>
          </div>
        </div>
      );
    }
    if (isBrowser) {
      return (
        <div>
          <div id="password-mob-form">
            <h3 id="password-tittle">New password</h3>
            <p id="forgot-password-account">Please enter a new password.</p>
            <form onSubmit={this.handleSubmit}>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.passwordError
                  )}`}
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.value}
                  onKeyUp={this.checkPassword}
                  onBlur={this.checkPasswordValid}
                />
                {this.state.passwordError && (
                  <label className="errorMessage">
                    {this.state.passwordError}
                  </label>
                )}
              </FormGroup>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.confirmPasswordError
                  )}`}
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.value}
                  onBlur={this.checkConfirmPasswordValid}
                  onKeyUp={this.checkPassword}
                />
                {this.state.notSamePassword && (
                  <label className="errorMessage">
                    The passwords you entered do not match
                  </label>
                )}
                {this.state.confirmPasswordError && (
                  <label className="errorMessage">
                    {this.state.confirmPasswordError}
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Button color="danger" id="btn" type="submit">
                  Update
                </Button>
              </FormGroup>
            </form>
          </div>
        </div>
      );
    }
  }
}

export default resetPassword;
