import React, { Component } from 'react'
import image from "../../assets/images/green_check.png";
import { Modal, ModalBody } from 'reactstrap'

export class AccountDeletionSuccess extends Component {
  render() {
    return (
      <Modal
        centered
        isOpen={this.props.successModal}
        toggle={this.props.toggle}
      >
        <div>
          <ModalBody>
            <ModalBody id="reset-password-image-body">
              <img id="reset-password-image" src={image} alt="" />
            </ModalBody>
            <p className="text-center text-dark font-weight-bold">
              Deletion request successfully sent. Please give it 24 to 48 hours for it to take effect.
            </p>
          </ModalBody>
        </div>
      </Modal>
    )
  }
}

export default AccountDeletionSuccess