export class AppError extends Error {
  constructor(data, originalError) {
    super(originalError.message);
    this.data = data;
    this.originalError = originalError;
  }
}

export class UnauthorizedError extends AppError {}


export class BadInputError extends AppError {
  constructor(data, originalError) {
    super(data, originalError);


    if (data && data.result) {
      const errors = {};
      Object.entries(data.result).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) errors[key] = value[0];
        if (typeof value === 'string') errors[key] = value;
      });

      if (Object.entries(errors).length > 0) this.errors = errors;
    }
  }
}

export class NotFoundError extends AppError {}
