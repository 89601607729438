import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import mealsService from "../../services/mealsService";
import CancelSuccess from "./cancel_success";
import CancelFailure from "./cancel_failure";
import { getZlToken } from "../shared/common";
class ConfirmCancel extends React.Component {
  constructor(props) {
    let userData = getZlToken(localStorage.getItem("ZlToken"));
    super(props);
    this.state = {
      modal: this.props.newModal,
      userId: userData.id,
      id: this.props.id,
      openSuccess: false,
      openFailure: false
    };
  }

  cancelOrder = async () => {
    let orderIds = [];
    orderIds.push(this.state.id);
    await mealsService
      .cancel_Order(orderIds, this.state.userId)
      .then(res => {
        this.setState({ openSuccess: true });
      })
      .catch(res => {
        this.setState({ openFailure: true });
      });
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  toggle = () => {
    this.props.toggel();
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  componentDidUpdate() {
    if (this.props.id !== this.state.id) {
      this.setState({ id: this.props.id });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newModal !== this.props.newModal) {
      this.toggle();
    }
  }

  handleClick = () => {
    this.toggle();
  };

  render() {
    return (
      <div>
        <Modal
          centered
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={`${this.props.className} reset-password`}
        >
          <div>
            <ModalBody>
              <p className="popup-body">
                Are you sure you want to cancel this order?
              </p>
              <div className="txtCenter">
                <Button
                  color="success"
                  id="btnCancelYes"
                  onClick={this.cancelOrder}
                >
                  YES
                </Button>
                <Button color="danger" id="btnCancelNo" onClick={this.toggle}>
                  NO
                </Button>
              </div>
            </ModalBody>
          </div>
        </Modal>
        <CancelSuccess
          newModal={this.state.openSuccess}
          handelHideCancel={this.props.handelHideCancel}
          toggle={this.props.toggel}
        />

        <CancelFailure newModal={this.state.openFailure} />
      </div>
    );
  }
}

export default ConfirmCancel;
