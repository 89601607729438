import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

class TermsAndConditions extends Component {
  state = {
    modal: false
  };

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  render() {
    return (
      <div>
        <h4 id="terms" onClick={this.toggle}>
          {this.props.buttonLabel}Terms and Conditions
        </h4>
        <Modal
          centered
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Terms and conditions</ModalHeader>

          <ModalBody className="model-body">
            <p>Last Modified: November 26th, 2019</p>
            <p>
              <b>Acceptance of the Terms of Use</b>
              <br />
              These terms of use are entered into by and between You and
              ZipLunch Inc. ("<b>ZipLunch</b>," "<b>we</b>," or "<b>us</b>").
              The following terms and conditions (the "<b>Terms of Use</b>"),
              govern your access to and use of www.ZipLunch.com, including any
              content, functionality, and services offered on or through
              www.ZipLunch.com (collectively, the "<b>Website</b>"), whether as
              a guest or a registered user.
            </p>
            <p>
              Please read the Terms of Use carefully before you start to use the
              Website.{" "}
              <b>
                By using the Website, you accept and agree to be bound and abide
                by these Terms of Use and our Privacy Policy, incorporated
                herein by reference
              </b>
              . If you do not want to agree to these Terms of Use or the Privacy
              Policy, you must not access or use the Website.
            </p>
            <p>
              This Website is offered and available to users who are 18 years of
              age or older and reside in North America. By using this Website,
              you represent and warrant that you are of legal age to form a
              binding contract with ZipLunch and meet all of the foregoing
              eligibility requirements. If you do not meet all of these
              requirements, you must not access or use the Website.
            </p>
            <p>
              <b>Changes to the Terms of Use</b>
              <br />
              We may revise and update these Terms of Use from time to time at
              our sole discretion. All changes are effective immediately when we
              post them, and apply to all access to and use of the Website
              thereafter.
            </p>
            <p>
              Your continued use of the Website following the posting of revised
              Terms of Use means that you accept and agree to the changes. You
              are expected to check this page frequently so you are aware of any
              changes, as they are binding on you.
            </p>
            <p>
              <b>
                Overview and Ordering Terms
                <br />
                Overview
              </b>
              <br />
              The purpose of the Website is to provide a platform to connect you
              with our restaurant or catering partners (collectively,
              “Partners”) and to facilitate the ordering of food and related
              items and services to be provided to you by the Partner. WE MAKE
              NO REPRESENTATIONS REGARDING THE ITEMS OR SERVICES PROVIDED TO YOU
              BY OUR PARTNERS. WE ARE NOT RESPONSIBLE FOR AND ARE NOT INVOLVED
              IN PREPARING OR DELIVERING ANY FOOD ITEMS YOU ORDER THROUGH THE
              WEBSITE. OUR PARTNERS OPERATE INDEPENDENTLY OF ZIPLUNCH. YOUR
              INTERACTIONS BETWEEN YOU AND OUR PARTNERS ARE STRICTLY BETWEEN YOU
              AND OUR PARTNERS. ZIPLUNCH IS NOT LIABLE FOR ANY ACTS OR OMISSIONS
              OF OUR PARTNERS.
            </p>
            <p>
              <b>Account and Payment Information</b>
              <br />
              When you create an account on the Website, you may be asked to
              provide us with payment information. Your ability to use the
              Website may be limited until this step has been completed. By
              providing us with this information, you represent and warrant that
              you have the legal right to use all payment method(s) provided and
              that such information is accurate.
            </p>
            <p>
              You may place food orders through the Website through various
              channels, as explained on the Website or in any ZipLunch email,
              SMS or MMS (collectively, “<b>ZipLunch Messages</b>”). You may
              also store your payment information (e.g., credit card) on file
              with us or with our third-party suppliers to facilitate convenient
              ordering.
            </p>
            <p>
              When you place an order with our Partners through the Website or
              by replying to ZipLunch Messages, your CREDIT OR DEBIT CARD MAY BE
              CHARGED IMMEDIATELY for the specified amount, or, for catering
              orders, at such other time as indicated on your order confirmation
              or other ZipLunch Messages. We may enable ordering through other
              means and notify you of those means through a Message. You will
              receive a Message confirming that your order has been placed and
              containing the receipt for your charges.
            </p>
            <p>
              When you provide payment information, you represent and warrant
              that the information is accurate, that you are authorized to use
              the payment method provided, and that you will notify us of
              changes to the payment information. We reserve the right to
              utilize third party credit card updating services to obtain
              current expiration dates on credit cards.
            </p>
            <p>
              ZipLunch uses third-party payment platforms, such as Stripe or
              PSIgate to process credit card transactions for your ZipLunch
              account. By using ZipLunch and agreeing to the ZipLunch Terms of
              Use, you also agree to be bound by Stripe’s or PSIgates’ Terms of
              Service. Those terms can be found on their respective website
              (https://stripe.com/en-US/legal/ or https://www.psigate.com)
            </p>
            <p>
              <b>Order Cancellation</b>
              <br />
              To cancel an order, you must reply “CANCEL” to the ZipLunch
              Message order confirmation or select the cancellation option in
              your ZipLunch account. You may cancel your order in exchange for a
              credit, or, at our sole discretion, a refund in the amount of the
              full purchase price, provided that you cancel the order before the
              cutoff time specified in your order confirmation. ZIPLUNCH WILL
              NOT REFUND YOU OR OFFER YOU ANY ZIPLUNCH CREDITS IF YOU CANCEL
              YOUR ORDER AFTER THE CUTOFF TIME SPECIFIED IN YOUR ORDER
              CONFIRMATION. FOR CATERING ORDERS, PARTIAL REFUNDS MAY BE
              AVAILABLE, SUBJECT TO THE TERMS OF THE ORDER CONFIRMATION OR AS
              SET FORTH IN ZIPLUNCH MESSAGES.
            </p>
            <p>
              <b>Availability</b>
              <br />
              All food orders are subject to availability. Orders are honored on
              a first-come-first-served basis, and your order (including any
              “pending” order) is not confirmed until you receive a confirmation
              ZipLunch Message.
            </p>
            <p>
              <b>Meal Depictions</b>
              <br />
              We provide photographs and descriptions of our Partners’ offerings
              provided by our Partners. You acknowledge that a meal may not look
              exactly as photographed or taste as described, and by using the
              Website you agree to accept such meals where there are reasonable
              variations between the photographs/descriptions and what the
              Partners provide.
            </p>
            <p>
              <b>Delivery</b>
              <br />
              Partners are solely and exclusively responsible for delivering
              food orders to you. YOU AGREE THAT DELIVERY ESTIMATES ARE
              ESTIMATES ONLY, AND WE ARE NOT LIABLE FOR REASONABLE VARIATIONS
              (+/- 30 MINUTES) IN THE ESTIMATED DELIVERY TIME.
            </p>
            <p>
              <b>Email and Messaging Services</b>
              <br />
              When you sign up for an account on our Website, you may be
              opted-in to our e-mail and/or SMS ordering and notifications. Upon
              sign up, we may send you a confirmation message informing you of
              the primary medium we will be using to communicate with you
              (either e-mail or SMS).
            </p>
            <p>
              You can always opt-out of e-mail, SMS and/or MMS notifications by
              changing your preferences online in your account profile.
            </p>
            <p>
              We may also send you updates or promotional materials from
              time-to-time, which you have the right to opt-out of at any time.
            </p>
            <p>
              If at any time you need to reach our customer support team, just
              e-mail support@ZipLunch.com, and we will respond with
              instructions.
            </p>
            <p>
              SMS, MMS and data charges may apply for any messages or images
              sent to you from us and/or us from you. If you have any questions
              about your mobile messaging or data plans, please contact your
              cellular phone and/or internet service provider.
            </p>
            <p>
              If you have any questions or concerns regarding privacy, please
              read our Privacy Policy.
            </p>
            <p>
              <b>Accessing the Website and Account Security</b>
              <br />
              We reserve the right to withdraw or amend this Website, and any
              service or material we provide on the Website, in our sole
              discretion without notice. We will not be liable if for any reason
              all or any part of the Website is unavailable at any time or for
              any period. From time to time, we may restrict access to some
              parts of the Website, or the entire Website, to users, including
              registered users.
            </p>
            <p>You are responsible for both:</p>
            <ul>
              <li>
                Making all arrangements necessary for you to have access to the
                Website.
              </li>
              <li>
                Ensuring that all persons who access the Website through your
                internet connection are aware of these Terms of Use and comply
                with them.
              </li>
            </ul>
            <p>
              To access the Website or some of the resources it offers, you may
              be asked to provide certain registration details or other
              information. It is a condition of your use of the Website that all
              the information you provide on the Website is correct, current,
              and complete. You agree that all information you provide to
              register with this Website or otherwise, including, but not
              limited to, through the use of any interactive features on the
              Website, is governed by our Privacy Policy, and you consent to all
              actions we take with respect to your information consistent with
              our Privacy Policy.
            </p>
            <p>
              If you choose, or are provided with a user name, password, or any
              other piece of information as part of our security procedures, you
              must treat such information as confidential, and you must not
              disclose it to any other person or entity. You also acknowledge
              that your account is personal to you and agree not to provide any
              other person with access to this Website or portions of it using
              your user name, password, or other security information. You agree
              to notify us immediately of any unauthorized access to or use of
              your user name or password or any other breach of security. You
              also agree to ensure that you exit from your account at the end of
              each session. You should use particular caution when accessing
              your account from a public or shared computer so that others are
              not able to view or record your password or other personal
              information.
            </p>
            <p>
              We have the right to disable any user name, password, or other
              identifier, whether chosen by you or provided by us, at any time
              in our sole discretion for any or no reason, including if, in our
              opinion, you have violated any provision of these Terms of Use.
            </p>
            <p>
              <b>Intellectual Property Rights</b>
              <br />
              The Website and its entire contents, features, and functionality
              (including but not limited to all information, software, text,
              displays, images, video, and audio, and the design, selection, and
              arrangement thereof) are owned by ZipLunch, its licensors, or
              other providers of such material and are protected by Canadian and
              international copyright, trademark, patent, trade secret, and
              other intellectual property or proprietary rights laws.
            </p>
            <p>
              These Terms of Use permit you to use the Website for your
              personal, non-commercial use only. You must not reproduce,
              distribute, modify, create derivative works of, publicly display,
              publicly perform, republish, download, store, or transmit any of
              the material on our Website, except as follows:
            </p>
            <ul>
              <li>
                Your computer may temporarily store copies of such materials in
                RAM incidental to your accessing and viewing those materials.
              </li>
              <li>
                You may store files that are automatically cached by your Web
                browser for display enhancement purposes.
              </li>
              <li>
                You may print one copy of a reasonable number of pages of the
                Website for your own personal, non-commercial use and not for
                further reproduction, publication, or distribution.
              </li>
              <li>
                If we provide desktop, mobile, or other applications for
                download, you may download a single copy to your computer or
                mobile device solely for your own personal, non-commercial use,
                provided you agree to be bound by our end user license agreement
                for such applications.
              </li>
              <li>
                If we provide social media features with certain content, you
                may take such actions as are enabled by such features.
              </li>
            </ul>
            You must not:
            <ul>
              <li>Modify copies of any materials from this site.</li>
              <li>
                Use any illustrations, photographs, video or audio sequences, or
                any graphics separately from the accompanying text.
              </li>
              <li>
                Delete or alter any copyright, trademark, or other proprietary
                rights notices from copies of materials from this site.
              </li>
            </ul>
            <p>
              You must not access or use for any commercial purposes any part of
              the Website or any services or materials available through the
              Website.
            </p>
            <p>
              If you print, copy, modify, download, or otherwise use or provide
              any other person with access to any part of the Website in breach
              of the Terms of Use, your right to use the Website will stop
              immediately and you must, at our option, return or destroy any
              copies of the materials you have made. No right, title, or
              interest in or to the Website or any content on the Website is
              transferred to you, and all rights not expressly granted are
              reserved by ZipLunch. Any use of the Website not expressly
              permitted by these Terms of Use is a breach of these Terms of Use
              and may violate copyright, trademark, and other laws.
            </p>
            <p>
              <b>Trademarks</b>
              <br />
              The ZipLunch name, the ZipLunch logo, and all related names,
              logos, product and service names, designs, and slogans are
              trademarks of ZipLunch or its affiliates or licensors. You must
              not use such marks without the prior written permission of
              ZipLunch. All other names, logos, product and service names,
              designs, and slogans on this Website are the trademarks of their
              respective owners.
            </p>
            <b>Prohibited Uses</b>
            <br />
            <p>
              You may use the Website only for lawful purposes and in accordance
              with these Terms of Use. You agree not to use the Website:
            </p>
            <ul>
              <li>
                In any way that violates any applicable federal, state, local,
                or international law or regulation (including, without
                limitation, any laws regarding the export of data or software to
                and from the US or other countries).
              </li>
              <li>
                For the purpose of exploiting, harming, or attempting to exploit
                or harm minors in any way by exposing them to inappropriate
                content, asking for personally identifiable information, or
                otherwise.
              </li>
              <li>
                To send, knowingly receive, upload, download, use, or re-use any
                material that does not comply with the Content Standards set out
                in these Terms of Use.
              </li>
              <li>
                To transmit, or procure the sending of, any advertising or
                promotional material without our prior written consent,
                including any "junk mail," "chain letter," "spam," or any other
                similar solicitation.
              </li>
              <li>
                To impersonate or attempt to impersonate ZipLunch, a ZipLunch
                employee, another user, or any other person or entity
                (including, without limitation, by using email addresses or
                screen names associated with any of the foregoing).
              </li>
              <li>
                To engage in any other conduct that restricts or inhibits
                anyone's use or enjoyment of the Website, or which, as
                determined by us, may harm ZipLunch or users of the Website, or
                expose them to liability.
              </li>
            </ul>
            Additionally, you agree not to:
            <ul>
              <li>
                Use the Website in any manner that could disable, overburden,
                damage, or impair the site or interfere with any other party's
                use of the Website, including their ability to engage in real
                time activities through the Website.
              </li>
              <li>
                Use any robot, spider, or other automatic device, process, or
                means to access the Website for any purpose, including
                monitoring or copying any of the material on the Website.
              </li>
              <li>
                Use any manual process to monitor or copy any of the material on
                the Website, or for any other purpose not expressly authorized
                in these Terms of Use, without our prior written consent.
              </li>
              <li>
                Use any device, software, or routine that interferes with the
                proper working of the Website.
              </li>
              <li>
                Introduce any viruses, Trojan horses, worms, logic bombs, or
                other material that is malicious or technologically harmful.
              </li>
              <li>
                Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of the Website, the server on which the
                Website is stored, or any server, computer, or database
                connected to the Website.
              </li>
              <li>
                Attack the Website via a denial-of-service attack or a
                distributed denial-of-service attack.
              </li>
              <li>
                Otherwise attempt to interfere with the proper working of the
                Website.
              </li>
            </ul>
            <p>
              <b>User Contributions</b>
              <br />
              The Website may contain message boards, chat rooms, personal web
              pages or profiles, forums, bulletin boards, and other interactive
              features (collectively, "<b>Interactive Services</b>") that allow
              users and Partners to post, submit, publish, display, or transmit
              to other users or other persons (hereinafter, "<b>post</b>")
              content or materials (collectively, "<b>User Contributions</b>")
              on or through the Website.
            </p>
            <p>
              All User Contributions must comply with the Content Standards set
              out in these Terms of Use.
            </p>
            <p>
              Any User Contribution you post to the Website will be considered
              non-confidential and non-proprietary. By providing any User
              Contribution on the Website, you grant us and our affiliates and
              service providers, and each of their and our respective licensees,
              successors, and assigns the right to use, reproduce, modify,
              perform, display, distribute, and otherwise disclose to third
              parties any such material for any purpose.
            </p>
            <p>You represent and warrant that:</p>
            <ul>
              <li>
                You own or control all rights in and to the User Contributions
                and have the right to grant the license granted above to us and
                our affiliates and service providers, and each of their and our
                respective licensees, successors, and assigns.
              </li>
              <li>
                All of your User Contributions do and will comply with these
                Terms of Use.
              </li>
            </ul>
            <p>
              You understand and acknowledge that you are responsible for any
              User Contributions you submit or contribute, and you, not
              ZipLunch, have full responsibility for such content, including its
              legality, reliability, accuracy, and appropriateness.
            </p>
            <p>
              We are not responsible or liable to any third party for the
              content or accuracy of any User Contributions posted by you or any
              other user of the Website, including our Partners.
            </p>
            <p>
              <b>Monitoring and Enforcement; Termination</b>
              <br />
              We have the right to:
            </p>
            <ul>
              <li>
                Remove or refuse to post any User Contributions for any or no
                reason in our sole discretion.
              </li>
              <li>
                Take any action with respect to any User Contribution that we
                deem necessary or appropriate in our sole discretion, including
                if we believe that such User Contribution violates the Terms of
                Use, including the Content Standards, infringes any intellectual
                property right or other right of any person or entity, threatens
                the personal safety of users of the Website or the public, or
                could create liability for ZipLunch.
              </li>
              <li>
                Disclose your identity or other information about you to any
                third party who claims that material posted by you violates
                their rights, including their intellectual property rights or
                their right to privacy.
              </li>
              <li>
                Take appropriate legal action, including without limitation,
                referral to law enforcement, for any illegal or unauthorized use
                of the Website.
              </li>
              <li>
                Terminate or suspend your access to all or part of the Website
                for any or no reason, including without limitation, any
                violation of these Terms of Use.
              </li>
            </ul>
            <p>
              Without limiting the foregoing, we have the right to cooperate
              fully with any law enforcement authorities or court order
              requesting or directing us to disclose the identity or other
              information of anyone posting any materials on or through the
              Website. YOU WAIVE AND HOLD HARMLESS ZIPLUNCH AND ITS AFFILIATES,
              LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM
              ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN
              AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW
              ENFORCEMENT AUTHORITIES.
            </p>
            <p>
              However, we cannot review all material before it is posted on the
              Website, and cannot ensure prompt removal of objectionable
              material after it has been posted. Accordingly, we assume no
              liability for any action or inaction regarding transmissions,
              communications, or content provided by any user or third party. We
              have no liability or responsibility to anyone for performance or
              nonperformance of the activities described in this section.
            </p>
            <p>
              <b>Content Standards</b>
              <br />
              These content standards apply to any and all User Contributions
              and use of Interactive Services. User Contributions must in their
              entirety comply with all applicable federal, state, local, and
              international laws and regulations. Without limiting the
              foregoing, User Contributions must not:
            </p>
            <ul>
              <li>
                Contain any material that is defamatory, obscene, indecent,
                abusive, offensive, harassing, violent, hateful, inflammatory,
                or otherwise objectionable.
              </li>
              <li>
                Promote sexually explicit or pornographic material, violence, or
                discrimination based on race, sex, religion, nationality,
                disability, sexual orientation, or age.
              </li>
              <li>
                Infringe any patent, trademark, trade secret, copyright, or
                other intellectual property or other rights of any other person.
              </li>
              <li>
                Violate the legal rights (including the rights of publicity and
                privacy) of others or contain any material that could give rise
                to any civil or criminal liability under applicable laws or
                regulations or that otherwise may be in conflict with these
                Terms of Use and our Privacy Policy [LINK TO PRIVACY POLICY].
              </li>
              <li>Be likely to deceive any person.</li>
              <li>
                Promote any illegal activity, or advocate, promote, or assist
                any unlawful act.
              </li>
              <li>
                Cause annoyance, inconvenience, or needless anxiety or be likely
                to upset, embarrass, alarm, or annoy any other person.
              </li>
              <li>
                Impersonate any person, or misrepresent your identity or
                affiliation with any person or organization.
              </li>
              <li>
                Involve commercial activities or sales, such as contests,
                sweepstakes, and other sales promotions, barter, or advertising.
              </li>
              <li>
                Give the impression that they emanate from or are endorsed by us
                or any other person or entity, if this is not the case.
              </li>
            </ul>
            <p>
              <b>Reliance on Information Posted</b>
              <br />
              The information presented on or through the Website is made
              available solely for general information purposes. We do not
              warrant the accuracy, completeness, or usefulness of this
              information. Any reliance you place on such information is
              strictly at your own risk. We disclaim all liability and
              responsibility arising from any reliance placed on such materials
              by you or any other visitor to the Website, or by anyone who may
              be informed of any of its contents.
            </p>
            <p>
              This Website includes content provided by third parties, including
              materials provided by Partners, other users, bloggers, and
              third-party licensors, syndicators, aggregators, and/or reporting
              services. All statements and/or opinions expressed in these
              materials, and all articles and responses to questions and other
              content, other than the content provided by ZipLunch, are solely
              the opinions and the responsibility of the person or entity
              providing those materials. These materials do not necessarily
              reflect the opinion of ZipLunch. We are not responsible, or liable
              to you or any third party, for the content or accuracy of any
              materials provided by any third parties.
            </p>
            <p>
              <b>Changes to the Website</b>
              <br />
              We may update the content on this Website from time to time, but
              its content is not necessarily complete or up-to-date. Any of the
              material on the Website may be out of date at any given time, and
              we are under no obligation to update such material.
            </p>
            <p>
              <b>Information About You and Your Visits to the Website</b>
              <br />
              All information we collect on this Website is subject to our
              Privacy Policy. By using the Website, you consent to all actions
              taken by us with respect to your information in compliance with
              the Privacy Policy.
            </p>
            <p>
              <b>Linking to the Website and Social Media Features</b>
              <br />
              You may link to our homepage, provided you do so in a way that is
              fair and legal and does not damage our reputation or take
              advantage of it, but you must not establish a link in such a way
              as to suggest any form of association, approval, or endorsement on
              our part without our express written consent.
            </p>
            <p>
              This Website may provide certain social media features that enable
              you to:
            </p>
            <ul>
              <li>
                Link from your own or certain third-party websites to certain
                content on this Website.
              </li>
              <li>
                Send emails or other communications with certain content, or
                links to certain content, on this Website.
              </li>
              <li>
                Cause limited portions of content on this Website to be
                displayed or appear to be displayed on your own or certain
                third-party websites.
              </li>
            </ul>
            <p>
              You may use these features solely as they are provided by us and
              solely with respect to the content they are displayed with, and
              otherwise in accordance with any additional terms and conditions
              we provide with respect to such features. Subject to the
              foregoing, you must not:
            </p>
            <ul>
              <li>
                Establish a link from any website that is not owned by you.
              </li>
              <li>
                Cause the Website or portions of it to be displayed on, or
                appear to be displayed by, any other site, for example, framing,
                deep linking, or in-line linking.
              </li>
              <li>Link to any part of the Website other than the homepage.</li>
              <li>
                Otherwise take any action with respect to the materials on this
                Website that is inconsistent with any other provision of these
                Terms of Use.
              </li>
            </ul>
            <p>
              You agree to cooperate with us in causing any unauthorized framing
              or linking immediately to stop. We reserve the right to withdraw
              linking permission without notice.
            </p>
            <p>
              We may disable all or any social media features and any links at
              any time without notice in our discretion.
            </p>
            <p>
              <b>Links from the Website</b>
              <br />
              If the Website contains links to other sites and resources
              provided by third parties, these links are provided for your
              convenience only. This includes links contained in advertisements,
              including banner advertisements and sponsored links. We have no
              control over the contents of those sites or resources, and accept
              no responsibility for them or for any loss or damage that may
              arise from your use of them. If you decide to access any of the
              third-party websites linked to this Website, you do so entirely at
              your own risk and subject to the terms and conditions of use for
              such websites.
            </p>
            <p>
              <b>Geographic Restrictions</b>
              <br />
              The owner of the Website is based in Toronto, Canada. We provide
              this Website for use only by persons located in North America. We
              make no claims that the Website or any of its content is
              accessible or appropriate outside of North America. Access to the
              Website may not be legal by certain persons or in certain
              countries. If you access the Website from outside North America,
              you do so on your own initiative and are responsible for
              compliance with local laws.
            </p>
            <p>
              <b>Disclaimer of Warranties</b>
              <br />
              You understand that we cannot and do not guarantee or warrant that
              files available for downloading from the internet or the Website
              will be free of viruses or other destructive code. You are
              responsible for implementing sufficient procedures and checkpoints
              to satisfy your particular requirements for anti-virus protection
              and accuracy of data input and output, and for maintaining a means
              external to our Website for any reconstruction of any lost data.
              TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR
              ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE
              ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT
              MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR
              OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY
              SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR
              DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED
              TO IT.
            </p>
            <p>
              YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
              OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
              CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
              ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
              WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER
              ZIPLUNCH NOR ANY PERSON ASSOCIATED WITH ZIPLUNCH MAKES ANY
              WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
              SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
              WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER ZIPLUNCH NOR
              ANYONE ASSOCIATED WITH ZIPLUNCH REPRESENTS OR WARRANTS THAT THE
              WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH
              THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
              UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR WEBSITE OR
              THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS
              OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR
              EXPECTATIONS.
            </p>
            <p>
              TO THE FULLEST EXTENT PROVIDED BY LAW, ZIPLUNCH HEREBY DISCLAIMS
              ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY,
              OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
              MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR
              PURPOSE.
            </p>
            <p>
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
            <p>
              <b>Limitation on Liability</b>
              <br />
              TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL ZIPLUNCH,
              ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
              AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
              UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR
              USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT,
              ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY
              DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
              DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
              SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
              LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
              GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
              NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF
              FORESEEABLE.
            </p>
            <p>
              THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
            <p>
              <b>Indemnification</b>
              <br />
              You agree to defend, indemnify, and hold harmless ZipLunch, its
              affiliates, licensors, and service providers, and its and their
              respective officers, directors, employees, contractors, agents,
              licensors, suppliers, successors, and assigns from and against any
              claims, liabilities, damages, judgments, awards, losses, costs,
              expenses, or fees (including reasonable attorneys' fees) arising
              out of or relating to your violation of these Terms of Use or your
              use of the Website, including, but not limited to, your User
              Contributions, any use of the Website's content, services, and
              products other than as expressly authorized in these Terms of Use,
              or your use of any information obtained from the Website.
            </p>
            <p>
              <b>Governing Law and Jurisdiction</b>
              <br />
              All matters relating to the Website and these Terms of Use, and
              any dispute or claim arising therefrom or related thereto (in each
              case, including non-contractual disputes or claims), shall be
              governed by and construed in accordance with the internal laws of
              the province of Ontario, Canada without giving effect to any
              choice or conflict of law provision or rule (whether of the
              province of Ontario or any other jurisdiction).
            </p>
            <p>
              Any legal suit, action, or proceeding arising out of, or related
              to, these Terms of Use or the Website shall be instituted
              exclusively in the courts of Canada, in each case located in the
              City of Toronto, province of Ontario, although we retain the right
              to bring any suit, action, or proceeding against you for breach of
              these Terms of Use in your country of residence or any other
              relevant country. You waive any and all objections to the exercise
              of jurisdiction over you by such courts and to venue in such
              courts.
            </p>
            <p>
              <b>Limitation on Time to File Claims</b>
              <br />
              ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
              RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED
              WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE,
              SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
            </p>
            <p>
              <b>Waiver and Severability</b>
              <br />
              No waiver by ZipLunch of any term or condition set out in these
              Terms of Use shall be deemed a further or continuing waiver of
              such term or condition or a waiver of any other term or condition,
              and any failure of ZipLunch to assert a right or provision under
              these Terms of Use shall not constitute a waiver of such right or
              provision.
            </p>
            <p>
              If any provision of these Terms of Use is held by a court or other
              tribunal of competent jurisdiction to be invalid, illegal, or
              unenforceable for any reason, such provision shall be eliminated
              or limited to the minimum extent such that the remaining
              provisions of the Terms of Use will continue in full force and
              effect.
            </p>
            <p>
              <b>Entire Agreement</b>
              <br />
              The Terms of Use and our Privacy Policy constitute the sole and
              entire agreement between you and ZipLunch regarding the Website
              and supersede all prior and contemporaneous understandings,
              agreements, representations, and warranties, both written and
              oral, regarding the Website.
            </p>
            <p>
              <b>Your Comments and Concerns</b>
              <br />
              This website is operated by ZipLunch, Toronto, Ontario.
              <br />
              All other feedback, comments, requests for technical support, and
              other communications relating to the Website should be directed
              to: support@ziplunch.com.
            </p>
            <br />
            <br />
            <br />
            <p>
              <b>Privacy Policy</b>
            </p>
            <p>Last modified: November 26th, 2019 </p>
            <p>
              <b>Introduction</b>
              ZipLunch Inc. (“<b>ZipLunch</b>,” “<b>us</b>,” “<b>our</b>” or “
              <b>we</b>”) respects your privacy and is committed to protecting
              it through our compliance with this policy. ZipLunch operates the
              website, www.ZipLunch.com (our “<b>Website</b>”) as well as a
              messaging platform for the efficient and convenient ordering of
              food via the Website, email, SMS, or MMS (collectively, the “
              <b>Services</b>”). This Privacy Policy informs you of our policies
              regarding the collection, use and disclosure of personal
              information we receive from users of the Website or Services.
            </p>
            <p>
              <b>This policy applies to information we collect</b>
              <br />
              On this Website and
            </p>
            <ul>
              <li>
                In email, text, and other electronic messages including
                web-based and SMS and MMS messages between you and ZipLunch.
              </li>
              <li>
                Through mobile and desktop applications you download from this
                Website, which provides dedicated non-browser-based interaction
                between you and this Website.
              </li>
              <li>
                When you interact with our advertising and applications on
                third-party websites and services, if those applications or
                advertising include links to this policy.
              </li>
            </ul>
            It does not apply to information collected by:
            <ul>
              <li>
                Through any other means, including on any other website operated
                by ZipLunch or any third party including our affiliates and
                subsidiaries; or
              </li>
              <li>
                Any third party including our affiliates and subsidiaries,
                including through any application or content (including
                advertising) that may link to or be accessible from or on the
                Website.
              </li>
            </ul>
            <p>
              Please read this policy carefully to understand our policies and
              practices regarding your information and how we will treat it. If
              you do not agree with our policies and practices, your choice is
              not to use our Website. By accessing or using this Website, you
              agree to this privacy policy. This policy may change from time to
              time (see Changes to Our Privacy Policy). Your continued use of
              this Website after we make changes is deemed to be acceptance of
              those changes, so please check the policy periodically for
              updates.
            </p>
            <p>
              Children Under the Age of 13
              <br />
              Our Website is not intended for children under 13 years of age. No
              one under age 13 may provide any personal information to or on the
              Website. We do not knowingly collect personal information from
              children under 13. If you are under 13, do not use or provide any
              information on this Website or through any of its features,
              register on the Website, make any purchases through the Website,
              use any of the interactive or public comment features of this
              Website, or provide any information about yourself to us,
              including your name, address, telephone number, email address, or
              any screen name or user name you may use. If we learn we have
              collected or received personal information from a child under 13
              without verification of parental consent, we will delete that
              information. If you believe we might have any information from or
              about a child under 13, please contact us at
            </p>
            <p>Contact Information.</p>
            <p>Information We Collect About You and How We Collect It</p>
            <p>
              We collect several types of information from and about users of
              our Website, including information:
            </p>
            <ul>
              <li>
                By which you may be personally or commercially identified, such
                as name, postal address, e-mail address, telephone number, job
                title, website, and social media URLs, or any other identifier
                by which you may be contacted online or offline (
                <b>personal information</b>);
              </li>
              <li>Payment information including credit card information;</li>
              <li>
                That is about you but individually does not identify you, such
                as an email address, dietary preferences or other information
                required to use certain features; automatically recorded times
                and dates of visits to the site; the contents of a user’s public
                comments and postings on the site; and/or
              </li>
              <li>
                About your internet connection, the equipment you use to access
                our Website, and usage details.
              </li>
            </ul>
            We collect this information:
            <ul>
              <li>Directly from you when you provide it to us.</li>
              <li>
                Automatically as you navigate through the site. Information
                collected automatically may include usage details, IP addresses,
                and information collected through cookies, web beacons, and
                other tracking technologies.
              </li>
            </ul>
            <p>Information You Provide to Us</p>
            <p>
              The information we collect on or through our Website may include:
            </p>
            <ul>
              <li>
                Information that you provide by filling in forms on our Website.
                This includes information provided at the time of registering to
                use our Website, subscribing to our service, posting material,
                or requesting further services. We may also ask you for
                information when you enter a contest or promotion sponsored by
                us, and when you report a problem with our Website.
              </li>
              <li>
                Records and copies of your correspondence (including email
                addresses), if you contact us.
              </li>
              <li>
                Your responses to surveys that we might ask you to complete for
                research purposes.
              </li>
              <li>
                Details of transactions you carry out through our Website and of
                the fulfillment of your orders. You may be required to provide
                financial information before placing an order through our
                Website.
              </li>
              <li>Your search queries on the Website.</li>
            </ul>
            <p>
              You also may provide information to us through SMS or MMS
              messages.
            </p>
            <p>
              You also may provide information to be published or displayed
              (hereinafter, <b>posted</b>) on public areas of the Website, or
              transmitted to other users of the Website or third parties
              (collectively, <b>User Contributions</b>). Your User Contributions
              are posted on and transmitted to others at your own risk. Although
              we limit access to certain pages/you may set certain privacy
              settings for such information by logging into your account
              profile, please be aware that no security measures are perfect or
              impenetrable. Additionally, we cannot control the actions of other
              users of the Website with whom you may choose to share your User
              Contributions. Therefore, we cannot and do not guarantee that your
              User Contributions will not be viewed by unauthorized persons.
            </p>
            <p>
              Information We Collect Through Automatic Data Collection
              Technologies
            </p>
            <p>
              As you navigate through and interact with our Website, we may use
              automatic data collection technologies to collect certain
              information about your equipment, browsing actions, and patterns,
              including:
            </p>
            <ul>
              <li>
                Details of your visits to our Website, including traffic data,
                location data, logs, and other communication data and the
                resources that you access and use on the Website.
              </li>
              <li>
                Information about your computer and internet connection,
                including your IP address, operating system, and browser type.
              </li>
            </ul>
            The information we collect automatically collects statistical and
            personal information. It helps us to improve our Website and to
            deliver a better and more personalized service, including by
            enabling us to:
            <ul>
              <li>Estimate our audience size and usage patterns.</li>
              <li>
                Store information about your preferences, allowing us to
                customize our Website according to your interests.
              </li>
              <li>Speed up your searches.</li>
              <li>Recognize you when you return to our Website.</li>
            </ul>
            <p>
              The technologies we use for this automatic data collection may
              include:
            </p>
            <ul>
              <li>
                <b>Cookies (or browser cookies)</b>. A cookie is a small file
                placed on the hard drive of your computer. You may refuse to
                accept browser cookies by activating the appropriate setting on
                your browser. However, if you select this setting you may be
                unable to access certain parts of our Website. Unless you have
                adjusted your browser setting so that it will refuse cookies,
                our system will issue cookies when you direct your browser to
                our Website.
              </li>
              <li>
                <b>Flash Cookies</b>. Certain features of our Website may use
                locally stored objects (or Flash cookies) to collect and store
                information about your preferences and navigation to, from, and
                on our Website. Flash cookies are not managed by the same
                browser settings as are used for browser cookies. For
                information about managing your privacy and security settings
                for Flash cookies, see Choices About How We Use and Disclose
                Your Information.
              </li>
              <li>
                <b>Web Beacons</b>. Pages of our Website and our e-mails may
                contain small electronic files known as web beacons (also
                referred to as clear gifs, pixel tags, and single-pixel gifs)
                that permit ZipLunch, for example, to count users who have
                visited those pages or opened an email and for other related
                website statistics (for example, recording the popularity of
                certain website content and verifying system and server
                integrity).
              </li>
            </ul>
            <p>Third-Party Use of Cookies and Other Tracking Technologies</p>
            <p>
              Some content or applications, including advertisements, on the
              Website are served by third-parties, including advertisers, ad
              networks and servers, content providers, and application
              providers. These third parties may use cookies alone or in
              conjunction with web beacons or other tracking technologies to
              collect information about you when you use our website. The
              information they collect may be associated with your personal
              information or they may collect information, including personal
              information, about your online activities over time and across
              different websites and other online services. They may use this
              information to provide you with interest-based (behavioral)
              advertising or other targeted content.
            </p>
            <p>
              We do not control these third parties’ tracking technologies or
              how they may be used. If you have any questions about an
              advertisement or other targeted content, you should contact the
              responsible provider directly. For information about how you can
              opt out of receiving targeted advertising from many providers, see
              Choices About How We Use and Disclose Your Information.
            </p>
            <p>Analytics</p>
            <p>
              We may use analytics services, such as Google Analytics (see
              http://www.google.com/policies/privacy/partners/ and
              https://tools.google.com/dlpage/gaoptout) to analyze how our users
              use the Website and help improve our Website and Services.
            </p>
            <p>How We Use Your Information</p>
            <p>
              We use information that we collect about you or that you provide
              to us, including any personal information:
            </p>
            <ul>
              <li>
                To present our Website and its contents and the Services to you.
              </li>
              <li>
                To provide you with information, products, or services that you
                request from us.
              </li>
              <li>To fulfill any other purpose for which you provide it.</li>
              <li>
                To provide you with notices about your account/subscription,
                including expiration and renewal notices.
              </li>
              <li>
                To carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection.
              </li>
              <li>
                To notify you about changes to our Website, Services or any
                other products or services we offer or provide through the
                Website.
              </li>
              <li>
                To allow you to participate in interactive features on our
                Website.
              </li>
              <li>
                In any other way we may describe when you provide the
                information.
              </li>
              <li>For any other purpose with your consent.</li>
            </ul>
            <p>
              We may also use your information to contact you about our own and
              third-parties’ goods and services that may be of interest to you.
              For more information, see Choices About How We Use and Disclose
              Your Information.
            </p>
            <p>
              We may use the information we have collected from you to enable us
              to display advertisements to our advertisers’ target audiences.
              Even though we do not disclose your personal information for these
              purposes without your consent, if you click on or otherwise
              interact with an advertisement, the advertiser may assume that you
              meet its target criteria.
            </p>
            <p>Disclosure of Your Information</p>
            <p>
              We may disclose aggregated information about our users, and
              information that does not identify any individual, without
              restriction.
            </p>
            <p>
              We may disclose personal information that we collect, or you
              provide as described in this privacy policy:
            </p>
            <ul>
              <li>To our restaurant partners.</li>
              <li>To our subsidiaries and affiliates.</li>
              <li>
                To contractors, service providers, and other third parties we
                use to support our business and who are bound by contractual
                obligations to keep personal information confidential and use it
                only for the purposes for which we disclose it to them.
              </li>
              <li>
                To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of ZipLunch, Inc.’s
                assets, whether as a going concern or as part of bankruptcy,
                liquidation, or similar proceeding, in which personal
                information held by ZipLunch, Inc. about our Website users is
                among the assets transferred.
              </li>
              <li>
                To other third parties to market their products or services to
                you if you have consented to these disclosures. We contractually
                require these third parties to keep personal information
                confidential and use it only for the purposes for which we
                disclose it to them. For more information, see Choices About How
                We Use and Disclose Your Information.
              </li>
              <li>
                To fulfill the purpose for which you provide it. For example, if
                you give us an email address to use the “email a friend” feature
                of our Website, we will transmit the contents of that email and
                your email address to the recipients.
              </li>
              <li>
                For any other purpose disclosed by us when you provide the
                information.
              </li>
              <li>With your consent.</li>
            </ul>
            We may also disclose your personal information:
            <ul>
              <li>
                To comply with any court order, law, or legal process, including
                to respond to any government or regulatory request.
              </li>
              <li>
                To enforce or apply our terms of use or terms of service and
                other agreements, including for billing and collection purposes.
              </li>
              <li>
                If we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of ZipLunch, Inc., our
                customers, or others.
              </li>
            </ul>
            <p>Choices About How We Use and Disclose Your Information</p>
            <p>
              We strive to provide you with choices regarding the personal
              information you provide to us. We have created mechanisms to
              provide you with the following control over your information:
            </p>
            <ul>
              <li>
                Tracking Technologies and Advertising. You can set your browser
                to refuse all or some browser cookies or to alert you when
                cookies are being sent. To learn how you can manage your Flash
                cookie settings, visit the Flash player settings page on Adobe’s
                website. If you disable or refuse cookies, please note that some
                parts of this site may then be inaccessible or not function
                properly.
              </li>
              <li>
                Disclosure of Your Information for Third-Party Advertising. If
                you do not want us to share your personal information with
                unaffiliated or non-agent third parties for promotional
                purposes, you can opt-out by sending an email to
                support@ziplunch.com
              </li>
              <li>
                Promotional Offers from ZipLunch. If you do not wish to have
                your email address or contact information used by ZipLunch to
                promote our own or third parties’ products or services, you can
                opt-out by checking the relevant box as part of the registration
                process or by sending us an email stating your request to
                support@ZipLunch.com. If we have sent you a promotional email,
                you may send us a return email asking to be omitted from future
                email distributions. This opts out does not apply to information
                provided to ZipLunch as a result of a product purchase, warranty
                registration, product service experience or other transactions.
              </li>
            </ul>
            <p>
              We do not control third parties’ collection or use of your
              information to serve interest-based advertising. However, these
              third parties may provide you with ways to choose not to have your
              information collected or used in this way. You can opt out of
              receiving targeted ads from members of the Network Advertising
              Initiative (NAI) on the NAI’s website.
            </p>
            <p>Accessing and Correcting Your Information</p>
            <p>
              You can review and change your personal information by logging
              into the Website and visiting your account profile page.
              <br />
              You may also send us an email at support@ziplunch.com to request
              access to, correct or delete any personal information that you
              have provided to us. We cannot delete your personal information
              except by also deleting your user account. We may not accommodate
              a request to change information if we believe the change would
              violate any law or legal requirement or cause the information to
              be incorrect.
            </p>
            <p>
              If you delete your User Contributions from the Website, copies of
              your User Contributions may remain viewable in cached and archived
              pages, or might have been copied or stored by other Website users.
              Proper access and use of information provided on the Website,
              including User Contributions, is governed by our Terms of Use.
            </p>
            <p>Data Security</p>
            <p>
              We have implemented measures designed to secure your personal
              information from accidental loss and unauthorized access, use,
              alteration, and disclosure. The safety and security of your
              information also depends on you. Where we have given you (or where
              you have chosen) a password for access to certain parts of our
              Website, you are responsible for keeping this password
              confidential. We ask you not to share your password with anyone.
              We urge you to be careful about giving out information in public
              areas of the Website like message boards. The information you
              share in public areas may be viewed by any user of the Website.
              <br />
              Unfortunately, the transmission of information via the internet is
              not completely secure. Although we do our best to protect your
              personal information, we cannot guarantee the security of your
              personal information transmitted to our Website. Any transmission
              of personal information is at your own risk. We are not
              responsible for circumvention of any privacy settings or security
              measures contained on the Website.
            </p>
            <p>Changes to Our Privacy Policy</p>
            <p>
              It is our policy to post any changes we make to our privacy policy
              on this page. If we make material changes to how we treat our
              users’ personal information, we will notify you by email to the
              primary email address specified in your account and/or through a
              notice on the Website home page. The date the privacy policy was
              last revised is identified at the top of the page. You are
              responsible for ensuring we have an up-to-date active and
              deliverable email address for you, and for periodically visiting
              our Website and this privacy policy to check for any changes.
            </p>
            <p>Contact Information</p>
            <p>
              To ask questions or comment about this privacy policy and our
              privacy practices, contact us at:
              <br />
              support@ziplunch.com
            </p>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default TermsAndConditions;
