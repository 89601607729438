import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { isBrowser, isMobile } from "react-device-detect";
import UserService from "../../services/userService";
import CompanyService from "../../services/companyService";
import filestackService from "../../services/file-stackService";
import MobileHeader from "../shared/mobile_header";
import { getZlToken } from "../shared/common"

// const setting = {
//   color: {
//     theme: "#000",
//   },
//   launcher: {
//     chatLabel: {
//       "en-US": "Need Help",
//     },
//   },
//   contactForm: {
//     fields: [
//       { id: "description", prefill: { "*": "My pre-filled description" } },
//     ],
//   },
// };

class ProfilePage extends React.Component {
  constructor(props) {
    let userData = getZlToken(localStorage.ZlToken);
    super(props);
    this.state = {
      fullName: localStorage.getItem("user")
        ? userData.first_name +
        " " +
        userData.last_name
        : "",
      firstName: localStorage.getItem("user")
        ? userData.first_name
        : "",
      lastName: localStorage.getItem("user")
        ? userData.last_name
        : "",
      companyId: localStorage.getItem("user")
        ? userData.company_id
        : "",
      imageURL:
        "https://cdn.filestackcontent.com/resize=height:300/cache=expiry:8640000/G3Q48l8RSvHMH2BXH10A",
      company: "",
    };
  }

  checkLogin() {
    let userData = getZlToken(localStorage.ZlToken);
    const user = localStorage.getItem("user");
    //if (window.location.href.toString().toLowerCase().indexOf("#/profilepage") > -1) {
    if (user) {
      this.setState({
        fullName:
          userData.first_name +
          " " +
          userData.last_name,
        companyId: userData.company_id,
        firstName: userData.first_name,
        lastName: userData.last_name,
      });
      this.getCompany(userData.company_id);
    } else {
      if (
        window.location.href.toString().toLowerCase().indexOf("#/profilepage") >
        -1
      ) {
        let x = window.location.href.match(/\/(\w+)$/)[1];
        window.location.replace(`#/login?prev=${x}`);
      }
    }
    //}
  }

  getCompany = async (companyId) => {
    const getCompany = await CompanyService.get_company_details(companyId);
    this.setState({ company: getCompany.result[0] });
  };

  formatDate() {
    var date = new Date(),
      month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    let today = [year, month, day].join("-");
    localStorage.setItem("today", today);
  }

  logedOut() {
    UserService.logout();
  }

  showen = () => {
    if (this.state.showen === true) {
      return "";
    } else {
      return "hide";
    }
  };

  getPolicy = async () => {
    await filestackService.getUploadCard();
  };

  componentDidMount() {
    this.checkLogin();
    this.getPolicy();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.showen !== this.props.showen) {
      this.setState({
        showen: nextProps.showen,
      });
    }
  }

  UNSAFE_componentWillUpdate() {
    if (this.props.showen !== this.state.showen)
      this.setState({ showen: this.props.showen });
  }

  render() {
    this.formatDate();
    if (isBrowser) {
      return (
        <div className={`sidebar ${this.showen()}`}>
          <Row className="m-0">
            <Col md="9" className="black-background"></Col>
            <Col md="3" className="profile-scroll">
              <Row className="profile-header-dev m-0">
                <Col xs={2}>
                  <Link to="/dailymenu">
                    <i
                      className="fa fa-times profile-back-arrow"
                      onClick={this.props.handleClick}
                    ></i>
                  </Link>
                </Col>
                <Col xs={8}>
                  <p className="profile-header">Profile</p>
                </Col>
                <Col xs={2}></Col>
              </Row>
              <Link to="/myprofile">
                <Row
                  style={{
                    lineHeight: "2px",
                    color: "#000",
                  }}
                >
                  <Col xs={3}>
                    <i className="fas fa-user profile-user-icon"></i>
                  </Col>
                  <Col xs={7}>
                    {this.state.fullName.length > 16 ? (
                      <div className="profile-icon-name">
                        <p>{this.state.firstName}</p>
                        <p>{this.state.lastName}</p>
                      </div>
                    ) : (
                      <p className="profile-icon-name">{this.state.fullName}</p>
                    )}

                    <p>Edit Profile</p>
                  </Col>
                  <Col xs={2}>
                    <i className="fas fa-chevron-right profile-icon-arrow"></i>
                  </Col>
                </Row>
                <hr></hr>
              </Link>
              <div className="profile-container">
                <Link to="/myorders">
                  <Row>
                    <Col xs={3}>
                      <div className="myorder-icon-container">
                        <i className="fas fa-file-alt profile-icon"></i>
                      </div>
                    </Col>
                    <Col xs={7}>
                      <p className="profile-icon-name">My Orders</p>
                    </Col>
                    <Col xs={2}>
                      <i className="fas fa-chevron-right profile-icon-arrow"></i>
                    </Col>
                  </Row>
                </Link>
                <Link to="/useraddresses">
                  <Row>
                    <Col xs={3}>
                      <div className="companyname-icon-container">
                        <i className="fas fa-map-marker-alt profile-icon"></i>
                      </div>
                    </Col>
                    <Col xs={7}>
                      <p className="profile-icon-name">
                        Delivery Addresses
                      </p>
                    </Col>
                    <Col xs={2}>
                      <i className="fas fa-chevron-right profile-icon-arrow"></i>
                    </Col>
                  </Row>
                </Link>
              </div>

              <div className="profile-container">
                <Link to="/notificationsettings">
                  <Row>
                    <Col xs={3}>
                      <div className="notification-icon-container">
                        <i className="fas fa-bell notification-icon"></i>
                      </div>
                    </Col>
                    <Col xs={7}>
                      <p className="profile-icon-name">Notification Settings</p>
                    </Col>
                    <Col xs={2}>
                      <i className="fas fa-chevron-right profile-icon-arrow"></i>
                    </Col>
                  </Row>
                </Link>
                <Link to="/payment">
                  <Row>
                    <Col xs={3}>
                      <div className="payment-icon-container">
                        <i className="fas fa-credit-card payment-icon"></i>
                      </div>
                    </Col>
                    <Col xs={7}>
                      <p className="profile-icon-name">Payment method</p>
                    </Col>
                    <Col xs={2}>
                      <i className="fas fa-chevron-right profile-icon-arrow"></i>
                    </Col>
                  </Row>
                </Link>
                <Link to="/refferalcode">
                  <Row>
                    <Col xs={3}>
                      <div className="percent-icon-container">
                        <i className="fas fa-percentage profile-icon"></i>
                      </div>
                    </Col>
                    <Col xs={7}>
                      <p className="profile-icon-name">
                        50% Off Your Next Order
                      </p>
                    </Col>
                    <Col xs={2}>
                      <i className="fas fa-chevron-right profile-icon-arrow"></i>
                    </Col>
                  </Row>
                </Link>
              </div>

              <div className="profile-container">
                <Link to="/help">
                  <Row>
                    <Col xs={3}>
                      <div className="help-icon-container">
                        <i className="fas fa-question-circle"></i>
                      </div>
                    </Col>
                    <Col xs={7}>
                      <p className="profile-icon-name">Help Center</p>
                    </Col>
                    <Col xs={2}>
                      <i className="fas fa-chevron-right profile-icon-arrow"></i>
                    </Col>
                  </Row>
                </Link>
              </div>

              <div className="profile-container">
                <Link to="/about_us">
                  <Row>
                    <Col xs={3}>
                      <div className="about-icon-container">
                        <i className="fas fa-exclamation-circle help-icon"></i>
                      </div>
                    </Col>
                    <Col xs={7}>
                      <p className="profile-icon-name">About us</p>
                    </Col>
                    <Col xs={2}>
                      <i className="fas fa-chevron-right profile-icon-arrow"></i>
                    </Col>
                  </Row>
                </Link>
              </div>
              <div className="profile-container">
                <div onClick={this.logedOut}>
                  <Link to="/">
                    <Row>
                      <Col xs={3}>
                        <div className="logout-icon-container">
                          <i className="fas fa-sign-out-alt help-icon"></i>
                        </div>
                      </Col>
                      <Col xs={7}>
                        <p className="profile-icon-name">Log out</p>
                      </Col>
                      <Col xs={2}>
                        <i className="fas fa-chevron-right profile-icon-arrow"></i>
                      </Col>
                    </Row>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    if (isMobile) {
      return (
        <div>
          <MobileHeader title="Profile" link="/dailymenu" />
          <Link to="/myprofile">
            <Row
              style={{
                lineHeight: "2px",
                color: "#000",
                padding: "10px",
              }}
            >
              <Col xs={3}>
                <i className="fas fa-user profile-user-icon"></i>
              </Col>
              <Col xs={7}>
                {this.state.fullName.length > 16 ? (
                  <div
                    className="profile-icon-name"
                    style={{ fontWeight: "600" }}
                  >
                    <p>{this.state.firstName}</p>
                    <p>{this.state.lastName}</p>
                  </div>
                ) : (
                  <p
                    className="profile-icon-name"
                    style={{ fontWeight: "600" }}
                  >
                    {this.state.fullName}
                  </p>
                )}
                <p>Edit Profile</p>
              </Col>

              <Col xs={2}>
                <i className="fas fa-chevron-right profile-icon-arrow"></i>
              </Col>
            </Row>
            <hr></hr>
          </Link>
          <div className="profile-container">
            <Link to="/myorders">
              <Row className="m-0">
                <Col xs={3}>
                  <div className="myorder-icon-container">
                    <i className="fas fa-file-alt profile-icon"></i>
                  </div>
                </Col>
                <Col xs={7}>
                  <p className="profile-icon-name">My Orders</p>
                </Col>
                <Col xs={2}>
                  <i className="fas fa-chevron-right profile-icon-arrow"></i>
                </Col>
              </Row>
            </Link>
            <Link to="/useraddresses">
              <Row className="m-0">
                <Col xs={3}>
                  <div className="companyname-icon-container">
                    <i className="fas fa-map-marker-alt profile-icon"></i>
                  </div>
                </Col>
                <Col xs={7}>
                  <p className="profile-icon-name">
                    Delivery Addresses
                  </p>
                </Col>
                <Col xs={2}>
                  <i className="fas fa-chevron-right profile-icon-arrow"></i>
                </Col>
              </Row>
            </Link>
          </div>
          <div className="profile-container">
            <Link to="/notificationsettings">
              <Row className="m-0">
                <Col xs={3}>
                  <div className="notification-icon-container">
                    <i className="fas fa-bell notification-icon"></i>
                  </div>
                </Col>
                <Col xs={7}>
                  <p className="profile-icon-name">Notification Settings</p>
                </Col>
                <Col xs={2}>
                  <i className="fas fa-chevron-right profile-icon-arrow"></i>
                </Col>
              </Row>
            </Link>
            <Link to="/payment">
              <Row className="m-0">
                <Col xs={3}>
                  <div className="payment-icon-container">
                    <i className="fas fa-credit-card payment-icon"></i>
                  </div>
                </Col>
                <Col xs={7}>
                  <p className="profile-icon-name">Payment method</p>
                </Col>
                <Col xs={2}>
                  <i className="fas fa-chevron-right profile-icon-arrow"></i>
                </Col>
              </Row>
            </Link>

            <Link to="/refferalcode">
              <Row className="m-0">
                <Col xs={3}>
                  <div className="percent-icon-container">
                    <i className="fas fa-percentage profile-icon"></i>
                  </div>
                </Col>
                <Col xs={7}>
                  <p className="profile-icon-name">50% Off Your Next Order</p>
                </Col>
                <Col xs={2}>
                  <i className="fas fa-chevron-right profile-icon-arrow"></i>
                </Col>
              </Row>
            </Link>
          </div>

          <div className="profile-container">
            <Link to="/help">
              <Row className="m-0">
                <Col xs={3}>
                  <div className="help-icon-container">
                    <i class="fas fa-question-circle"></i>
                  </div>
                </Col>
                <Col xs={7}>
                  <p className="profile-icon-name">Help Center</p>
                </Col>
                <Col xs={2}>
                  <i className="fas fa-chevron-right profile-icon-arrow"></i>
                </Col>
              </Row>
            </Link>
          </div>

          <div className="profile-container">
            <Link to="/about_us">
              <Row className="m-0">
                <Col xs={3}>
                  <div className="about-icon-container">
                    <i className="fas fa-exclamation-circle help-icon"></i>
                  </div>
                </Col>
                <Col xs={7}>
                  <p className="profile-icon-name">About us</p>
                </Col>
                <Col xs={2}>
                  <i className="fas fa-chevron-right profile-icon-arrow"></i>
                </Col>
              </Row>
            </Link>
          </div>
          <div className="profile-container">
            <div onClick={this.logedOut}>
              <Link to="/">
                <Row className="m-0">
                  <Col xs={3}>
                    <div className="logout-icon-container">
                      <i className="fas fa-sign-out-alt help-icon"></i>
                    </div>
                  </Col>
                  <Col xs={7}>
                    <p className="profile-icon-name">Log out</p>
                  </Col>
                  <Col xs={2}>
                    <i className="fas fa-chevron-right profile-icon-arrow"></i>
                  </Col>
                </Row>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ProfilePage;
