import React, { Component } from "react";
import "./thanks_message.css";
import { Link } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { Container, Row, Col } from "reactstrap";
class Thanks extends Component {
  // constructor(props) {
  //     super(props);
  // }
  render() {
    return (
      <div className="main-thanks">
        <Container>
          <Row>
            <Col md={12}>
              <h1 className={`${isBrowser ? "message" : "mobile-message"}`}>
                Thank You for your interest in ZipLunch.
              </h1>
              {/* to do Asem: please put below text in smaller font under the main thank you message .. i think we need to add container for
                the page as in other two pages */}

              {/* to do Asem: please move all our new controls under one folder which is search address */}
              <h5>
                We now have the new address in our list and we will contact you
                once we start delivering there.
              </h5>
              <div className="search-link">
                <Link to="/searchaddress">Search for another address </Link>
              </div>
              <div className="login-page">
                Already have an account?
                <br />
                <Link to="/login">Login instead</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Thanks;
