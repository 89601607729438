import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import image from "../../assets/images/failed-icon.jpg";


class OrderFailure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error : localStorage.getItem("error")
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.props.onClose();
  }

  handleClick=()=>{
    this.toggle();
    localStorage.removeItem('error');
  }

  render() {
    return (
      <div>
        <Modal
          centered
          isOpen={true}
          toggle={this.toggle}
          className={`${this.props.className} reset-password`}
        >
          <div>
            <ModalBody id="reset-password-image-body">
              <img id="reset-password-image" src={image} alt="" />
            </ModalBody>
            <ModalBody>
              <p className="popup-body">Sorry the order wasn't successful. Please try again. ({this.state.error})</p>
              <Button color="danger" id="btn" onClick={this.handleClick}>
                Continue
              </Button>
            </ModalBody>
          </div>
        </Modal>
      </div>
    );
  }
}

export default OrderFailure;