import React, { Component } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import image from "../../assets/images/green_lock.png";

class resetPasswordPopup extends Component {
  state = {
    modal: true
  };

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  render() {
    return (
      <div>
        <Modal
          centered
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={`${this.props.className} reset-password`}
        >
          <div>
            <ModalBody id="reset-password-image-body">
              <img id="reset-password-image" src={image} alt="" />
            </ModalBody>
            <ModalBody>
              <h3 id="reset-password-popup-title">
                Your Password has been reset
              </h3>
              <p id="reset-password-popup-body">
                You'll shortly receive an email with instructions to setup a new
                password.
              </p>
              <Button color="danger" id="btn" onClick={this.toggle}>
                Done
              </Button>
            </ModalBody>
          </div>
        </Modal>
      </div>
    );
  }
}

export default resetPasswordPopup;
