import React, { Component } from "react";
import "./menu_top_message.css";
class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className={`messageParagraph ${this.props.additionalClass}`}>{this.props.messageText}</div>;
  }
}

export default Message;
