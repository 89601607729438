import React, { Component } from "react";
import Slideshow from "./slideshow";
import slide1 from "../assets/images/slider1.jpg";
import slide2 from "../assets/images/slider2.png";
import slide3 from "../assets/images/slider3.png";
import slide4 from "../assets/images/slider4.png";
import Header from "./header.jsx";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const s = {
  container: "screenW screenH dGray col p-0",
  header: "flex1 fCenter fSize2",
  main: "flex8 white",
  footer: "flex1 fCenter"
};

const slides = [slide1, slide2, slide3, slide4];
const items = [
    {
      id: 0,
      altText: "A SMART WAY TO HAVE LUNCH. ", 
      caption:
        "ZipLunch provides daily lunch option from leading restaurants in your area Free Delivery & No Markup!" 
    },
    {
      id: 1,
      altText: "DAILY ROTATING MENU ",
      caption:
        "Up to 15 different lunch options sent to you every morning. Different menu every day, based on your food preferences." 
    },
    {
      id: 2,
      altText: "PLACE YOUR ORDER ",
      caption: "Select your lunch option by 10:30 AM."
    },
    {
      id: 3,
      altText: "LUNCH ARRIVES ",
      caption:
        "We will deliver your lunch between 12:00-12:30 directly to your office's reception desk."
    }
  ];
class App extends Component {
  render() {
    return (
      <div className={s.container}>
        <Header />
        <div className={s.main}>
          <Slideshow slides={slides} items={items}/>
          <div className="home-bottom">
            <Link to="/searchaddress">
              <Button id="homeButton" color="danger">
                Order Lunch Now
              </Button>
            </Link>
            <p>
              Have an account?{" "} 
              <Link id="link-to" to="/login">
                Log in
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default App;