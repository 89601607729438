import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { GoogleApiWrapper } from 'google-maps-react';
import {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';
import backgroundImage from './../../../../assets/images/backgroundImage-dark.jpeg';
import { Container, Col, Row, FormGroup, Input } from 'reactstrap'
import './search_address.css'
import { isBrowser } from "react-device-detect";
import { Link } from "react-router-dom";

const searchOptions = {
    types: ['address'],
    componentRestrictions: {
        country: ['us', 'ca']
    }
};
const inputProps = {
    autocomplete: 'off',
}

class SearchAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            addressError: ''
        };
    }
    handleChange = address => {
        this.setState({
            address,
            addressError: ''
        });


    };
    checkLogin() {
        const user = localStorage.getItem("user");
        if (user) {
            this.props.history.push('/dailymenu')
        }
    }
    UNSAFE_componentWillMount() {
        this.checkLogin();
    }

    errorClass(error) {
        return error.length === 0 ? "" : "has-error";

    }
    componentDidMount = () => {
        document.body.style.background = `url(${backgroundImage}) no-repeat center`;
        const ref = window.location.href.split('=')[1];
        if(ref) {
            localStorage.setItem('ref', ref);
        }    
    }
    componentWillUnmount = () => {
        document.body.style.background = ``;
    }

    handleSelect = async address => {
        await geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .catch(error => console.error('Error', error));
        this.setState({ address });
        this.props.history.push({
            pathname: '/resultaddress',
            search: `?address=${encodeURIComponent(this.state.address)}`
        })
    };
    handleEnterKey = (e) => {
        if (e.keyCode === 13 && this.state.address.trim().length === 0)
            this.setState({
                addressError: 'Please select address from the suggestions'
            })

    }

    handleSearch = async (e) => {
        await this.setState({
            addressError: 'Please select address from the suggestions'
        })

    }
    render() {

        return (
            <div className="main-search">
                <Container className="themed-container" fluid={true}>
                    <div className="content-container">
                        <Row>
                            <Col md={12}>
                                <h1 className="title">Meal Ordering Simplified  </h1>
                            </Col>
                            <Col>
                                <p className={`${isBrowser ? 'description' : 'mobile-description'}`}>Enter  Your  Address  to  see  if  ZipLunch delivers to your building</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <PlacesAutocomplete
                                    value={this.state.address}
                                    onChange={this.handleChange}
                                    onSelect={this.handleSelect}
                                    searchOptions={searchOptions}
                                    inputProps={inputProps}

                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div className={`${isBrowser ? 'form-address' : 'mobile-form-address'}`}>
                                            <Input
                                                {...getInputProps({
                                                    placeholder: 'Start typing your street address',
                                                    className: `${this.errorClass(this.state.addressError)}`,
                                                })}
                                                onKeyDown={this.handleEnterKey}
                                            />
                                            <small
                                              className={this.state.address.trim().length === 0 || suggestions.length === 0
                                                ? "info-disclaimer"
                                                : "hidden-info-disclaimer"}
                                            >
                                                *Start with the street number followed by street name.
                                                Do not include your unit/suite number.
                                            </small>
                                            <Col md={12}>
                                                <div className='autocomplete-dropdown'>
                                                    {suggestions.splice(3) &&
                                                    suggestions.map(suggestion => {
                                                        const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? {
                                                                backgroundColor: 'rgb(190, 187, 187)', cursor: 'pointer', padding: '5px'

                                                            }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '5px' };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                                key={suggestion.placeId}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                                {this.state.addressError.length > 0 &&
                                    <label>{this.state.addressError}</label>
                                }
                            </Col>

                            <Col md={12}>
                                <FormGroup>
                                    <button className="search-button"
                                        onClick={this.handleSearch}
                                        onKeyDown={this.handleSearch}
                                    >
                                        Submit
                                </button>
                                </FormGroup>
                            </Col>
                            <Col md={12}  >
                                <Link to='/login' className="login-link">
                                    Login Instead
                            </Link>
                            </Col>
                        </Row>
                    </div>
                </Container >
            </div>
        )
    }
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyCde16IQkTCT_LzQmAHneEoEzUm4PRBeZg'
})(SearchAddress);