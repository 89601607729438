/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Label,
  CardBody,
  FormGroup,
  Input,
  Button,
  Row,
  Col
} from "reactstrap";
import { isBrowser, isMobile, isIOS } from "react-device-detect";
import MealHeader from "../meals/mealHeader";
import userService from "../../services/userService";
import validationService from "../../services/validationService";
import filestackService from "../../services/file-stackService";
import { getZlToken, setZlToken } from "../shared/common"
import ConfirmAccountDeletion from "../shared/confirmAccountDeletion";
import AccountDeletionSuccess from "../shared/accountDeletionSuccess";

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: "",
      policy: "",
      signature: "",
      id: "",
      firstName: "",
      lastName: "",
      fullName: "",
      nameError: "",
      nameValid: true,
      firstNameError: '',
      firstNameValid: true,
      lastNameError: '',
      lastNameValid: true,
      phone: "",
      phoneError: "",
      phoneValid: true,
      email: "",
      emailError: "",
      emailValid: true,
      emailExist: false,
      imageURL:
        "https://cdn.filestackcontent.com/resize=height:300/cache=expiry:8640000/G3Q48l8RSvHMH2BXH10A",
      editingName: false,
      editingPhone: false,
      editingEmail: false,
      confirmModal: false,
      successModal: false
    };

    this.nameEditor();
    this.editName = this.editName.bind(this);
    this.saveFirstName = this.saveFirstName.bind(this);
    this.saveLastName = this.saveLastName.bind(this);
    this.phoneEditor();
    this.editPhone = this.editPhone.bind(this);
    this.savePhone = this.savePhone.bind(this);
    this.emailEditor();
    this.editEmail = this.editEmail.bind(this);
    this.saveEmail = this.saveEmail.bind(this);
  }

  componentDidMount = async () => {
    this.checkLogin();
  };

  checkFirstNameValid = () => {
    let firstChecked = validationService.validatName(this.state.firstName);

    this.setState({
      firstNameError: firstChecked.erroeMessage,
      firstNameValid: firstChecked.nameValid
    });
  };
  checkLastNameValid = () => {
    let firstChecked = validationService.validatName(this.state.lastName);

    this.setState({
      lastNameError: firstChecked.erroeMessage,
      lastNameValid: firstChecked.nameValid
    });
  };

  checkEmailValid = () => {
    let eamilChecked = validationService.validatEmail(this.state.email);

    if (eamilChecked.emailValid === null) {
      this.setState({ emailValid: false, formValid: false });
    } else {
      this.setState({ emailValid: true });
    }
    this.setState({
      emailError: eamilChecked.erroeMessage
    });
  };

  checkEmailExist = async () => {
    let userData = getZlToken(localStorage.ZlToken);
    if (this.state.email === userData.email) {
      return;
    } else {
      const checkedEmail = await userService.check_email(this.state.email);

      if (checkedEmail.result === true) {
        this.setState({ emailExist: true });
      } else if (checkedEmail.result === false) {
        this.setState({ emailExist: false });
      }
    }
  };

  checkPhoneValid = () => {
    let phone = this.state.phone;

    if (phone[1] == 9) {
      phone = phone.substr(4, phone.length);
    } else if (phone[1] == 1) {
      phone = phone.substr(1, phone.length);
    }

    let phoneChecked = validationService.checkPhone(phone);

    this.setState({
      phoneError: phoneChecked.erroeMessage,
      phoneValid: phoneChecked.phoneValid
    });
  };

  formatPhone = e => {
    let phone = "";
    let fullNumber = "";

    if (!e) {
      phone = this.state.phone;
      phone = phone.substr(1, phone.length);
    } else {
      phone = e;
    }

    if (phone[0] == "+") {
      phone = phone.substr(1, phone.length);
    }

    if (phone[0] == 9) {
      let countryCode = phone.substr(0, 3);

      phone = phone.substr(3, phone.length);
      phone = validationService.formatPhone(phone);
      fullNumber = "+" + countryCode + " " + phone;

      this.setState({ phone: fullNumber });
    } else if (phone[0] == 1) {
      let countryCode = phone.substr(0, 1);
      phone = phone.substr(1, phone.length);
      phone = validationService.formatPhone(phone);
      fullNumber = "+" + countryCode + " " + phone;
      this.setState({ phone: fullNumber });
    }
    return fullNumber;
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    e.preventDefault();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  nameEditor() {
    this.editorName = (
      <div className="edit-name">
        <Input
          className={`form-group form-input ${this.errorClass(
            this.state.firstNameError
          )}`}
          type="text"
          id="firstName"
          defaultValue={this.state.firstName}
          onChange={this.handleChange}
          onKeyUp={this.checkFirstNameValid}
          onBlur={event => {
            this.saveFirstName(event.target.value);
          }}
          autoFocus={true}
        />
        {this.state.firstNameError && (
          <label className="errorMessage">
            {`${this.state.firstNameError}a valid first name.`}
          </label>
        )}
        <Input
          className={`form-group form-input ${this.errorClass(
            this.state.lastNameError
          )}`}
          type="text"
          id="lastName"
          defaultValue={this.state.lastName}
          onChange={this.handleChange}
          onKeyUp={this.checkLastNameValid}
          onBlur={event => {
            this.saveLastName(event.target.value);
          }}
        />
        {this.state.lastNameError && (
          <label className="errorMessage">
            {`${this.state.lastNameError}a valid last name.`}
          </label>
        )}
      </div>

    );
  }

  phoneEditor() {
    this.editorPhone = (
      <Input
        className={`form-group form-input ${this.errorClass(
          this.state.phoneError
        )}`}
        type="phone"
        id="phone"
        defaultValue={this.state.phone}
        onChange={this.handleChange}
        onKeyUp={this.checkPhoneValid}
        onBlur={event => {
          let phone = this.formatPhone(event.target.value);
          this.savePhone(phone);
        }}
        autoFocus={true}
      />
    );
  }

  emailEditor() {
    this.editorEmail = (
      <Input
        className={`form-group form-input ${this.errorClass(
          this.state.emailError
        )}`}
        id="email"
        type="email"
        valid={this.value}
        onChange={this.handleChange}
        defaultValue={this.state.email}
        onKeyUp={this.checkEmailValid}
        onBlur={event => {
          this.saveEmail(event.target.value);
        }}
        autoFocus={true}
      />
    );
  }

  editName() {
    this.setState({
      fullName: this.state.fullName,
      editingName: true
    });
  }

  editPhone() {
    this.setState({
      phone: this.state.phone,
      editingPhone: true
    });
  }

  editEmail() {
    this.setState({
      email: this.state.email,
      editingEmail: true
    });
  }

  saveFirstName(value) {
    this.setState({
      firstName: value,
      fullName: this.state.firstName + " " + this.state.lastName,
      editingName: true
    });
  }

  saveLastName(value) {
    this.setState({
      lastName: value,
      fullName: this.state.firstName + " " + this.state.lastName,
      editingName: false
    });
  }

  savePhone(value) {
    this.setState({
      phone: value,
      editingPhone: false
    });
  }

  saveEmail(value) {
    this.checkEmailExist();
    this.setState({
      email: value,
      editingEmail: false
    });
  }

  componentDidUpdate() {
    this.nameEditor();
    this.emailEditor();
    this.phoneEditor();
  }

  checkAllFieldsValidations = () => {
    this.checkFirstNameValid();
    this.checkLastNameValid();
    this.checkEmailValid();
    this.checkPhoneValid();
  };

  handleSubmit = async e => {
    e.preventDefault();

    if (
      !(this.state.firstNameValid && this.state.lastNameValid && this.state.emailValid && this.state.phoneValid)
    ) {
      this.checkAllFieldsValidations();
      return;
    }

    // let name = this.state.fullName;
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let phone = validationService.replacePhone(this.state.phone);
    phone = "+" + phone;

    const profileEdited = await userService.edit_profile(
      firstName,
      lastName,
      this.state.email,
      phone
    );
    const newData = getZlToken(localStorage.ZlToken);
    if (profileEdited.data.result.message === "Success") {
      newData.accessToken = profileEdited.headers.authorization;
      newData.first_name = firstName;
      newData.last_name = lastName;
      newData.email = this.state.email;
      newData.phone = this.state.phone;
      if (isMobile) {
        this.props.history.push("/profilepage");
      }
      if (isBrowser) {
        this.props.history.push("/dailymenu");
      }
    }

    if (profileEdited.data.result.phone_changed) {

      newData.phone_verified = false
      this.props.history.push("/verificationcode");
    }
    await setZlToken(newData);
  };

  checkLogin = () => {
    let userData = getZlToken(localStorage.ZlToken);
    const user = localStorage.getItem("user");
    if (user) {
      this.setState({
        id: userData.id,
        fullName:
          userData.first_name +
          " " +
          userData.last_name,
        firstName: userData.first_name,
        lastName: userData.last_name,
        phone: userData.phone,
        email: userData.email
      });
    } else {
      let x = window.location.href.match(/\/(\w+)$/)[1];
      window.location.replace(`#/login?prev=${x}`);
    }
  };

  getPolicy = async () => {
    await filestackService.getUploadCard();
  };

  UNSAFE_componentWillMount = async () => {
    this.checkLogin();
    this.getPolicy();
    this.formatPhone();
    await filestackService.getUploadCard();
  };

  toggleDeletionModal = (e) => {
    this.setState((prevState) => ({ confirmModal: !prevState.confirmModal }))
  }
  toggleSuccessDeletionModal = () => {
    this.setState((prevState) => ({ successModal: !prevState.successModal }))
  }

  render() {
    if (isMobile) {
      return (
        <div>
          <Row
            className={
              isIOS
                ? "ios-safe-padding m-0 ios-header-backgroung"
                : "profile-header-dev m-0"
            }
          >
            <Col xs={2}>
              <Link to="/profilepage">
                <i className="fa fa-arrow-left profile-back-arrow"></i>
              </Link>
            </Col>
            <Col xs={8}>
              <p className="profile-header">Edit Profile</p>
            </Col>
            <Col xs={2} className="p-0">
              <Link to="/profilepage">
                <p className="myprofile-done" onClick={this.handleSubmit}>
                  Done
                </p>
              </Link>
            </Col>
          </Row>
          <div className="my-profile-header">
            <div>
              <Card className="myprofile-card">
                <CardBody>
                  <form onSubmit={this.handleSubmit.bind(this)}>
                    <FormGroup row className="my-border-bottom">
                      <Label xs={3}>Name</Label>
                      {this.state.editingName ? (
                        this.editorName
                      ) : (
                        <Label xs={9} onClick={this.editName}>
                          {this.state.fullName}
                        </Label>
                      )}

                    </FormGroup>
                    <FormGroup row className="my-border-bottom">
                      <Label xs={3}>Email</Label>
                      {this.state.editingEmail ? (
                        this.editorEmail
                      ) : (
                        <Label xs={9} onClick={this.editEmail}>
                          {this.state.email}
                        </Label>
                      )}
                      {this.state.emailError.length > 0 && (
                        <label className="errorMessage">
                          {this.state.emailError}
                        </label>
                      )}
                      {this.state.emailExist && (
                        <label className="errorMessage">
                          Email already exists.
                        </label>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label xs={3}>Phone</Label>
                      {this.state.editingPhone ? (
                        this.editorPhone
                      ) : (
                        <Label xs={9} onClick={this.editPhone}>
                          {this.state.phone}
                        </Label>
                      )}
                      {this.state.phoneError.length > 0 && (
                        <label className="errorMessagePhone">
                          {this.state.phoneError}
                        </label>
                      )}
                    </FormGroup>
                    <Link to="./ChangePassword">
                      <p className="change-password-link">Change password</p>
                    </Link>
                  </form>
                </CardBody>
              </Card>
              <Link to={'#'} onClick={() => this.setState({ confirmModal: true })}>
                <p className="change-password-link ml-4 mt-3">Request account deletion</p>
              </Link>
              <ConfirmAccountDeletion toggle={this.toggleDeletionModal} toggleSuccess={this.toggleSuccessDeletionModal} confirmModal={this.state.confirmModal} />
              <AccountDeletionSuccess toggle={this.toggleSuccessDeletionModal} successModal={this.state.successModal} />

            </div>
          </div>
        </div>
      );
    }
    if (isBrowser) {
      return (
        <div>
          <MealHeader imageURL={this.state.imageURL} />
          <div className="my-profile-header">
            <div>
              <Card className="myprofile-card">
                <CardBody>
                  <form onSubmit={this.handleSubmit.bind(this)}>
                    <FormGroup row className="my-border-bottom">
                      <Label xs={3}>Name</Label>
                      {this.state.editingName ? (
                        this.editorName
                      ) : (
                        <Label xs={9} onClick={this.editName}>
                          {this.state.fullName}
                        </Label>
                      )}
                    </FormGroup>
                    <FormGroup row className="my-border-bottom">
                      <Label xs={3}>Email</Label>
                      {this.state.editingEmail ? (
                        this.editorEmail
                      ) : (
                        <Label xs={9} onClick={this.editEmail}>
                          {this.state.email}
                        </Label>
                      )}
                      {this.state.emailError.length > 0 && (
                        <label className="errorMessage">
                          {this.state.emailError}
                        </label>
                      )}
                      {this.state.emailExist && (
                        <label className="errorMessage">
                          Email already exists.
                        </label>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label xs={3}>Phone</Label>
                      {this.state.editingPhone ? (
                        this.editorPhone
                      ) : (
                        <Label xs={9} onClick={this.editPhone}>
                          {this.state.phone}
                        </Label>
                      )}
                      {this.state.phoneError.length > 0 && (
                        <label className="errorMessagePhone">
                          {this.state.phoneError}
                        </label>
                      )}
                    </FormGroup>
                    <Link to="./ChangePassword">
                      <p className="change-password-link">Change password</p>
                    </Link>
                    <FormGroup className="myprofile-btn">
                      <Button color="danger" type="submit">
                        Done
                      </Button>
                    </FormGroup>
                  </form>
                </CardBody>
              </Card>
              <Link to={'#'} onClick={() => this.setState({ confirmModal: true })}>
                <p className="change-password-link ml-4 mt-3">Request account deletion</p>
              </Link>
              <ConfirmAccountDeletion toggle={this.toggleDeletionModal} toggleSuccess={this.toggleSuccessDeletionModal} confirmModal={this.state.confirmModal} />
              <AccountDeletionSuccess toggle={this.toggleSuccessDeletionModal} successModal={this.state.successModal} />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default MyProfile;
