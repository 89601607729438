import React from "react";
import Logo from "../../assets/images/icon.png";
import mealsService from "../../services/mealsService";
import filestackService from "../../services/file-stackService";
import { withRouter } from "react-router-dom";
import httpService from "../../services/httpService";

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dayOrder: 0,
      activeOrder: null,
      orderImage: null,
      orderName: null,
      orderDelivery: null,
      deliveryTime: 0,
    };
    this.refreshIndex = -1;
    this.lastOrdersResult = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.refreshIndex !== prevProps.refreshIndex) {
      setTimeout(() => {
        this.getOrders();
      }, 500);
    } else {
      if (this.props.selectedDay !== prevProps.selectedDay) {
        this.updateBannerData();
      }
    }
  }

  componentDidMount() {
    this.getOrders();
  }

  getOrders = async () => {
    this.setState({
      activeOrder: await mealsService.get_Weekly_Orders_V2(
        this.props.weekStartDate,
        true
      ),
    });

    this.updateBannerData();
  };

  getHubMeal = async (mealId, hubId, date) => {
    const url = process.env.REACT_APP_APIURL;
    const hubMeal = await httpService.get(`${url}/hub_meals/search-criteria-hubs-meals?meal_id=${mealId}&hub_id=${hubId}&date=${date}`);
    return hubMeal
  }

  updateBannerData = async () => {
    if (!(this.state.activeOrder && this.state.activeOrder.result)) {
      return;
    }
    let activeOrders = JSON.parse(
      JSON.stringify(this.state.activeOrder.result)
    ); //clone object
    let orderOfTheDay = activeOrders.map((key, val) => {
      return (key.status_id === 0 || key.status_id === 1) &&
        key.created === this.props.selectedDay
        ? key
        : null;
    });
    orderOfTheDay = orderOfTheDay.filter(function (el) {
      return el != null;
    });

    if (orderOfTheDay.length === 1) {
      let hubMealData = await this.getHubMeal(orderOfTheDay[0].meal_id, orderOfTheDay[0].hub_id, orderOfTheDay[0].created);
      hubMealData = hubMealData.data.result[0]
      this.setState({
        orderName: orderOfTheDay[0].meal_name,
        orderImage: filestackService.getFileUrl(
          orderOfTheDay[0].meals.photo,
          true
        ),
        orderDelivery: hubMealData.delivery_time,
      });
    }
    this.setState({
      dayOrder: orderOfTheDay ? orderOfTheDay.length : 0,
    });
    let flag = orderOfTheDay.map((key, val) => {
      return key.restaurants.delivery_time;
    });
    flag = this.removeDuplicates(flag);
    this.setState({
      deliveryTime: flag,
    });
  };

  removeDuplicates = (array) => {
    return array.filter((a, b) => array.indexOf(a) === b);
  };

  redirect = () => {
    this.props.history.push("/myorders");
  };

  render() {
    return (
      <div
        className="Banner"
        style={{
          display: this.state.dayOrder === 0 ? "none" : "flex",
        }}
      >
        <div className="bannerCont" onClick={this.redirect}>
          <div className="bannerTitle">Orders Already Placed</div>
          {this.state.dayOrder === 1 ? (
            <div className="bannerInfo">
              <img src={this.state.orderImage} alt="" />
              <div className="bannerContent">
                <p className="bannerWithIcon">
                  {this.state.orderName && this.state.orderName.length > 40
                    ? this.state.orderName.substring(0, 40) + "..."
                    : this.state.orderName}
                </p>
                <p>
                  <i className="fas fa-stopwatch"></i>{" "}
                  {this.state.orderDelivery}
                </p>
              </div>
              <i className="fas fa-chevron-right navIcon"></i>
            </div>
          ) : (
            <div className="bannerInfo">
              <div className="bannerImage">
                <img src={Logo} alt="" />
              </div>
              <div className="bannerContent">
                <p>{this.state.dayOrder} Orders </p>
                <p className="bannerWithIcon">
                  <i className="fas fa-stopwatch"></i>
                  {this.state.deliveryTime && this.state.deliveryTime.length > 1
                    ? " " + this.state.deliveryTime[0]
                    : " Different Delivery Time Between Orders"}
                </p>
              </div>
              <i className="fas fa-chevron-right navIcon"></i>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Banner);
