import HubspotForm from "react-hubspot-form";
import React, { Component } from "react";
import MealHeader from "../../meals/mealHeader";
import "./help_page.css";
import UserService from "../../../services/userService";
import { getZlToken } from "../../shared/common";

/**
 * @component that let user send questions  ?
 */
class Help extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: "",
      message: "",
      showTanksMessage: null,
      validSubject: false,
      validMessage: false,
      showError: false,
      errorMessage: "",
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";

    script.addEventListener("load", () => {
      console.log("script loaded");
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "5715183",
          formId: "9a6ccac5-e835-4b25-8717-c24e3ca6f659",
          target: "#hubspot-form",
          onFormReady: (form) => {
            console.log("Form ready!");

            const emailField = form.querySelector("input[name='email']");
            let userData = getZlToken(localStorage.ZlToken);

            if (emailField) {
              emailField.value = userData.email; // Set the value of the hidden email field
            }
          },
        });
      }
    });
    document.body.appendChild(script);
  }

  render() {
    return (
      <div>
        <MealHeader title={"Help Center"} />
        <div className="gray notification-web">
          <div className="helpForm" id="hubspot-form"></div>

          {/* <HubspotForm
            portalId="5715183"
            formId="9a6ccac5-e835-4b25-8717-c24e3ca6f659"
            region="na1"
            onSubmit={() => console.log("Submit!")}
            onReady={(form) => {
              console.log("Form ready!");

              const emailField = form.querySelector("input[name='email']");
              let userData = getZlToken(localStorage.ZlToken);

              if (emailField) {
                emailField.value = userData.email;
              }
            }}
            loading={<div>Loading...</div>}
          /> */}
        </div>
      </div>
    );
  }
}

export default Help;
