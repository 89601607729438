import React from "react";
import { FormGroup, Input } from "reactstrap";
import { isBrowser, isMobile } from "react-device-detect";
import MealHeader from "../meals/mealHeader";
import image from "../../assets/images/refferal-code.png";
import userService from "../../services/userService";
import MobileHeader from "../shared/mobile_header";
import { CopyToClipboard } from "react-copy-to-clipboard";
import image2 from "../../assets/images/refferal-icon.png";

class RefferalCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refferal: "",
      copied: false
    };
  }

  shareMobile = () => {
    window.plugins.socialsharing.share(this.state.refferal);
  };

  getRefferal = async () => {
    const refferal = await userService.get_refferal();
    this.setState({ refferal });
  };

  UNSAFE_componentWillMount() {
    this.getRefferal();
  }

  render() {
    if (isMobile) {
      return (
        <div>
          <MobileHeader title="50% Discount" link="/profilepage" />
          <div className="refferal-form">
            <div className="refeeral-image-div">
              <img src={image} alt="refferal" id="refferal-image" />
            </div>
            <div>
              <p id="referall-title">Share The Love,Save Some Money!</p>
              <p id="refferal-subtitle">
                Get 50% off on your next lunch, when a friend joins and
                orders with your invite link below.
              </p>
              {window.plugins ? (
                <div               
                onClick={this.shareMobile}>
                  <i
                    className="refferal-icon"       
                  ><img src={image2} alt="upload" id="" /></i>
             <FormGroup id="refferal-code-input">
             
               <Input
                  className="form-group company-inputs-refferal "
                  id="refferal"
                  type="text"
                  value={this.state.refferal}
                  disabled
               />
                {this.state.copied && (
                <label className="errorMessage">Refferal code copied</label>
                )} 
             </FormGroup>
                </div>
              ) : (
                <div>
                <CopyToClipboard
                  text={this.state.refferal}
                  onCopy={() => this.setState({ copied: true })}
                >
                  <i className="refferal-icon"> <img src={image2} alt="upload" id="" /></i>
                </CopyToClipboard>
                   <FormGroup id="refferal-code-input">            
                     <Input
                     className="form-group company-inputs-refferal "
                     id="refferal"
                     type="text"
                     value={this.state.refferal}
                     disabled
                    />
                    {this.state.copied && (
                  <label className="errorMessage">Refferal code copied</label>
                )} 
              </FormGroup>
              </div>
              )}
            </div>
            <p id="refferal-subtitle">Share your personal invitation link</p>
          </div>
        </div>
      );
    }
    if (isBrowser) {
      return (
        <div className="notification-body m-0">
          <MealHeader title={"50% Discount"} />
          <div className="gray">
            <div className="refeeral-image-div">
              <img src={image} alt="refferal" id="refferal-image" />
            </div>
            <div>
              <p id="referall-title">Share The Love,Save Some Money!</p>
              <p id="refferal-subtitle">
                Get 50% off on your next lunch, when a friend joins and
                orders with your invite link below.
              </p>
            </div>
            <CopyToClipboard
             text={this.state.refferal}
             onCopy={() => this.setState({ copied: true })}
            >
            <FormGroup id="refferal-code-input-web">
              <CopyToClipboard
                text={this.state.refferal}
                onCopy={() => this.setState({ copied: true })}
              >
                <i className="refferal-icon-web"> <img src={image2} alt="upload" id="" /></i>
              </CopyToClipboard>
              <Input
                className="form-group company-inputs-refferal "
                onChange={({ target: { value } }) =>
                  this.setState({ refferal: value, copied: false })
                }
                id="refferal"
                type="text"
                value={this.state.refferal}
                disabled
              />
              {this.state.copied && (
                <label className="errorMessage">Refferal code copied</label>
              )}
            </FormGroup>
            </CopyToClipboard>
            <p id="refferal-subtitle">Share your personal invitation link</p>
          </div>
        </div>
      );
    }
  }
}

export default RefferalCode;
