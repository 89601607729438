import React, { Component } from "react";
import "./result_address.css";
import { Container, Col, Row, FormGroup, CustomInput, Input } from "reactstrap";

import queryString from "query-string";
import { Link } from "react-router-dom";
import companyService from "./../../../../services/companyService";
import { isBrowser } from "react-device-detect";
import validationService from "./../../../../services/validationService";
import Spinner from "./../../../shared/spinner";
import backgroundImage from "./../../../../assets/images/backgroundImage-dark.jpeg";

class ResultAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestionsCompanies: [],
      address: "",
      isAvailable: false,
      content: "",
      selectCompany: "",
      companySelectedError: "",
      companySelectedValid: "",
      companyInfo: [],
      companyName: "",
      companyNameError: "",
      companyNameValid: false,
      buildingType: "Residential",
      email: "",
      emailError: "",
      emailValid: false,
      isLoading: true,
      isBuilding: "",
      companyId: 0,
    };
    this.handleAutocomplete.bind(this);
  }

  componentDidMount = async () => {
    document.body.style.background = `url(${backgroundImage}) no-repeat center`;
    await this.checkAvailableAddress();
    this.setState({
      isLoading: false,
    });
    if (!this.state.isAvailable) {
      this.addNewAddress(
        this.state.address,
        this.state.email,
        this.state.companyName
      );
    }
  };
  UNSAFE_componentWillMount() {
    this.checkLogin();
  }

  componentWillUnmount = () => {
    document.body.style.background = ``;
  };

  addNewAddress = (address, email, company) => {
    companyService.add_new_address(address, email, company);
  };

  checkLogin() {
    const user = localStorage.getItem("user");
    if (user) {
      this.props.history.push("/dailymenu");
    }
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  checkAvailableAddress = async () => {
    const values = queryString.parse(this.props.location.search);
    const data = await companyService.get_address(values.address);
    this.setState({ address: values.address.trim() });
    if (data.length >= 1) {
      this.setState({
        content: "Great News! We deliver to your building at:",
        companyInfo: data,
        isAvailable: true,
        isBuilding: data[0].is_building,
        companyId: data[0].id,
      });
    } else {
      this.setState({
        content: "We currently do not deliver to your building at:",
      });
    }
  };

  handleAutocomplete = (e, companies) => {
    e.preventDefault();
    const value = e.target.value;
    this.setState({
      selectCompany: value,
      companySelectedError: "",
    });
    let suggestionsCompanies = [];
    if (value.length > 0 && value.indexOf(".") === -1) {
      const regex = new RegExp(`^${value}`, "i");
      suggestionsCompanies = companies
        .sort()
        .filter((ele) => regex.test(ele.name));
    }
    this.setState({ suggestionsCompanies: suggestionsCompanies });
  };

  selectCompany = async (value) => {
    await this.setState({
      selectCompany: value.name,
      companyId: value.id,
      suggestionsCompanies: [],
    });

    this.props.history.push({
      pathname: "/signup",
      search: `?company_id=${encodeURIComponent(
        this.state.companyId
      )}&address=${encodeURIComponent(this.state.address)}`,
    });
  };

  checkCompanyNameValid = () => {
    let firstNameChecked = validationService.validatName(
      this.state.companyName
    );
    this.setState({
      companyNameError: firstNameChecked.erroeMessage,
      companyNameValid: firstNameChecked.nameValid,
    });
  };

  renderSuggestionsCompanies() {
    if (this.state.suggestionsCompanies.length === 0) {
      return null;
    }
    return (
      <div className="company-list">
        {this.state.suggestionsCompanies.map((ele) => (
          <div className="company-item" onClick={() => this.selectCompany(ele)}>
            {ele.name}
          </div>
        ))}
      </div>
    );
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmitNewAddress = async (e) => {
    e.preventDefault();
    if (this.state.buildingType === "Company") {
      if (!(this.state.emailValid && this.state.companyNameValid)) {
        this.checkEmailValid();
        this.checkCompanyNameValid();
        return;
      }
    }
    if (!this.state.emailValid) {
      this.checkEmailValid();
      return;
    }
    this.addNewAddress(
      this.state.address,
      this.state.email,
      this.state.companyName
    );
    this.props.history.push("/thanks");
  };

  handleExitAddress = (e) => {
    e.preventDefault();
    if (!this.state.companySelectedValid) {
      this.checkCompanyValid();
      return;
    }
    let data = this.state.companyInfo.filter(
      (ele) => this.state.selectCompany.trim() === ele.name
    );
    if (data.length === 0) {
      this.addNewAddress(this.state.address, "", this.state.selectCompany);
      this.props.history.push({
        pathname: "/signup",
        search: `?company_id=${encodeURIComponent(
          this.state.companyId
        )}&name=${encodeURIComponent(
          this.state.selectCompany
        )}&address=${encodeURIComponent(this.state.address)}`,
      });
    } else {
      this.props.history.push({
        pathname: "/signup",
        search: `?company_id=${encodeURIComponent(
          this.state.companyId
        )}&address=${encodeURIComponent(this.state.address)}`,
      });
    }
  };
  checkCompanyValid = () => {
    let companyNameChecked = validationService.validatName(
      this.state.selectCompany
    );
    this.setState({
      companySelectedError: companyNameChecked.erroeMessage,
      companySelectedValid: companyNameChecked.nameValid,
    });
  };
  checkEmailValid = () => {
    let emailChecked = validationService.validatEmail(this.state.email);

    if (emailChecked.emailValid === null) {
      this.setState({ emailValid: false });
    } else {
      this.setState({ emailValid: true });
    }
    this.setState({
      emailError: emailChecked.erroeMessage,
    });
  };

  handleResidentialAddress = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: "/signup",
      search: `?company_id=${encodeURIComponent(
        this.state.companyId
      )}&address=${encodeURIComponent(this.state.address)}`,
    });
  };

  render() {
    return (
      <div className="main">
        {this.state.isLoading ? (
          <Spinner className="loading" />
        ) : (
          <Container fluid={true}>
            <div className="container-content">
              <Row>
                <Col md={12}>
                  <div className="title">
                    <h2>{this.state.content}</h2>
                  </div>

                  <div className="address">
                    <i className="fas fa-sm fa-map-marker-alt"></i>
                    {"  " + this.state.address}
                  </div>
                </Col>
              </Row>

              {this.state.isAvailable ? (
                <Row>
                  <Col>
                    <div className="account">
                      Create an account to get started
                    </div>

                    {!this.state.isBuilding ? (
                      <div
                        className={`${
                          isBrowser
                            ? "input-result-address"
                            : "mobile-input-result-address"
                        }`}
                      >
                        <form onSubmit={this.handleExitAddress}>
                          <Input
                            autoComplete="off"
                            list="companies"
                            name="selectCompany"
                            value={this.state.selectCompany}
                            className={`select-company ${this.errorClass(
                              this.state.companySelectedError
                            )}`}
                            placeholder="Select your company"
                            onChange={(e) =>
                              this.handleAutocomplete(
                                e,
                                this.state.companyInfo.map((ele) => {
                                  return { name: ele.name, id: ele.id };
                                })
                              )
                            }
                            onBlur={(e) => this.checkCompanyValid()}
                          />
                          <div className="container-companies">
                            {this.renderSuggestionsCompanies()}
                          </div>
                          {this.state.companySelectedError && (
                            <label className="error-message">
                              {`${this.state.companySelectedError}Business Name`}
                            </label>
                          )}

                          <div>
                            <button className="next-button" type="submit">
                              Next
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <div>
                        <form onSubmit={this.handleResidentialAddress}>
                          <div>
                            <button className="next-button" type="submit">
                              Create an account
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </Col>
                  <Col md={12}>
                    <div className="change-address">
                      <Link to={`/searchaddress`}>Or change address</Link>
                    </div>
                    <div className="already-account">
                      Already have an account?
                      <br />
                      <Link to="/login">Login instead</Link>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md={12}>
                    <div
                      className={`${
                        isBrowser ? "notify-message" : "mobile-notify-message"
                      }`}
                    >
                      Please leave your email address and we will notify you
                      once we do.
                      <br />
                      New buildings are being added daily
                    </div>
                  </Col>
                  <Col md={12}>
                    <form onSubmit={this.handleSubmitNewAddress} noValidate>
                      <FormGroup>
                        <div
                          className={`${
                            isBrowser
                              ? "input-result-address"
                              : "mobile-input-result-address "
                          }`}
                        >
                          <Input
                            className={`email ${this.errorClass(
                              this.state.emailError
                            )}`}
                            type="email"
                            name="email"
                            value={this.state.value}
                            placeholder="Enter your Email address"
                            onChange={this.handleChange}
                            onBlur={this.checkEmailValid}
                          />
                        </div>
                        {this.state.emailError.length > 0 && (
                          <label className="error-message">
                            {this.state.emailError}
                          </label>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <CustomInput
                          type="radio"
                          id="Residential"
                          name="buildingType"
                          label="Residential Address"
                          value="Residential"
                          onChange={this.handleChange}
                          checked={this.state.buildingType === "Residential"}
                        />
                      </FormGroup>
                      <FormGroup>
                        <CustomInput
                          type="radio"
                          id="Company"
                          name="buildingType"
                          label="Company Address"
                          value="Company"
                          onChange={this.handleChange}
                          checked={this.state.buildingType === "Company"}
                        />
                      </FormGroup>
                      {this.state.buildingType === "Company" ? (
                        <FormGroup>
                          <div
                            className={`${
                              isBrowser
                                ? "input-result-address"
                                : "mobile-input-result-address "
                            }`}
                          >
                            <Input
                              type="text"
                              name="companyName"
                              placeholder="Company Name"
                              className={`email ${this.errorClass(
                                this.state.companyNameError
                              )}`}
                              onChange={this.handleChange}
                              onBlur={this.checkCompanyNameValid}
                            />
                            {this.state.companyNameError.length > 0 && (
                              <label className="error-message">
                                {`${this.state.companyNameError} Business Name`}
                              </label>
                            )}
                          </div>
                        </FormGroup>
                      ) : (
                        ""
                      )}

                      <FormGroup>
                        <button className="submit-button">Submit</button>
                      </FormGroup>
                    </form>
                    <div className="address-change">
                      <Link to="/searchaddress">Change Address</Link>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </Container>
        )}
      </div>
    );
  }
}
export default ResultAddress;
