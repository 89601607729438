import React, { Component } from "react";
import userService from "../../services/userService";
import validationService from "../../services/validationService";
import { Button, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import {setZlToken} from "../shared/common";

class forgotPassword extends Component {
  state = {
    email: "",
    emailError: "",
    emailValid: false,
    emailExist: false
  };

  checkEmailValid = () => {
    let emailChecked = validationService.validatEmail(this.state.email);
    this.setState({
      emailError: emailChecked.erroeMessage,
      emailValid: emailChecked.emailValid,
      formValid: emailChecked.emailValid
    });
  };

  checkEmail = async () => {
    const checkedEmail = await userService.check_email(this.state.email);
    console.log("checkedEmail.result :",checkedEmail.result);
    if (checkedEmail.result === true) {
      this.setState({ emailExist: false });
    } else if (checkedEmail.result === false) {
      this.setState({ emailExist: true });
    }
  };

  handleChange = e => {
    this.setState({
      email: e.target.value,
      emailExist: false
    });
    e.preventDefault(e);
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  handleSubmit = async e => {
    e.preventDefault();
    if (!(this.state.emailValid && !this.state.emailExist)) {
      this.checkEmailValid();
      return;
    }

    let passwordForgot;
    try {
      passwordForgot = await userService.forgot_password(this.state.email);
    } catch (err) {
      if (isMobile && err.response.status === 400) {
        this.setState({
          emailExist: true
        })
      }
    }

    if (!passwordForgot) {
    } else if (passwordForgot.result === "success") {
      let userData ={email:this.state.email};
      await setZlToken(userData);
      this.props.history.push("/waitingpage");
    }
  };

  render() {
    if (isMobile) {
      return (
        <div>
          <div className="marTop d-none d-md-block"></div>
          <Link to="/login">
            <i className="fa fa-arrow-left" id="backArrow" />
          </Link>
          <div id="password-mob-form">
            <h3 id="password-tittle">Forgot password</h3>
            <p id="forgot-password-account">
              Please enter your email address. You will receive a link to create
              a new password via email.
            </p>
            <form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.emailError
                  )}`}
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  noValidate
                  value={this.state.value}
                  onChange={this.handleChange}
                  onKeyUp={this.checkEmailValid}
                />
                {this.state.emailError.length > 0 && (
                  <label className="errorMessage">
                    {this.state.emailError}
                  </label>
                )}
                {this.state.emailExist && (
                  <label className="errorMessage">
                    Email does not exist,{" "}
                    <Link className="log-in-insted" to="/searchaddress">
                      sign up instead
                    </Link>{" "}
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Button color="danger" id="btn" type="submit">
                  Send
                </Button>
              </FormGroup>
            </form>
          </div>
        </div>
      );
    }

    if (isBrowser) {
      return (
        <div>
          <div id="password-mob-form">
            <h3 id="password-tittle">Forgot password</h3>
            <p id="forgot-password-account">
              Please enter your email address. You will receive a link to create
              a new password via email.
            </p>
            <form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.emailError
                  )}`}
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  noValidate
                  value={this.state.value}
                  onChange={this.handleChange}
                  onKeyUp={this.checkEmailValid}
                />
                {this.state.emailError.length > 0 && (
                  <label className="errorMessage">
                    {this.state.emailError}
                  </label>
                )}
                {this.state.emailExist && (
                  <label className="errorMessage">
                    Email not exists,{" "}
                    <Link className="log-in-insted" to="/searchaddress">
                      signup instead
                    </Link>{" "}
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Button color="danger" id="btn" type="submit">
                  Send
                </Button>
              </FormGroup>
            </form>
          </div>
        </div>
      );
    }
  }
}

export default forgotPassword;