/* eslint-disable */
import React from "react";
import { ModalBody } from "reactstrap";
import MealHeader from "../meals/mealHeader";
import { isBrowser, isMobile } from "react-device-detect";
import MobileHeader from "../shared/mobile_header";
class About_us extends React.Component {
  render() {
      return (
        <div>
          {isBrowser && <MealHeader title={"About Us"} />}
          {isMobile && <MobileHeader title="About Us" link="/profilepage" />}
          <div className="gray">
            <ModalBody>
              <p>
                Our mission is to help simplify busy office professionals’ lunch
                ordering routine so they can get some time back in their day.
              </p>
              <p>
                <b>Version 1.0.35 </b> <br></br> Build 1.0.62
                <br></br>
                <b>©️ 2021 ZipLunch Inc. All rights reserved.</b>
              </p>
            </ModalBody>
          </div>
        </div>
      );
  }
}

export default About_us;