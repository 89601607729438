import React, { Component } from "react";
import { Button, FormGroup, Input } from "reactstrap";
import userService from "../../services/userService";
import validationService from "../../services/validationService";
import { isBrowser, isMobile } from "react-device-detect";
import toastService from "../../services/toast-service";
import MealHeader from "../meals/mealHeader";
import { getUserInfoByToken , getZlToken} from "../shared/common";
import { Link } from "react-router-dom";
import MobileHeader from "../shared/mobile_header";
class ChangePassword extends Component {
  state = {
    currentPassword : "",
    newPassword : "" ,
    confirmPassword : "",
    currentPasswordError : "",
    newPasswordError : "",
    confirmPasswordError : "",
    currentPasswordValid : false ,
    newPasswordValid : false ,
    confirmPasswordValid : false ,
    is_match: false ,
  };

  checkCurrentPasswordValidation = async ()=> {
    let userData =getZlToken(localStorage.getItem("ZlToken"));
    let existPassword = await userService.checkPassword(userData.id,this.state.currentPassword);
    let isExist = existPassword.data.result.isMatch;
    this.setState({
      currentPasswordError: isExist === true ? "" : "this password does not match your password",
      currentPasswordValid: isExist === true,
    });
  }

  checkNewPasswordValidation = ()=> {
    let passwordChecked = validationService.validatPassword(
      this.state.newPassword
    );
    if (this.state.newPassword === this.state.currentPassword) {
      this.setState({
        newPasswordError: "Current password match new password !!",
        newPasswordValid: false,
      });
    }else {
      this.setState({
        newPasswordError: passwordChecked.erroeMessage,
        newPasswordValid: passwordChecked.passwordValid,
      });
    }
  }

  checkConfirmPasswordValidation = ()=> {
    let passwordChecked = validationService.validatPassword(
      this.state.confirmPassword
    );
    if (this.state.confirmPassword === this.state.currentPassword) {
      this.setState({
        confirmPasswordError: "Confirm password match new password !!",
        confirmPasswordValid: false,
      });
    }else {
      this.setState({
        confirmPasswordError: passwordChecked.erroeMessage,
        confirmPasswordValid: passwordChecked.passwordValid,
      });
    }
  }

  checkLogin() {
    const user = localStorage.getItem("user");
    if (!user) {
      this.props.history.push("/login");
    }
  }

  componentDidMount = async () => {
    this.checkLogin();
  }

  handleChangeCurrentPassword = (e)=>{
    e.preventDefault();
    this.setState({
      currentPassword: e.target.value,
    },()=>{
      let passwordChecked = validationService.validatPassword( this.state.currentPassword);
      this.setState({ currentPasswordValid: passwordChecked.passwordValid,});
    })
  }

  handleChangeNewPassword = (e)=>{
    e.preventDefault();
    this.setState({
      newPassword: e.target.value,
    },()=>{
      let passwordChecked = validationService.validatPassword( this.state.newPassword);
      this.setState({ newPasswordValid: passwordChecked.passwordValid,},()=>{
        this.checkMatch();
      });
    })
  }

  handleChangeConfirmPassword = (e)=>{
    e.preventDefault();
    this.setState({
      confirmPassword: e.target.value,
    },()=>{
      let passwordChecked = validationService.validatPassword(this.state.confirmPassword);
      this.setState({ confirmPasswordValid: passwordChecked.passwordValid,},()=>{
        this.checkMatch();
      });
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let userData = await getUserInfoByToken();
    let userEmail = userData.data.result[0].email;
    let userPasswordCode = userData.data.result[0].password_code;
    let useNewPassword = this.state.newPassword;
    useNewPassword=useNewPassword.toString();
    let resetPasswordData = await userService.reset_password(userEmail,userPasswordCode,useNewPassword);
    if (resetPasswordData.result==="success") {
      toastService.success("Password changed successfully");
      this.setState({
        currentPassword : "",
        newPassword : "" ,
        confirmPassword : "",
        currentPasswordError : "",
        newPasswordError : "",
        confirmPasswordError : "",
        currentPasswordValid : false ,
        newPasswordValid : false ,
        confirmPasswordValid : false ,
        is_match: false
      })
    }
  }

  checkMatch = () => {
    if (this.state.newPassword === this.state.confirmPassword) {
      this.setState ( { is_match : true });
    }else {
      this.setState ( { is_match : false },);
    }
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  render() {
    if (isMobile) {
      return (
        <div>
          <MobileHeader title="Change Password" link="/profilepage" />
          <div className="mainwrapper">
            <Link to="profilepage">
              <i
                className="fa fa-arrow-left xs-block d-md-none"
                id="backArrow"
              ></i>
            </Link>
            <div id="signup-mobile-form">
              <h3 id="changePasswordTitle">Change Password</h3>
              <p id="changePasswordSupTitle">Please enter a new password</p>
              <form onSubmit={this.handleSubmit}>
                <FormGroup className="change-password-form">
                  <Input
                    className={`form-group form-input ${this.errorClass(
                      this.state.currentPasswordError
                    )}`}
                    type="password"
                    name="current_password"
                    id="current_password"
                    value={this.state.currentPassword}
                    placeholder="Current Password"
                    noValidate
                    onChange={this.handleChangeCurrentPassword}
                    onBlur={this.checkCurrentPasswordValidation}
                  />
                  <p>{this.state.currentPasswordError}</p>
                </FormGroup>
                <FormGroup className="change-password-form">
                  <Input
                    className={`form-group form-input ${this.errorClass(
                      this.state.newPasswordError
                    )}`}
                    type="password"
                    name="new_password"
                    id="new_password"
                    value={this.state.newPassword}
                    placeholder="New Password"
                    noValidate
                    onChange={this.handleChangeNewPassword}
                    onBlur={this.checkNewPasswordValidation}
                  />
                  <p>{this.state.newPasswordError}</p>
                </FormGroup>
                <FormGroup className="change-password-form">
                  <Input
                    className={`form-group form-input ${this.errorClass(
                      this.state.confirmPasswordError
                    )}`}
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    value={this.state.confirmPassword}
                    placeholder="Confirm Password"
                    noValidate
                    onChange={this.handleChangeConfirmPassword}
                    onBlur={this.checkConfirmPasswordValidation}
                  />
                  <p>{this.state.confirmPasswordError}</p>
                  { this.state.newPasswordValid !== false &&
                    this.state.confirmPasswordValid !== false && this.state.is_match === false
                    ?<p>{"new password not match confirm password"}</p>:""
                  }
                </FormGroup>
                {this.state.currentPasswordValid !== false && this.state.newPasswordValid !== false &&
                    this.state.confirmPasswordValid !== false && this.state.is_match !== false
                    ?
                    <FormGroup className="mb-0">
                      <Button color="danger" id="btn_addCard" type="submit"
                      className="">
                        Update
                      </Button>
                    </FormGroup>
                    :
                    <FormGroup className="mb-0 cursor-not-allowed">
                      <Button color="danger" id="btn_addCard" type="submit"
                      className="btn-disabled cancel-pointer-events">
                        Update
                      </Button>
                    </FormGroup>
                  }
              </form>
            </div>
          </div>
        </div>
      );
    }
    if (isBrowser) {
      return (
        <div>
          <MealHeader imageURL={this.state.imageURL} />
          <div className="mainwrapper">
            <Link to="dailymenu">
              <i
                className="fa fa-arrow-left xs-none d-md-block"
                id="backArrow"
              ></i>
            </Link>
            <div id="signup-mobile-form">
              <h3 id="changePasswordTitle">Change Password</h3>
              <p id="changePasswordSupTitle">Please enter a new password</p>
              <form onSubmit={this.handleSubmit}>
                <FormGroup className="change-password-form">
                  <Input
                    className={`form-group form-input ${this.errorClass(
                      this.state.currentPasswordError
                    )}`}
                    type="password"
                    name="current_password"
                    id="current_password"
                    value={this.state.currentPassword}
                    placeholder="Current Password"
                    noValidate
                    onChange={this.handleChangeCurrentPassword}
                    onBlur={this.checkCurrentPasswordValidation}
                  />
                  <p>{this.state.currentPasswordError}</p>
                </FormGroup>
                <FormGroup className="change-password-form">
                  <Input
                    className={`form-group form-input ${this.errorClass(
                      this.state.newPasswordError
                    )}`}
                    type="password"
                    name="new_password"
                    id="new_password"
                    value={this.state.newPassword}
                    placeholder="New Password"
                    noValidate
                    onChange={this.handleChangeNewPassword}
                    onBlur={this.checkNewPasswordValidation}
                  />
                  <p>{this.state.newPasswordError}</p>
                </FormGroup>
                <FormGroup className="change-password-form">
                  <Input
                    className={`form-group form-input ${this.errorClass(
                      this.state.confirmPasswordError
                    )}`}
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    value={this.state.confirmPassword}
                    placeholder="Confirm Password"
                    noValidate
                    onChange={this.handleChangeConfirmPassword}
                    onBlur={this.checkConfirmPasswordValidation}
                  />
                  <p>{this.state.confirmPasswordError}</p>
                  { this.state.newPasswordValid !== false &&
                    this.state.confirmPasswordValid !== false && this.state.is_match === false
                    ?<p>{"new password not match confirm password"}</p>:""
                  }
                </FormGroup>
                {this.state.currentPasswordValid !== false && this.state.newPasswordValid !== false &&
                    this.state.confirmPasswordValid !== false && this.state.is_match !== false
                    ?
                    <FormGroup className="mb-0">
                      <Button color="danger" id="btn_addCard" type="submit"
                      className="">
                        Update
                      </Button>
                    </FormGroup>
                    :
                    <FormGroup className="mb-0 cursor-not-allowed">
                      <Button color="danger" id="btn_addCard" type="submit"
                      className="btn-disabled cancel-pointer-events">
                        Update
                      </Button>
                    </FormGroup>
                  }
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ChangePassword;