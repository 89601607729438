import React, { Component } from "react";
import { Navbar } from "reactstrap";

class Header extends Component {
  render() {
    return (
      <div>
        <Navbar light expand="md" className="home-header">
          <img
            id="navbar-image"
            alt="headerLogo"
            src="https://ziplunch.com/wordpress/wp-content/themes/twentyseventeen-child/assets/images/logo.png"
          />
        </Navbar>
      </div>
    );
  }
}
export default Header;
