import httpService from "./httpService";

const url = process.env.REACT_APP_APIURL;

const cardKey = "downloadCard";

const getUploadCard = async () => {
  /*const response = await httpService.get(`${url}/getUploadPolicy`);
  const card = response.data;
  localStorage.setItem("downloadCard", JSON.stringify(card));*/
};

const fillDownloadCard = async () => {
  const response = await httpService.get(`${url}/getDownloadPolicy`);
  const card = response.data;
  localStorage.setItem(cardKey, JSON.stringify(card));
};

// const checkDownloadCardExpiry = () => {
//   // this function used to refresh  download card before 7 days of its expiry
//   const card = JSON.parse(localStorage.getItem(cardKey));
//   if (card) return;
//   if (!card) return fillDownloadCard();

//   const policy = JSON.parse(atob(card.policy));

//   //add 7 days
//   const dateStamp = (new Date().getTime() + 7 * 24 * 60 * 60 * 1000) / 1000;
//   if (dateStamp >= policy.expiry) fillDownloadCard();
// };
const getFileUrl = (url, isCrop, isDesktop = false) => {
  const imageUrl = url.split("filestackcontent.com");

  if (isDesktop) {
    return `${imageUrl[0]
      }filestackcontent.com/resize=height:165/crop=dim:[10,0,300,300]/cache=expiry:${8640000}${imageUrl[1]
      }`;
  }

  if (isCrop) {
    return `${
      imageUrl[0]
    }filestackcontent.com/resize=height:300/crop=dim:[75,0,300,300]/cache=expiry:${8640000}${
      imageUrl[1]
    }`;
  } else {
    return `${
      imageUrl[0]
    }filestackcontent.com/resize=height:300/cache=expiry:${8640000}${
      imageUrl[1]
    }`;
  }
};
export default {
  getUploadCard,
  fillDownloadCard,
  getFileUrl
};
