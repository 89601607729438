import { getZlTokenWithError, setZlToken } from '../components/shared/common';
import userService from './userService';

import { promisify } from 'util';

const jwt = require('jsonwebtoken');
const key = process.env.REACT_APP_SECRET_KEY;

const verify = promisify(jwt.verify);

export const handleMissingToken = async () => {
  try {
    await getZlTokenWithError(localStorage.getItem("ZlToken"))
  } catch (error) {
    if (error.message !== "invalid signature") {
      try {
        const userToken = localStorage.getItem("user");
        const parsedToken = JSON.parse(userToken).accessToken;
        const decoded = await verify(parsedToken, key);
        const user = await userService.getUserById(decoded.id).then(data => data.data.result[0]);
        await setZlToken(user)
        window.location.replace(`#/dailymenu`);
      }
      catch (e) {
        return clearStorage();
      }
    }
    else return clearStorage();
  }
  return true;
}

const clearStorage = () => {
  localStorage.clear();
  window.location.replace(`#/login`);
  return false;
}
