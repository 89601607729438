/* eslint-disable */
import React from "react";
import { isBrowser, isMobile } from "react-device-detect";
import {
  Modal,
  ModalBody,
  Button,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import creditCardService from "../../services/creditCardService";
import { Link } from "react-router-dom";
import MobileHeader from "../shared/mobile_header";
import MealHeader from "../meals/mealHeader";
import { checkCutOffTime } from "../shared/common";
import mealsService from "../../services/mealsService";
import ErrorMobile from "../../components/shared/errorMobile";
import * as yup from 'yup';
import { getZlToken } from "../shared/common";
class AddCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backTo: "",
      modal: false,
      cardHolder: "",
      cardNumber: "",
      cardExpMMYY: "",
      cardCCV: "",
      cardHolderError: "",
      cardNumberError: "",
      cardExpMMYYError: "",
      cardCCVError: "",
      cardHolderValid: false,
      cardNumberValid: false,
      cardExpMMYYValid: false,
      cardCCVValid: false,
      mealId: localStorage.getItem("mealId")
        ? localStorage.getItem("mealId")
        : null,
    };
    this.toggle = this.toggle.bind(this);
  }

  // by monther
  // schema for form validation
  schema = yup.object().shape({
    cardHolder: yup.string()
      .required("Please enter your Card Name")
      .matches(/^[-a-zA-Z' ]{2,26}$/, "Please enter a valid Card Name"),
    cardNumber: yup.string()
      .required("Please enter your Card Number")
      .matches(/^[0-9]{13,19}$/, "Please enter a valid Card Number"),
    cardExpMMYY: yup.string()
      .required("Please enter a valid MMYY")
      .matches(/^(0[1-9]|1[0-2])?([0-9]{2})$/, "Please enter a valid MMYY"),
    cardCCV: yup.string()
      .required("Please enter a valid ccv")
      .matches(/^[0-9]{3,4}$/, "Please enter a valid ccv"),
  });

  // by monther
  // validation for card data
  validateInputProperty = (data, name) => {
    try {
      this.schema.validateSyncAt(
        name,
        { [name]: data[name] },
        { context: { ...data, componentProps: this.props } }
      );
      return { errorMass: "", valid: true };
    } catch (error) {
      return { errorMass: error.errors[0], valid: false };
    }
  };

  componentDidMount() {
    if (this.state.mealId === null || this.state.mealId === "null")
      this.setState({ backTo: "/payment" });
    else this.setState({ backTo: "/dailymenu" });
  }

  checkCardHolderValid = () => {
    const error = this.validateInputProperty({ cardHolder: this.state.cardHolder }, "cardHolder");
    this.setState({
      cardHolderError: error.errorMass,
      cardHolderValid: error.valid,
    });
  };

  checkCardNumberValid = () => {
    const error = this.validateInputProperty({ cardNumber: this.state.cardNumber }, "cardNumber");
    this.setState({
      cardNumberError: error.errorMass,
      cardNumberValid: error.valid,
    });
  };

  checkCardExpMMYYValid = () => {
    const error = this.validateInputProperty({ cardExpMMYY: this.state.cardExpMMYY }, "cardExpMMYY");
    this.setState({
      cardExpMMYYError: error.errorMass,
      cardExpMMYYValid: error.valid,
    });
  };

  checkCardCCVValid = () => {
    const error = this.validateInputProperty({ cardCCV: this.state.cardCCV }, "cardCCV");
    this.setState({
      cardCCVError: error.errorMass,
      cardCCVValid: error.valid,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    }, () => {
      const error = this.validateInputProperty({ cardHolder: this.state.cardHolder }, "cardHolder");
      this.setState({ cardHolderValid: error.valid });
    });
    e.preventDefault();
  };

  handleCardNumberChange = (e) => {
    if (e.keyCode === 8) {
    }
    if (e.target.value.length > 16) return;
    else {
      this.setState({
        [e.target.id]: e.target.value,
      }, () => {
        const error = this.validateInputProperty({ cardNumber: this.state.cardNumber }, "cardNumber");
        this.setState({ cardNumberValid: error.valid });
      });
      e.preventDefault();
    }
  };

  handleCardCCVChange = (e) => {
    if (onkeydown === 8) {
    }

    if (e.target.value.length > 4) return;
    else {
      this.setState({
        [e.target.id]: e.target.value,
      }, () => {
        const error = this.validateInputProperty({ cardCCV: this.state.cardCCV }, "cardCCV");
        this.setState({ cardCCVValid: error.valid });
      });
      e.preventDefault();
    }
  };

  handleMonthYearChange = (e) => {
    if (onkeydown === 8) {
    }
    if (e.target.value.length > 4) return;
    else {
      this.setState({
        [e.target.id]: e.target.value,
      }, () => {
        const error = this.validateInputProperty({ cardExpMMYY: this.state.cardExpMMYY }, "cardExpMMYY");
        this.setState({ cardExpMMYYValid: error.valid });
      });
      e.preventDefault();
    }
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  checkPhoneNumber() {
    let newData = getZlToken(localStorage.getItem("ZlToken"));
    if (localStorage.user && newData.phone === null) {
      return true;
    } else return false
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    // by monther
    // checking cutoff time
    if (this.state.backTo === "/dailymenu") {
      if (await checkCutOffTime(this.state.mealId)) {
        this.toggle();
        this.props.history.push({
          pathname: "/dailymenu",
        });
        return;
      }
    }

    const month = this.state.cardExpMMYY[0] + this.state.cardExpMMYY[1];
    const year = this.state.cardExpMMYY[2] + this.state.cardExpMMYY[3];

    let isCardValid = false;
    try {
      const x = await creditCardService.add_card(
        this.state.cardHolder,
        this.state.cardNumber,
        month,
        year,
        this.state.cardCCV
      );
    } catch (error) {
      isCardValid = true;
    }

    this.setState({
      cardHolder: "",
      cardNumber: "",
      cardExpMMYY: "",
      cardCCV: "",
    });

    this.toggle();
    // this.props.getCards();
    if (isCardValid === true) {
      this.setState({
        cardHolderError: false,
        cardNumberError: false,
        cardExpMMYYError: false,
        cardCCVValid: false,
        cardHolderError: "",
        cardNumberError: "",
        cardExpMMYYError: "",
        cardCCVError: "",
      });
    } else {
      if (this.state.mealId == "null" || this.state.mealId == null) {
        this.props.history.push("/payment");
      } else {
        if (this.checkPhoneNumber()) {
          this.props.history.push({ pathname: "/verifyphone" });
        } else this.props.history.push({ pathname: "/dailymenu" });
      }
    }
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newModal !== this.props.newModal) {
      this.setState({ newModal: nextProps.newModal });
      this.toggle();
    }
  }
  render() {
    if (isBrowser) {
      return (
        <div>
          <Modal
            centered
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={`${this.props.className} reset-password`}
          />
          <div>
            <MobileHeader title="Add a credit card" link={this.state.backTo} />
            <form
              onSubmit={this.handleSubmit}
              style={{ width: "600px", margin: "auto" }}
            >
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input2 ${this.errorClass(
                    this.state.cardHolderError
                  )}`}
                  type="text"
                  name="cardHolder"
                  id="cardHolder"
                  placeholder="Name on Card"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.cardHolder}
                  onBlur={this.checkCardHolderValid}
                />
                <p>{this.state.cardHolderError}</p>
              </FormGroup>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input2  ${this.errorClass(
                    this.state.cardNumberError
                  )}`}
                  type="number"
                  name="cardNumber"
                  id="cardNumber"
                  placeholder="Card Number"
                  noValidate
                  onChange={this.handleCardNumberChange}
                  value={this.state.cardNumber}
                  onBlur={this.checkCardNumberValid}
                />
                <p>{this.state.cardNumberError}</p>
              </FormGroup>
              <Row form>
                <Col xs={6}>
                  <FormGroup>
                    <Input
                      className={`form-group form-input2 ${this.errorClass(
                        this.state.cardExpMMYYError
                      )}`}
                      type="number"
                      name="cardExpMMYY"
                      id="cardExpMMYY"
                      placeholder="MMYY"
                      noValidate
                      onChange={this.handleMonthYearChange}
                      value={this.state.cardExpMMYY}
                      onBlur={this.checkCardExpMMYYValid}
                    />
                    <p>{this.state.cardExpMMYYError}</p>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Input
                      className={`form-group form-input2 ${this.errorClass(
                        this.state.cardCCVError
                      )}`}
                      pattern="[0-9]*"
                      inputMode="numeric"
                      type="password"
                      name="cardCCV"
                      id="cardCCV"
                      placeholder="CVV"
                      noValidate
                      onChange={this.handleCardCCVChange}
                      value={this.state.cardCCV}
                      onBlur={this.checkCardCCVValid}
                    />
                    <p>{this.state.cardCCVError}</p>
                  </FormGroup>
                </Col>
              </Row>
              {this.state.cardHolderValid !== false && this.state.cardNumberValid !== false &&
                this.state.cardExpMMYYValid !== false && this.state.cardCCVValid !== false
                ?
                <FormGroup className="mb-0">
                  <Button color="danger" id="btn_addCard" type="submit"
                    className="">
                    Add Card
                  </Button>
                </FormGroup>
                :
                <FormGroup className="mb-0 cursor-not-allowed">
                  <Button color="danger" id="btn_addCard" type="submit"
                    className="btn-disabled cancel-pointer-events">
                    Add Card
                  </Button>
                </FormGroup>
              }
            </form>
          </div>
        </div>
      );
    }
    if (isMobile) {
      return (
        <div>
          <Modal
            centered
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={`${this.props.className} reset-password`}
          />
          <div>
            <MobileHeader title="Add a credit card" link={this.state.backTo} />
            <form
              onSubmit={this.handleSubmit}
              style={{ width: "70%", margin: "auto" }}
            >
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input2 ${this.errorClass(
                    this.state.cardHolderError
                  )}`}
                  type="text"
                  name="cardHolder"
                  id="cardHolder"
                  placeholder="Name on Card"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.value}
                  onBlur={this.checkCardHolderValid}
                />
              </FormGroup>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input  ${this.errorClass(
                    this.state.cardNumberError
                  )}`}
                  type="number"
                  name="cardNumber"
                  id="cardNumber"
                  placeholder="Card Number"
                  noValidate
                  onChange={this.handleCardNumberChange}
                  value={this.state.cardNumber}
                  onBlur={this.checkCardNumberValid}
                />
              </FormGroup>
              <Row form>
                <Col xs={6}>
                  <FormGroup>
                    <Input
                      className={`form-group form-input ${this.errorClass(
                        this.state.cardExpMMYYError
                      )}`}
                      type="number"
                      name="cardExpMMYY"
                      id="cardExpMMYY"
                      placeholder="MMYY"
                      noValidate
                      onChange={this.handleMonthYearChange}
                      value={this.state.cardExpMMYY}
                      onBlur={this.checkCardExpMMYYValid}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Input
                      className={`form-group form-input ${this.errorClass(
                        this.state.cardCCVError
                      )}`}
                      pattern="[0-9]*"
                      inputMode="numeric"
                      type="password"
                      name="cardCCV"
                      id="cardCCV"
                      placeholder="CVV"
                      noValidate
                      onChange={this.handleCardCCVChange}
                      value={this.state.cardCCV}
                      onBlur={this.checkCardCCVValid}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {this.state.cardHolderValid !== false && this.state.cardNumberValid !== false &&
                this.state.cardExpMMYYValid !== false && this.state.cardCCVValid !== false
                ?
                <FormGroup className="mb-0">
                  <Button color="danger" id="btn_addCard" type="submit"
                    className="">
                    Add Card
                  </Button>
                </FormGroup>
                :
                <FormGroup className="mb-0 cursor-not-allowed">
                  <Button color="danger" id="btn_addCard" type="submit"
                    className="btn-disabled cancel-pointer-events">
                    Add Card
                  </Button>
                </FormGroup>
              }
            </form>
          </div>
        </div>
      );
    }
  }
}

export default AddCardForm;
