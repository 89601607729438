// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./../../../../assets/images/backgroundImage-dark.jpeg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".main-thanks {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position: center center;\n    background-size: cover;\n    background-repeat: no-repeat;\n    width: 100%;\n    height: 100vh;\n    padding: 0;\n    font-family: Avenir;\n    margin: 0 auto;\n    color: white;\n    display: flex;\n    align-self: start;\n    align-items: center;\n    text-align: center;\n}\n.message {\n    margin: auto;\n    font-size: 60px;\n}\n.mobile-message {\n    margin: auto;\n    font-size: 52px;\n}\n.search-link {\n    color: #fff;\n    margin-top: 28px;\n    font-size: 17px;\n}\n.search-link a {\n    color: #fff;\n    text-decoration: underline;\n}\n.search-link a:hover {\n    color: #fff;\n}\n\n.login-page {\n    margin-top: 25px;\n    font-size: 20px;\n}\n.login-page a {\n    color: #fff;\n    text-decoration: underline;\n    font-size: 18px;\n}\n.login-page a:hover {\n    color: #fff;\n    text-decoration: underline;\n}\n", ""]);
// Exports
module.exports = exports;
