import React, { Component } from "react";
import { handleMissingToken } from "../../services/authenticationService";

export default function loginRouteAuth(WrappedComponent) {
  class Wrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        routing: false
      }
    }
    componentDidMount = () => {
      this.checkZLToken();
    };

    checkZLToken = async () => {
      const routing = await handleMissingToken();
      this.setState({
        routing
      });
    }



    render() {
      return (
        this.state.routing && <WrappedComponent {...this.props} />
      )
    }
  }
  return Wrapper
}
