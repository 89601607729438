import React, { Component } from "react";
import { Button, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import TermsAndConditions from "./terms_and_conditions.jsx";
import userService from "../../services/userService";
import validationService from "../../services/validationService";
import { isBrowser, isMobile } from "react-device-detect";
import queryString from "query-string";
import urlService from "../../services/urlService";
import { setZlToken } from "../shared/common"

class SignUp extends Component {
  state = {
    ref: "",
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    emailExist: false,
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    passwordError: "",
    firstValid: false,
    lastValid: false,
    emailValid: false,
    passwordValid: false,
    companyName: "",
    companyId: "",
    address: "",
  };

  checkFirstNameValid = () => {
    let firstNameChecked = validationService.validatName(this.state.first_name);
    this.setState({
      firstNameError: firstNameChecked.erroeMessage,
      firstValid: firstNameChecked.nameValid,
    });
  };

  checkLastNameValid = () => {
    let lastNameChecked = validationService.validatName(this.state.last_name);
    this.setState({
      lastNameError: lastNameChecked.erroeMessage,
      lastValid: lastNameChecked.nameValid,
    });
  };

  checkEmailValid = () => {
    let eamilChecked = validationService.validatEmail(this.state.email);
    if (eamilChecked.emailValid === null) {
      this.setState({ emailValid: false, formValid: false });
    } else {
      this.setState({ emailValid: true });
    }
    this.setState({
      emailError: eamilChecked.erroeMessage,
    });
  };

  checkPasswordValid = () => {
    let passwordChecked = validationService.validatPassword(
      this.state.password
    );
    this.setState({
      passwordError: passwordChecked.erroeMessage,
      passwordValid: passwordChecked.passwordValid,
    });
  };

  getRef = () => {
    const url = new URL(window.location.href);
    url.href = url.href.replace("#/", "");
    const ref =
      urlService.getParameterByName("ref") || localStorage.getItem("ref");
    this.setState({ ref: ref });
  };

  checkAllFieldsValidations = () => {
    this.checkFirstNameValid();
    this.checkLastNameValid();
    this.checkEmailValid();
    this.checkPasswordValid();
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.preventDefault();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !(
        this.state.firstValid &&
        this.state.lastValid &&
        this.state.emailValid &&
        this.state.passwordValid
      )
    ) {
      this.checkAllFieldsValidations();
      return;
    }

    let signedUp;
    if (this.state.companyName.length === 0) {
      signedUp = await userService
        .signup(
          this.state.first_name,
          this.state.last_name,
          this.state.email,
          this.state.password,
          this.state.companyId
        )
        .then((data) => data)
        .catch((error) => error);
    } else {
      let company_info = {
        name: this.state.companyName,
        sibling: this.state.companyId,
      };

      signedUp = await userService
        .signup(
          this.state.first_name,
          this.state.last_name,
          this.state.email,
          this.state.password,
          company_info
        )
        .then((data) => data)
        .catch((error) => error);
    }

    if (signedUp.status === 200) {
      this.setState({ id: signedUp.data.id });
    }

    await userService
      .login(this.state.email, this.state.password, this.state.ref)
      .then(async (data) => {
        await setZlToken(data);
        this.props.history.push("/");
        return (data);
      })
      .catch((error) => error);
  };

  checkEmail = async () => {
    const checkedEmail = await userService.check_email(this.state.email);
    if (checkedEmail.result === true) {
      this.setState({ emailExist: true });
    } else if (checkedEmail.result === false) {
      this.setState({ emailExist: false });
    }
  };

  checkLogin() {
    const user = localStorage.getItem("user");
    if (user) {
      this.props.history.push("/dailymenu");
    }
  }

  UNSAFE_componentWillMount() {
    const values = queryString.parse(this.props.location.search);

    if (values.name === undefined && values.company_id === undefined) {
      this.props.history.push("/searchaddress");
    } else {
      values.name === undefined
        ? this.setState({
          companyId: values.company_id,
          address: values.address,
        })
        : this.setState({
          companyId: values.company_id,
          companyName: values.name,
          address: values.address,
        });
    }
    this.checkLogin();
  }
  componentDidMount() {
    this.getRef();
  }

  render() {
    if (isMobile) {
      return (
        <div className="mainwrapper">
          <Link
            to={`/resultaddress?address=${encodeURIComponent(
              this.state.address
            )}`}
          >
            <i
              className="fa fa-arrow-left xs-block d-md-none"
              id="backArrow"
            ></i>
          </Link>
          <div id="signup-mobile-form">
            <h3 id="signup-title">Create an account</h3>
            <form onSubmit={this.handleSubmit}>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.firstNameError
                  )}`}
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="First Name"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.value}
                  onBlur={this.checkFirstNameValid}
                />
                {this.state.firstNameError && (
                  <label className="errorMessage">
                    {`${this.state.firstNameError}a valid first name.`}
                  </label>
                )}
              </FormGroup>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.lastNameError
                  )}`}
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Last Name"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.value}
                  onBlur={this.checkLastNameValid}
                />
                {this.state.lastNameError && (
                  <label className="errorMessage">
                    {`${this.state.lastNameError}a valid last name.`}
                  </label>
                )}
              </FormGroup>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.emailError
                  )}`}
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.value}
                  onBlur={this.checkEmail}
                  onKeyUp={this.checkEmailValid}
                />
                {this.state.emailError.length > 0 && (
                  <label className="errorMessage">
                    {this.state.emailError}
                  </label>
                )}
                {this.state.emailExist && (
                  <label className="errorMessage">
                    Email already exists,{" "}
                    <Link className="log-in-insted" to="/login">
                      login instead
                    </Link>{" "}
                  </label>
                )}
              </FormGroup>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.passwordError
                  )}`}
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.value}
                  onBlur={this.checkPasswordValid}
                />
                {this.state.passwordError && (
                  <label className="errorMessage">
                    {this.state.passwordError}
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                <Button color="danger" id="btn" type="submit">
                  Sign up
                </Button>
              </FormGroup>
            </form>
            <div className="text-center" style={{ marginTop: 20 }}>
              <p id="agree-terms">
                By clicking Sign up you agree the following
              </p>
              <TermsAndConditions />
            </div>
            <div className="logInInstead">
              Already have an account?&nbsp;
              <Link id="link-to" to="/login">
                Log In Instead
              </Link>
            </div>
          </div>
        </div>
      );
    }
    if (isBrowser) {
      return (
        <div className="mainwrapper">
          <Link
            to={`/resultaddress?address=${encodeURIComponent(
              this.state.address
            )}`}
          >
            <i
              className="fa fa-arrow-left xs-block d-md-none"
              id="backArrow"
            ></i>
          </Link>
          <div id="signup-mobile-form">
            <h3 id="signup-title">Create an account</h3>
            <form onSubmit={this.handleSubmit}>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.firstNameError
                  )}`}
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="First Name"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.value}
                  onBlur={this.checkFirstNameValid}
                />
                {this.state.firstNameError && (
                  <label className="errorMessage">
                    {`${this.state.firstNameError}a valid first name.`}
                  </label>
                )}
              </FormGroup>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.lastNameError
                  )}`}
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Last Name"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.value}
                  onBlur={this.checkLastNameValid}
                />
                {this.state.lastNameError && (
                  <label className="errorMessage">
                    {`${this.state.lastNameError}a valid last name.`}
                  </label>
                )}
              </FormGroup>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.emailError
                  )}`}
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.value}
                  onBlur={this.checkEmail}
                  onKeyUp={this.checkEmailValid}
                />
                {this.state.emailError.length > 0 && (
                  <label className="errorMessage">
                    {this.state.emailError}
                  </label>
                )}
                {this.state.emailExist && (
                  <label className="errorMessage">
                    Email already exists,{" "}
                    <Link className="log-in-insted" to="/login">
                      login instead
                    </Link>{" "}
                  </label>
                )}
              </FormGroup>
              <FormGroup className="sign-up-form">
                <Input
                  className={`form-group form-input ${this.errorClass(
                    this.state.passwordError
                  )}`}
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  noValidate
                  onChange={this.handleChange}
                  value={this.state.value}
                  onBlur={this.checkPasswordValid}
                />
                {this.state.passwordError && (
                  <label className="errorMessage">
                    {this.state.passwordError}
                  </label>
                )}
              </FormGroup>
              <FormGroup className="sign-up-form"></FormGroup>
              <FormGroup>
                <Button color="danger" id="btn" type="submit">
                  Sign up
                </Button>
              </FormGroup>
            </form>
            <div className="text-center" style={{ marginTop: 20 }}>
              <p id="agree-terms">
                By clicking Sign up you agree the following
              </p>
              <TermsAndConditions />
            </div>
            <div className="text-center" style={{ marginTop: 20 }}></div>
            <div className="text-center" style={{ marginTop: 20 }}>
              <p>
                Already have an account?&nbsp;
                <Link to="/login" id="link-to">
                  Log In Instead
                </Link>
              </p>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default SignUp;
