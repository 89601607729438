/* eslint-disable */
import React from "react";
import { isBrowser, isIOS, isMobile } from "react-device-detect";
import { Row, Col } from "reactstrap";
import CompanyService from "../../services/companyService";
import cardService from "../../services/creditCardService";
import fileStackService from "../../services/file-stackService";
import Skeleton from "react-loading-skeleton";
import mealsService from "../../services/mealsService";
import MealHeader from "./mealHeader";
import MealModals from "./meal_modals";
import Banner from "./banner";
import MenuTopMessage from "./messages/menu_top_message";
import NoMenuImage from "../../assets/images/no-menu.png";
import { getZlToken, setUserToken, setZlToken } from "../shared/common"
import AddressesDropList from "./addressesDropList";
import { handleMissingToken } from "../../services/authenticationService";
import userService from "../../services/userService";

const mealTypes = Object.freeze({
  dinner: "Dinner",
  breakfast: "Breakfast",
  lunch: "Lunch",
});

class DailyMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      week: [
        { day: "Mon", date: 8, restaurants: [] },
        { day: "The", date: 9, restaurants: [] },
        { day: "Wed", date: 10, restaurants: [] },
        { day: "Thu", date: 11, restaurants: [] },
        { day: "Fri", date: 12, restaurants: [] },
      ],
      restaurants: [],
      cards: [],
      selected_date: 0,
      floor: "",
      company_name: "",
      counter: null,
      addressId: 0,
      index: 99,
      DateFromCard: localStorage.getItem("selectedDay")
        ? localStorage.getItem("selectedDay")
        : null,
      quantity: null,
      mealSelect: null,
      newOrdersCount: 0,
      weekStartDate: "",
      messageText: "",
      isLoading: true,
    };
  }

  rerenderMealsMobile() {
    document.addEventListener(
      "resume",
      () => {
        this.setState({
          isLoading: true,
        });
        this.getMeals();
      },
      false
    );
  }

  // to update cut off time if the time exceed cut of before ordering
  updateCutOff = (data) => {
    const currentRestaurantIndex = data.restaurantIndex;
    const currentHubMealIndex = data.hubMealIndex;
    const currentHubMealId = data.hubMeal.id;
    const currentRestaurantId = data.hubMeal.restaurant_id;
    const idForResAtIndex = this.state.restaurants[currentRestaurantIndex].id;
    const idForMealAtIndex = this.state.restaurants[currentRestaurantIndex].meals[currentHubMealIndex].id;

    if (currentRestaurantId === idForResAtIndex && currentHubMealId === idForMealAtIndex) {
      let newRestaurantsState = [...this.state.restaurants];
      newRestaurantsState[currentRestaurantIndex].meal[currentHubMealIndex].cut_off = 1;
      this.setState({ restaurants: newRestaurantsState });
    } else {
      this.getMeals();
    }
  };

  getUserData = async (id) => {
    const userData = await userService.getUserById(id);
    return userData.data.result[0];
  }

  getCompany = async () => {
    if (localStorage.user) {
      const userBytoken = getZlToken(localStorage.ZlToken);
      const userData = await this.getUserData(userBytoken.id);
      const getCompany = await CompanyService.get_company_details(
        userData.company_id
      );
      if (getCompany.result[0]) {
        const user = JSON.parse(localStorage.user);
        const userToken = getZlToken(user.accessToken);
        userToken.company_id = userData.company_id;
        setUserToken(userToken)
        userBytoken.company_id = userData.company_id;
        await setZlToken(userBytoken);
        this.setState({
          floor: getCompany.result[0].floor,
          company_name: getCompany.result[0].name,
          addressId: userData.company_id
        });
      }
    }
  };

  getCards = async () => {
    const cards = await cardService.get_cards();
    this.setState({ cards: cards.result });
  };

  getPolicy = async () => {
    await fileStackService.getUploadCard();
  };

  getMessage = async () => {
    const result = await mealsService
      .get_Message()
      .then((response) => {
        this.setState({
          messageText: response.result.trim(),
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  getMeals = async () => {
    await this.getCompany()
    const mealsGet = await mealsService.get_Meals();
    if (mealsGet.result === "authentication required") {
      this.props.history.push("/login");
      localStorage.clear();
    }

    try {
      mealsGet.data.result = mealsGet.data.result.filter(
        (weekDay) =>
          weekDay.day.toLowerCase() != "sat" &&
          weekDay.day.toLowerCase() != "sun"
      );
    } catch (ex) {
      //do nothing if filter fails
    }

    let initialSelectedDate = mealsGet.data.result[0].date;
    let weekStartDate = mealsGet.data.result[0].date;

    let initialIndex = 0;
    let i = 0;
    if (this.state.selected_date == 0) {
      let dateObj = new Date();
      let currentMonth = dateObj.getMonth() + 1;
      let currentDay = dateObj.getDate();

      mealsGet.data.result.forEach((day) => {
        let d = parseInt(day.date.toString().substr(8, 2), 10);
        let m = parseInt(day.date.toString().substr(5, 2), 10);
        if (d == currentDay && currentMonth == m) {
          initialSelectedDate = day.date;
          initialIndex = i;
        }

        i++;
      });
      this.handelSelectedDay(
        initialSelectedDate,
        initialIndex,
        mealsGet.data.result,
        weekStartDate
      );
    } else {
      this.handelSelectedDay(
        this.state.selected_date,
        this.state.index,
        mealsGet.data.result,
        weekStartDate
      );
    }
  };

  handelClassName() {
    let index = this.state.index;
    return `r${index}`;
  }

  async handelSelectedDay(date, i, weekData, weekStartDate) {
    if (! await handleMissingToken()) {
      return
    }
    let week = weekData;
    if (typeof weekData === "undefined") {
      week = this.state.week;
    }
    if (
      this.state.DateFromCard !== "null" &&
      this.state.DateFromCard !== null
    ) {
      date = this.state.DateFromCard;
      i = new Date(date).getDay() - 1;

      this.setState({
        DateFromCard: null,
      });
    }

    const result = week.filter((w) => w.date == date);
    if (result[0].restaurants) {
      let newState = this.state;
      newState.restaurants = result[0].restaurants;
      newState.index = i;
      newState.selected_date = date;
      newState.week = week;
      newState.weekStartDate = weekStartDate;
      newState.isLoading = false;
      this.setState({ ...newState });
    } else {
      this.setState({
        restaurants: [],
        selected_date: date,
        index: i,
        week: week,
        weekStartDate: weekStartDate,
        isLoading: false,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  UNSAFE_componentWillMount() {
    const user = localStorage.getItem("user");
    if (!user) {
      //if not logged in redirect to login
      let x = window.location.href.match(/\/(\w+)$/)[1];
      window.location.replace(`#/login`);
    } else {
      this.rerenderMealsMobile();
      this.getMessage();
      this.getMeals();
      this.getPolicy();
      this.getCards();
      this.getCompany();
    }
  }

  componentDidMount = () => {
    let cachedSelectedDay = localStorage.getItem("selectedDay")
      ? localStorage.getItem("selectedDay")
      : null;
    let cachedMealSelected = localStorage.getItem("mealSelect")
      ? localStorage.getItem("mealSelect")
      : null;
    let cachedPrice = localStorage.getItem("newPrice")
      ? localStorage.getItem("newPrice")
      : null;
    if (cachedSelectedDay) {
      this.setState({
        DateFromCard: cachedSelectedDay,
        mealSelect: cachedMealSelected,
        newPrice: cachedPrice,
      });
    }

    this.setMealRefreshTimerOnBrowsers();
  };

  setMealRefreshTimerOnBrowsers() {
    let url = window.location.href.substr(0, 4);
    if (url === "http") {
      this.interval = setInterval(() => {
        this.getMeals();
      }, 300000);
    }
  }

  componentWillUnmount() {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  }

  onOrderSuccess = () => {
    this.getMeals();
    this.setState({
      newOrdersCount: this.state.newOrdersCount + 1,
    });
  };

  onOrderFailure = () => {
    this.getMeals();
    this.setState({
      newOrdersCount: this.state.newOrdersCount + 1, //its okay we increase even if order fails
    });
  };

  render() {
    const loadingSkeletonRestaurants = [1, 2, 3];
    const loadingSkeletonMeals = [1, 2, 3, 4, 5, 6];

    function date() {
      var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var date = new Date();
      var day = date.getDate();
      var dayName = days[date.getDay()];
      var monthName = months[date.getMonth()];
      var dateFormat = dayName + " - " + monthName + " " + day;
      return dateFormat;
    }

    function convertTime(time) {
      time = time.split(":");
      time =
        Number(time[0]) < 12
          ? (time[0] + ":" + time[1] + " AM").toString()
          : Number(time[0]) === 12
            ? (Number(time[0]) + ":" + time[1] + " PM").toString()
            : ("0" + (Number(time[0]) - 12) + ":" + time[1] + " PM").toString();
      return time;
    }

    if (isBrowser) {
      return (
        <div>
          <MealHeader leftCornerTitle="Free Delivery To" />
          <Row>
            <Col className="freeDeliveryToWeb">
              <Row className='m-3'>
                <AddressesDropList className='mx-2' addressId={this.state.addressId} getMessage={this.getMessage} getCompany={this.getCompany} getMeals={this.getMeals} />
                <p className="pl-4 pt-2">{this.state.company_name}</p>
              </Row>
            </Col>
          </Row>
          <div className="weeklyMenu weeklyMenuWeb">
            <Row className="m-0">
              {this.state.week.map((w, i) => (
                <Col
                  key={i + w.date.toString().substr(8, 2)}
                  className="pt-1"
                  onClick={() => this.handelSelectedDay(w.date, i)}
                  id={`sds-${w.date.toString().substr(5, 2)}-${w.date
                    .toString()
                    .substr(8, 2)}`}
                >
                  <span>{w.day}</span>
                  <h2>{w.date.toString().substr(8, 2)}</h2>
                </Col>
              ))}
            </Row>

            <div className={`redLabel ${this.handelClassName()}`}></div>
          </div>
          {this.state.messageText.length != 0 ? (
            <MenuTopMessage messageText={this.state.messageText} />
          ) : null}
          {/* no food now ! */}
          {this.state.restaurants.length === 0 &&
            this.state.isLoading == false && (
              <div
                style={{
                  marginTop: this.state.messageText.length != 0 ? "70px" : "",
                  textAlign: "center",
                  marginRight: "5%",
                  marginLeft: "5%",
                }}
              >
                <img
                  src={NoMenuImage}
                  style={{
                    width: 250,
                  }}
                  alt="Cooking Chef"
                />
                <p className="no-menu">
                  We're still cooking your menu!
                  <br />
                  <span
                    className="no-menu-text"
                    style={{ marginBottom: 15, maxWidth: "75%" }}
                  >
                    We are working hard with top restaurants to bring you unique
                    curated meals. Stay tuned and check here regularly
                  </span>
                  <span
                    className="no-menu-text"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    Make sure the correct delivery address is selected under
                    your profile
                  </span>
                </p>
              </div>
            )}
          {/* daily meal */}
          {this.state.isLoading &&
            this.state.restaurants.length === 0 &&
            loadingSkeletonRestaurants.map(
              (skeletonLoadingRestaurant, index) => {
                return (
                  <div
                    className="resturantDailyMealsWeb"
                    key={index}
                    style={{
                      marginBottom:
                        Number(this.state.restaurants.length) - 1 == index
                          ? "8%"
                          : "1%",
                    }}
                  >
                    <Skeleton
                      width={600}
                      height={28}
                      style={{ marginBottom: 10 }}
                    />
                    <br />
                    <Skeleton
                      width={140}
                      height={20}
                      style={{ marginBottom: 5 }}
                    />
                    <Row id="mealsCardWeb" className="mt-1">
                      {loadingSkeletonMeals.map((meal, id) => {
                        return (
                          <Col
                            key={"col-" + index + "-" + id}
                            onClick={this.toggle}
                            className="meals-card"
                            xs="12"
                            lg="4"
                          >
                            <Skeleton
                              key={index + "-" + id}
                              width={240}
                              height={80}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                );
              }
            )}
          {this.state.restaurants.length > 0 &&
            this.state.restaurants.map((resturant, restIndex) => {
              return (
                <div
                  className="resturantDailyMealsWeb"
                  key={
                    restIndex +
                    "-" +
                    this.state.selected_date +
                    "-" +
                    resturant.name
                  }
                  style={{
                    marginBottom:
                      Number(this.state.restaurants.length) - 1 == restIndex
                        ? "8%"
                        : "1%",
                  }}>
                  <h3 id="resturantNameWeb" onClick={this.getMeals}>
                    {resturant.name}
                  </h3>
                  <div>
                    {resturant.meals[0].cut_off == 0 && (
                      < p id="orderTimeWeb">
                        {`
                    ${resturant.meals[0].meal_type
                            ? `${mealTypes[resturant.meals[0].meal_type]} - `
                            : ""
                          }
                      ${resturant.meals[0].delivery_time
                            ? `Delivery From ${resturant.meals[0].delivery_time}`
                            : ""
                          }`}
                      </p>
                    )}
                    {resturant.meals[0].cut_off == 1 && (
                      < p id="orderTimeWeb">
                        {`Ordering Ended at ${resturant.meals[0].cut_off_time} ${resturant.meals[0].delivery_time
                          ? `- Delivery From ${resturant.meals[0].delivery_time}`
                          : ""
                          }`}
                      </p>
                    )}
                  </div>
                  <span className="cut-off-time">{resturant.meals[0].cut_off_time ? `Order by ${convertTime(resturant.meals[0].cut_off_time)}` : ''}</span>
                  <Row id="mealsCardWeb" className="mt-1">
                    {resturant.meals.map((hubMeal, index) => {
                      return (
                        <MealModals
                          company_name={this.state.company_name}
                          hubMeal={hubMeal}
                          key={`${this.state.selected_date}-${hubMeal.meal_id}`}
                          cards={this.state.cards}
                          floor={this.state.floor}
                          getCards={this.getCards}
                          orderSuccessHandler={this.onOrderSuccess}
                          orderFailureHandler={this.onOrderFailure}
                          history={this.props.history}
                          is_passed_cut_off_time={hubMeal.cut_off}
                          cut_off_time={convertTime(hubMeal.cut_off_time)}
                          pickupInstruction={resturant.pickup}
                          selectedDay={this.state.selected_date}
                          updateCutOff={this.updateCutOff}
                          restaurantIndex={restIndex}
                          hubMealIndex={index}
                        />
                      );
                    })}
                  </Row>
                </div>
              );
            })}
          {this.state.selected_date && (
            <Banner
              selectedDay={this.state.selected_date}
              refreshIndex={this.state.newOrdersCount}
              weekStartDate={this.state.weekStartDate}
            />
          )}
        </div>
      );
    }
    if (isMobile) {
      return (
        <div>
          <MealHeader
            additionalClass="sticky-header--main"
            iosCSSClass="ios-header-backgroung-dailymenu"
          />
          <Row>
            <Col className="freeDeliveryToWeb">
              <Row className='m-3'>
                <AddressesDropList className='mx-2' addressId={this.state.addressId} getMessage={this.getMessage} getCompany={this.getCompany} getMeals={this.getMeals} />
                <p className="pl-2 pt-2">{this.state.company_name}</p>
              </Row>
            </Col>
          </Row>
          <div className="weeklyMenu sticky-header--menu">
            <Row className="m-0">
              {this.state.week.map((w, i) => (
                <Col
                  key={i + w.date.toString().substr(8, 2)}
                  className="pt-1"
                  onClick={() => this.handelSelectedDay(w.date, i)}
                >
                  <span>{w.day}</span>
                  <h2>{w.date.toString().substr(8, 2)}</h2>
                </Col>
              ))}
            </Row>
            <div className={`redLabel ${this.handelClassName()}`}></div>
          </div>
          {this.state.messageText.length != 0 ? (
            <MenuTopMessage
              additionalClass="sticky-header--message"
              messageText={this.state.messageText}
            />
          ) : null}

          {this.state.restaurants.length === 0 &&
            this.state.isLoading == false && (
              <div
                className={`${isIOS ? "IOSdailyMenu" : "ANDdailymenu"}`}
                style={{
                  textAlign: "center",
                }}
              >
                {isIOS && (
                  <img
                    src={NoMenuImage}
                    style={{
                      width: 200,
                      marginTop: this.state.messageText != 0 ? "70px" : "30px",
                    }}
                    alt="Cooking Chef"
                  />
                )}
                {isIOS == false && (
                  <img
                    src={NoMenuImage}
                    style={{
                      width: 200,
                      marginTop: this.state.messageText != 0 ? "60px" : "20px",
                    }}
                    alt="Cooking Chef"
                  />
                )}
                <p className="no-menu" style={{ fontSize: 24, marginTop: 30 }}>
                  We're still cooking your menu!
                  <br />
                  <span className="no-menu-text" style={{ fontSize: 18 }}>
                    We are working hard with top restaurants to bring you unique
                    curated meals. Stay tuned and check here regularly
                  </span>
                  <span
                    className="no-menu-text"
                    style={{
                      fontSize: 14,
                      marginTop: 15,
                    }}
                  >
                    Make sure the correct delivery address is selected under
                    your profile
                  </span>
                </p>
              </div>
            )}
          {this.state.isLoading && this.state.restaurants.length === 0 && (
            <div
              className={`${isIOS ? "IOSdailyMenu" : "ANDdailymenu"}`}
              style={{ marginLeft: 15 }}
            >
              {loadingSkeletonRestaurants.map(
                (skeletonLoadingRestaurant, index) => {
                  return (
                    <div
                      className="resturantDailyMealsWeb"
                      key={index}
                      style={{
                        marginBottom: "1%",
                        marginTop:
                          index == 0 && this.state.messageText != 0
                            ? "70px"
                            : "30px",
                      }}
                    >
                      <Skeleton
                        width={300}
                        height={28}
                        style={{ marginBottom: 10 }}
                      />
                      <br />
                      <Skeleton
                        width={140}
                        height={20}
                        style={{ marginBottom: 5 }}
                      />
                      <Row id="mealsCardWeb" className="mt-1">
                        {loadingSkeletonMeals.map((meal, id) => {
                          return (
                            <Col
                              key={id}
                              onClick={this.toggle}
                              className="meals-card"
                              xs="12"
                              lg="4"
                            >
                              <Skeleton
                                key={index + "-" + id}
                                width={240}
                                height={80}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  );
                }
              )}
            </div>
          )}
          {this.state.restaurants.length > 0 && (
            <div className={`${isIOS ? "IOSdailyMenu" : "ANDdailymenu"}`}>
              {this.state.restaurants.map((resturant, index) => {
                return (
                  <div
                    className="resturantDailyMealsWeb"
                    key={
                      index +
                      "-" +
                      this.state.selected_date +
                      "-" +
                      resturant.name
                    }
                    style={{
                      marginBottom: "1%",
                      marginTop:
                        index == 0 && this.state.messageText != 0
                          ? "60px"
                          : "20px",
                    }}
                  >
                    <h3
                      id="resturantName"
                      onClick={this.getMeals}
                      style={{ fontWeight: "bold" }}
                    >
                      {resturant.name}{" "}
                    </h3>
                    <div>
                      {resturant.meals[0].cut_off == 0 && (
                        < p id="orderTimeWeb">
                          {`
                    ${resturant.meals[0].meal_type
                              ? `${mealTypes[resturant.meals[0].meal_type]} - `
                              : ""
                            }
                      ${resturant.meals[0].delivery_time
                              ? `Delivery From ${resturant.meals[0].delivery_time}`
                              : ""
                            }`}
                        </p>
                      )}
                      {resturant.meals[0].cut_off == 1 && (
                        < p id="orderTimeWeb">
                          {`Ordering Ended at ${resturant.meals[0].cut_off_time} ${resturant.meals[0].delivery_time
                            ? `- Delivery From ${resturant.meals[0].delivery_time}`
                            : ""
                            }`}
                        </p>
                      )}
                    </div>
                    <span className="cut-off-time">{resturant.meals[0].cut_off_time ? `Order by ${convertTime(resturant.meals[0].cut_off_time)}` : ''}</span>
                    <Row id="mealsCard" className="mt-1">
                      {resturant.meals.map((hubMeal, id) => {
                        return (
                          <MealModals
                            hubMeal={hubMeal}
                            key={`${this.state.selected_date}-${hubMeal.meal_id}`}
                            cards={this.state.cards}
                            floor={this.state.floor}
                            getCards={this.getCards}
                            orderSuccessHandler={this.onOrderSuccess}
                            orderFailureHandler={this.onOrderFailure}
                            history={this.props.history}
                            is_passed_cut_off_time={hubMeal.cut_off}
                            cut_off_time={convertTime(hubMeal.cut_off_time)}
                            pickupInstruction={resturant.pickup}
                            selectedDay={this.state.selected_date}
                            updateCutOff={this.updateCutOff}
                          />
                        );
                      })}
                    </Row>
                  </div>
                );
              })}
            </div>
          )}
          {this.state.selected_date && (
            <Banner
              selectedDay={this.state.selected_date}
              refreshIndex={this.state.newOrdersCount}
              weekStartDate={this.state.weekStartDate}
            />
          )}
        </div>
      );
    }
  }
}

export default DailyMenu;
